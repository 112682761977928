import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiCmsModule } from '@domgen/dgx-fe-content-management';
import { Components } from './components/components';

@NgModule({
  imports: [CommonModule, SharedUiCmsModule],
  declarations: [Components],
  exports: [Components],
})
export class CommonUiCmsTemplatesModule {}
