import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { ContractType } from '@common/util-models';

@Component({
  selector: 'sales-what-you-will-get',
  templateUrl: './what-you-will-get.component.html',
  styleUrls: ['./what-you-will-get.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatYouWillGetComponent {
  @Input() productType: ContractType | null = ContractType.Insurance;

  insurance = ContractType.Insurance;
  maintenance = ContractType.MSP;
}
