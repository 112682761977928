import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AnalyticsData, CmsFormField, WrappedValidate } from '@domgen/dgx-fe-business-models';

import { DirectDebitFormConfigService } from './direct-debit-form-config.service';
import { DirectDebitFormStateService } from './direct-debit-form-state.service';

@Component({
  selector: 'dgx-uib-direct-debit-form',
  templateUrl: './direct-debit-form.component.html',
  styleUrls: ['./direct-debit-form.component.scss'],
  providers: [DirectDebitFormConfigService, DirectDebitFormStateService],
})
export class DirectDebitFormComponent implements OnChanges {
  @Input() set cmsFormData(value: CmsFormField[] | null) {
    if (value) {
      this.stateService.setCmsFormData(value);
    }
  }

  @Input() set validate(validate: WrappedValidate | null) {
    if (validate?.validate) {
      this.stateService.validate();
    }
  }

  @Input() set resetDirectDebitForm(reset: boolean) {
    if (reset) {
      this.stateService.resetDirectDebitForm();
    }
  }

  /**
   * The underlying form group
   */
  @Output() form = this.stateService.formGroup$;

  @Output() valueChanges = this.stateService.valueChanges$;

  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  readonly vm$ = this.stateService.vm$;

  constructor(private readonly stateService: DirectDebitFormStateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validate?.currentValue === true) {
      this.stateService.validate();
    }
  }

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }
}
