import { ValidationMessage } from './dynamic-formbuilder.interface';

export enum AddressItemType {
  Address = 'Address',
}

export enum AddressType {
  Residential = 'Residential',
  Commercial = 'Commercial',
}

export const postCodeMismatchMessage: ValidationMessage = {
  type: 'isPostCodeMismatch',
  message: 'This postcode should be the same as the one you just entered',
};

export const addressFieldRequired = {
  message: 'Please provide your home address or postcode',
};

export interface AddressItem {
  Id: string;
  Type: AddressItemType | string;
  Text: string;
  Highlight: string;
  Description: string;
}

export interface AddressItemDetail {
  Id: string;
  Line1: string;
  Line2: string;
  City: string;
  Province: string;
  PostalCode: string;
  Type: string;
}

export interface FindAddressResponse {
  Items: AddressItem[];
}

export interface RetrieveAddressResponse {
  Items: AddressItemDetail[];
}

export interface Address {
  line1: string;
  line2?: string;
  city: string;
  county: string;
  postcode: string;
  type: string;
}
