import { MarketingPreferences } from '@domgen/dgx-fe-business-models';
import { PersonalDetailsFormField } from './personal-details-form-cms.types';

export interface DirectDebit {
  accountName: string;
  accountNumber: string;
  sortCode: string;
  monthlyPaymentDay: number;
  autoRenewalOptOut?: boolean;
}

export interface PersonalAddress {
  line1: string;
  line2: string;
  city: string;
  county: string;
  postcode: string;
}

export interface PersonalDetails {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
}

export interface PersonalDetailsState {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  address: PersonalAddress | null;
  directDebitDetails: DirectDebit | null;
  paperlessPlanDocuments: boolean | null;
  marketingPreferences: MarketingPreferences | null;
}

export interface PersonalDetailsFormSubmitType {
  [PersonalDetailsFormField.Title]: string;
  [PersonalDetailsFormField.FirstName]: string;
  [PersonalDetailsFormField.LastName]: string;
  [PersonalDetailsFormField.Email]: string;
  [PersonalDetailsFormField.MobileNumber]: string;
  [PersonalDetailsFormField.Payment]: DirectDebit;
  [PersonalDetailsFormField.Address]: PersonalAddress;
}

export interface TaggingPersonalDetails {
  title: string;
  firstName?: string;
  lastName: string;
  email?: string;
  mobileNumber?: string;
  postcode: string;
  marketingPreferencesForm: MarketingPreferencesForm;
}

export interface MarketingPreferencesForm {
  email: boolean;
  phone: boolean;
  post: boolean;
}
