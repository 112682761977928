import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserProfileCommonSelectorsFacade } from './+state/user-profile-common-selectors.facade';
import { UserProfileEffects } from './+state/user-profile.effects';
import { UserProfileFacade } from './+state/user-profile.facade';
import * as fromUserProfile from './+state/user-profile.reducer';
import { UserProfileApiService } from './services/user-profile-api.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromUserProfile.USERPROFILE_FEATURE_KEY,
      fromUserProfile.reducer
    ),
    EffectsModule.forFeature([UserProfileEffects]),
  ],
  providers: [
    UserProfileFacade,
    UserProfileApiService,
    UserProfileCommonSelectorsFacade,
  ],
})
export class CommonDataAccessUserProfileModule {}
