import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QuotesEffects } from './+state/quotes.effects';
import * as fromQuotes from './+state/quotes.reducer';
import { QuotesCommonSelectorsFacade } from './facades/quotes-common-selectors.facade';
import { QuotesFacade } from './facades/quotes.facade';
import { SelectQuotePaymentOptionsFacade } from './facades/select-quote-payment-options.facade';
import { HasSelectedBasketItemsGuard } from './guards/has-selected-basket-items/has-selected-basket-items.guard';
import { QuotesApiService } from './services/quotes-api.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromQuotes.QUOTES_FEATURE_KEY, fromQuotes.reducer),
    EffectsModule.forFeature([QuotesEffects]),
  ],
  providers: [
    QuotesApiService,
    QuotesCommonSelectorsFacade,
    QuotesFacade,
    HasSelectedBasketItemsGuard,
    SelectQuotePaymentOptionsFacade,
  ],
})
export class CommonDataAccessQuotesModule {}
