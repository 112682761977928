import { Component, Input, OnInit } from '@angular/core';
import { CmsDynamicComponentContent } from '@domgen/dgx-fe-content-management';

declare global {
  interface Window {
    Trustpilot: { loadFromElement: (args: unknown) => void };
  }
}

window.Trustpilot = window.Trustpilot || {};

@Component({
  selector: 'common-cms-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrls: ['./hero-image.component.scss'],
})
export class HeroImageComponent implements OnInit {
  @Input() content!: CmsDynamicComponentContent;

  ngOnInit(): void {
    const trustboxRef = document.getElementById('trustbox-micro');
    window.Trustpilot?.loadFromElement(trustboxRef);
  }
}
