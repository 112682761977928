import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingPreferencesComponent } from './marketing-preferences.component';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';

@NgModule({
  imports: [CommonModule, SharedUiDynamicFormBuilderModule],
  declarations: [MarketingPreferencesComponent],
  exports: [MarketingPreferencesComponent],
})
export class MarketingPreferencesModule {}
