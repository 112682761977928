import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, RUNTIME_CONFIG } from '@common/util-foundation';
import { ConfigAdapter, Environment, RuntimeConfig } from '@common/util-models';

@Injectable()
export class WorldpayConfigAdapter implements ConfigAdapter {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(RUNTIME_CONFIG) private runtimeConfig: RuntimeConfig
  ) {}

  getConfig(): { [key: string]: unknown } {
    return {
      worldpay: {
        ...this.environment.worldpay,
        iframeHelperURL: this.runtimeConfig.worldpayIframeHelperURL,
      },
    };
  }
}
