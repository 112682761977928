import { CmsFormField } from '@domgen/dgx-fe-business-models';

export enum ApplianceFormField {
  Appliance = 'appliance',
  Brand = 'brand',
  GetQuote = 'getQuote',
  GoodWorkingOrder = 'goodWorkingOrder',
  GuaranteeDuration = 'guaranteeDuration',
  PurchaseDate = 'monthYearDate',
  PurchasePrice = 'purchasePrice',
  Submit = 'submit',
  UnderGuarantee = 'underGuarantee',
}

export type ApplianceFormFieldTypes =
  | CmsFormField<ApplianceFormField.Appliance>
  | CmsFormField<ApplianceFormField.Brand>
  | CmsFormField<ApplianceFormField.GetQuote>
  | CmsFormField<ApplianceFormField.GoodWorkingOrder>
  | CmsFormField<ApplianceFormField.GuaranteeDuration>
  | CmsFormField<ApplianceFormField.PurchaseDate>
  | CmsFormField<ApplianceFormField.PurchasePrice>
  | CmsFormField<ApplianceFormField.UnderGuarantee>;

export type ApplianceFormFields = Array<ApplianceFormFieldTypes>;

export interface FixedFormValues {
  [key: string]: number;
}
