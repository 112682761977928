import { CmsFormField } from '@domgen/dgx-fe-business-models';

export enum PersonalDetailsFormField {
  Title = 'title',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  MobileNumber = 'mobileNumber',
  Payment = 'payment',
  Address = 'address',
}

export interface AddressFormField
  extends CmsFormField<PersonalDetailsFormField.Address> {
  addressLine1Label: string;
  addressLine1Placeholder: string;
  addressLine2Label: string;
  addressLine2Placeholder: string;
  cityLabel: string;
  cityPlaceholder: string;
  countyLabel: string;
  countyPlaceholder: string;
  postcodeLabel: string;
  postcodePlaceholder: string;
}

export type PersonalDetailsFormFields = Array<CmsFormField | AddressFormField>;
