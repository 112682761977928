import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SELECTPAYMENT_FEATURE_KEY,
  SelectPaymentPartialState,
  SelectPaymentState,
} from './select-payment.reducer';

// Lookup the 'SelectPayment' feature state managed by NgRx
export const getSelectPaymentState = createFeatureSelector<
  SelectPaymentPartialState,
  SelectPaymentState
>(SELECTPAYMENT_FEATURE_KEY);

export const getSelectedPaymentType = createSelector(
  getSelectPaymentState,
  (state: SelectPaymentState) => state?.selectedPaymentType
);
