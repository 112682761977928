import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  first,
  map,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { NavigationTriggerProvider } from './+state/navigation-trigger-provider';
import { NavigationFacade } from './+state/navigation.facade';
import { NAVIGATION_CONFIG } from './navigation.token';
import { NavigationConfig, NavigationRuleConfig } from './navigation.types';

/**
 * A simple step based completion guard that checks the store for the completion of the
 * stepsToComplete. If they are all completed return true and allow navigation else return
 * UrlTree indicating a Navigation Cancellation to a defaultRedirect page
 * @export
 * @class StepCompletionHandler
 * @implements {NavigationHandler}
 */

@Injectable()
export class StepCompletionNavigationGuard implements CanActivate {
  constructor(
    @Inject(NAVIGATION_CONFIG)
    private config: NavigationConfig,
    private navigationFacade: NavigationFacade,
    private navigationTriggerProvider: NavigationTriggerProvider,
    private router: Router
  ) {}

  canActivate(
    _activatedRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const matchingConfig = this.config.navigationRules.find(
      (navigationRule) => navigationRule.target === state.url
    );
    return of(true).pipe(
      switchMap(() =>
        matchingConfig ? this.handleNavigation(matchingConfig) : of(true)
      )
    );
  }

  private handleNavigation(
    navigationRuleConfig: NavigationRuleConfig
  ): Observable<boolean | UrlTree> {
    return this.navigationFacade
      .areStepsCompleted(...navigationRuleConfig.stepsToComplete)
      .pipe(
        first(),
        withLatestFrom(
          this.navigationTriggerProvider.navigationTrigger$.pipe(
            startWith('imperative')
          )
        ),
        map(([stepsCompleted, navigationTrigger]) => {
          return !stepsCompleted ||
            (navigationTrigger === 'popstate' &&
              navigationRuleConfig.disableNavigationForBackButton)
            ? this.router.parseUrl(
                navigationRuleConfig.redirectUrl ??
                  this.config.defaultRedirectUrl
              )
            : true;
        })
      );
  }
}
