import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { filter } from 'rxjs/operators';

export const filterFields = (
  ...formFields: string[]
): MonoTypeOperatorFunction<AnalyticsData> => {
  return pipe(
    filter((analyticsData) => formFields.includes(analyticsData.controlName))
  );
};
