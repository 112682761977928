import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ApplianceDetailsEffects } from './+state/appliance-details.effects';

import { ApplianceDetailsFacade } from './+state/appliance-details.facade';
import * as fromApplianceDetails from './+state/appliance-details.reducer';
import { ApplianceFormDataEffects } from './+state/appliance-form-data/appliance-form-data.effects';
import { AppliancesEffects } from './+state/appliances/appliances.effects';
import { BrandsEffects } from './+state/brands/brands.effects';
import { AppliancesApiService } from './services/appliances-api.service';
import { AppliancesMapperService } from './services/appliances-mapper.service';
import { AppliancesResolver } from './services/appliances.resolver';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromApplianceDetails.APPLIANCE_DETAILS_FEATURE_KEY,
      fromApplianceDetails.applianceDetailsReducer
    ),
    EffectsModule.forFeature([
      ApplianceDetailsEffects,
      ApplianceFormDataEffects,
      AppliancesEffects,
      BrandsEffects,
    ]),
  ],

  providers: [
    ApplianceDetailsFacade,
    AppliancesApiService,
    AppliancesResolver,
    AppliancesMapperService,
  ],
})
export class CommonDataAccessApplianceDetailsModule {}
