import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AddressListDef, SelectionMode } from '../../_shared/interfaces';
import { FormElementBaseComponent } from '../form-element-base.component';
import { AddressListStateService } from './address-list-state.service';

@UntilDestroy()
@Component({
  selector: 'dgx-dfb-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
  providers: [AddressListStateService],
})
export class AddressListComponent
  extends FormElementBaseComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() field!: AddressListDef;

  @Input() validate = false;

  vm$ = this.addressListStateService.vm$;

  constructor(
    @Optional() ngControl: NgControl,
    private addressListStateService: AddressListStateService
  ) {
    super(ngControl);
    if (ngControl !== null) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.ngControl && this.ngControl.control) {
      this.addressListStateService.setFormControl(this.ngControl.control);
    }

    this.addressListStateService.addressFormValueChanges$
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.onChanged(value);
        this.onTouched();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fieldDef = changes?.field?.currentValue;
    if (fieldDef) {
      this.addressListStateService.setFieldDef(fieldDef);
      if (fieldDef.initialValue?.line1) {
        this.addressListStateService.setMode(SelectionMode.Manual);
      }
    }
    if (this.ngControl.touched) {
      this.addressListStateService.onTouched();
    }
  }

  onSelectionChange(index: number) {
    this.addressListStateService.setSelectedIndex(index);
  }

  onClearSelection() {
    this.addressListStateService.setSelectedIndex(-1);
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: string, emitAnalytics: boolean = false) {
    super.writeValue(val, emitAnalytics);
  }

  selectedModeOutput(selectedOption: SelectionMode): void {
    this.addressListStateService.setMode(selectedOption);
  }

  goToManual() {
    this.addressListStateService.setMode(SelectionMode.Manual);
  }
}
