import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { ENVIRONMENT } from '@common/util-foundation';
import { Environment } from '@common/util-models';

@Component({
  selector: 'sales-appliance-details-form-header',
  templateUrl: './appliance-details-form-header.component.html',
  styleUrls: ['./appliance-details-form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplianceDetailsFormHeaderComponent {
  @Input() discountPercentage?: number;
  @Input() hasExistingWhiteGoodsApplianceQuote?: boolean;
  @Input() multiItemQuoteCountIsAtMaximum?: boolean;

  isMultiBasket?: boolean = this.environment.multiBasket;
  multiBasketMaximum?: number = this.environment.multiBasketMaximum;

  constructor(@Inject(ENVIRONMENT) private readonly environment: Environment) {}
}
