import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  OptionItem,
  RadioDefBase,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

let nextId = 1;

@Component({
  selector: 'dgx-dfb-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent
  extends FormElementBaseComponent
  implements OnInit, ControlValueAccessor {
  @Input() field!: RadioDefBase;

  id = `radios-${nextId++}`;
  disabled = false;
  value?: string | boolean | number;

  options: OptionItem<string | number | boolean>[] = [];

  constructor(
    @Optional() public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setOptions();
    this.value = this.field?.initialValue;
  }

  registerOnChange(fn: (val: unknown) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(
    optionValue: string | boolean | number,
    emitAnalytics: boolean = false
  ): void {
    this.value = optionValue;
    super.writeValue(optionValue, emitAnalytics);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  private setOptions(): void {
    this.options =
      this.field?.options?.map(
        (option: string | OptionItem<string | number | boolean>) =>
          typeof option === 'string' ? { label: option, value: option } : option
      ) ?? [];
  }
}
