import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'wl-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBannerComponent {
  @Input() hideLink?: boolean;
  @Input() hideSubtitle?: boolean;
  @Input() linkText?: string;
  @Input() subtitle?: string;
  @Input() title = '';

  @Output() handleClickEvent = new EventEmitter();

  handleClick() {
    this.handleClickEvent.emit();
  }
}
