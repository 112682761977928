import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'dgx-ui-wl-footer-social',
  templateUrl: './footer-social.component.html',
  styleUrls: ['./footer-social.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterSocialComponent {
  @Input() socialLinks?: NavigationLink[];
}
