import { MarketingPreferences } from '@domgen/dgx-fe-business-models';
import { createAction, props } from '@ngrx/store';
import {
  DirectDebit,
  PersonalDetailsFormSubmitType,
  TaggingPersonalDetails,
} from '@common/util-models';

export const updatePersonalDetails = createAction(
  '[CheckoutPersonalDetailsComponent] Update Personal Details',
  props<{ payload: PersonalDetailsFormSubmitType }>()
);

export const exitingPersonalDetails = createAction(
  '[CheckoutPersonalDetailsComponent] Exit personal details page'
);

export const updateDirectDebit = createAction(
  '[CheckoutDirectDebitComponent] Update Direct Debit For Guest',
  props<{ payload: DirectDebit }>()
);

export const updateMarketingPreference = createAction(
  '[CheckoutDirectDebitComponent] Update Marketing Preference For Guest',
  props<{ payload: MarketingPreferences }>()
);

export const updatePlanDocumentPreference = createAction(
  '[CheckoutDirectDebitComponent] Update Paperless Plan Documents Guest',
  props<{ paperless: boolean }>()
);

export const proceedToPayment = createAction(
  '[CheckoutDirectDebitComponent] Proceed to Payment for Guest'
);

export const tagPersonalDetails = createAction(
  '[CheckoutPersonalDetailsComponent] tag personal details',
  props<{ taggingDetails: TaggingPersonalDetails }>()
);
