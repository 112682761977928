import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderConfirmationFacade } from './+state/order-confirmation.facade';

@NgModule({
  imports: [CommonModule],
  providers: [
    OrderConfirmationFacade,
  ],
})
export class CommonDataAccessCheckoutOrderConfirmationModule {}
