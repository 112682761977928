import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, merge, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

/** @dynamic */
@Injectable()
export class ListOverlayService {
  private nativeElement: HTMLElement | undefined;

  private escapeKeyDown$ = fromEvent<KeyboardEvent>(
    this.document,
    'keydown'
  ).pipe(this.filterAndPreventDefault('Escape'));

  private clickOutside$ = fromEvent<MouseEvent>(
    this.document,
    'mousedown'
  ).pipe(
    filter(() => this.nativeElement !== undefined),
    map((event) => !this.nativeElement?.contains(event.target as HTMLElement)),
    filter((result) => result)
  );

  dismissOptionsUpdater$ = merge(this.clickOutside$, this.escapeKeyDown$);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  setNativeDomElement(nativeElement: HTMLElement) {
    this.nativeElement = nativeElement;
  }

  private filterAndPreventDefault(
    ...keysToFilter: string[]
  ): MonoTypeOperatorFunction<KeyboardEvent> {
    return (source$: Observable<KeyboardEvent>) => {
      return source$.pipe(
        filter(
          (event) =>
            !!keysToFilter.find((keyToFilter) => keyToFilter === event.key)
        ),
        tap((event) => event.preventDefault())
      );
    };
  }
}
