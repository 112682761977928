import {
  ApplianceFormSubmit,
  ApplianceFormValueWithItemId,
} from '@common/util-models';
import { createAction, props } from '@ngrx/store';

export const setPrepopulatedFormValue = createAction(
  '[Appliance Details] Set Prepopulated Form Value',
  props<{
    formData: ApplianceFormSubmit;
  }>()
);

export const clearPrepopulatedFormValue = createAction(
  '[Appliance Details] Clear Prepopulated Form Value'
);

export const saveFormValueWithItemId = createAction(
  '[Appliance Details] Save Form Value With Item Id',
  props<{
    formData: ApplianceFormValueWithItemId;
  }>()
);

export const resetFormData = createAction(
  '[Appliance Details] Reset Form Data'
);
