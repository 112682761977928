import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  PersonalDetailsFormSubmitType,
  PersonalDetailsState,
} from '@common/util-models';
import { InvalidFormSubmissionPayload } from '@domgen/dgx-fe-dynamic-form-builder';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { PersonalDetailsFormConfigService } from './personal-details-form-config.service';
import { PersonalDetailsFormStateService } from './personal-details-form-state.service';
import { CmsFormField, WrappedValidate } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'sales-personal-details-form',
  templateUrl: './personal-details-form.component.html',
  styleUrls: ['./personal-details-form.component.scss'],
  providers: [
    PersonalDetailsFormStateService,
    PersonalDetailsFormConfigService,
  ],
})
export class PersonalDetailsFormComponent implements OnChanges {
  @Input() hideBorder = false;
  @Input() set cmsFormData(value: CmsFormField[] | null) {
    if (value) {
      this.personalDetailsFormStateService.setCmsFormData(value);
    }
  }

  @Input() set stateFormData(value: PersonalDetailsState | undefined | null) {
    if (value) {
      this.personalDetailsFormStateService.setPrefilledFormData(value);
    }
  }

  @Input() set validate(validate: WrappedValidate | null) {
    if (validate?.validate) {
      this.personalDetailsFormStateService.validate();
    }
  }

  @Output() form = this.personalDetailsFormStateService.formGroup$;
  @Output() valueChanges = this.personalDetailsFormStateService.valueChanges$;
  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  @Output()
  invalidSubmit: EventEmitter<InvalidFormSubmissionPayload> = new EventEmitter();

  @Output()
  validatedSubmit: EventEmitter<PersonalDetailsFormSubmitType> = new EventEmitter<PersonalDetailsFormSubmitType>();

  readonly vm$ = this.personalDetailsFormStateService.vm$;

  constructor(
    private readonly personalDetailsFormStateService: PersonalDetailsFormStateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validate?.currentValue === true) {
      this.personalDetailsFormStateService.validate();
    }
  }

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }
}
