import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'dgx-dfb-password-strength-checker',
  templateUrl: './password-strength-checker.component.html',
  styleUrls: ['./password-strength-checker.component.scss'],
})
export class PasswordStrengthCheckerComponent
  implements OnChanges, AfterViewInit {
  passwordValidator = {
    passwordLength: false,
    passwordUpperCase: false,
    passwordLowerCase: false,
    passwordNumber: false,
    passwordComplexCase: false,
  };

  @Input() value = '';

  @Input() showLength = false;
  @Input() showUppercase = false;
  @Input() showLowercase = false;
  @Input() showNumber = false;
  @Input() showComplex = false;

  @Input() lengthError = false;
  @Input() upperCaseError = false;
  @Input() lowerCaseError = false;
  @Input() numberError = false;
  @Input() showComplexError = false;
  @Input() submitted = false;

  public translations = {
    passwordNumber: 'At least one number',
    passwordLowerCase: 'At least one lower case letter',
    passwordUpperCase: 'At least one upper case letter',
    passwordLength: 'At least eight characters',
  };

  ngAfterViewInit(): void {
    this.checkValue();
  }

  ngOnChanges() {
    this.checkValue();
  }

  checkValue() {
    const result = this.validatePassword(this.value);
    this.passwordValidator = {
      passwordLength: Boolean(result.passwordLength),
      passwordLowerCase: Boolean(result.passwordLowerCase),
      passwordUpperCase: Boolean(result.passwordUpperCase),
      passwordNumber: Boolean(result.passwordNumber),
      passwordComplexCase: Boolean(result.passwordComplexCase),
    };
  }

  validatePassword(passwordInput: string) {
    return {
      passwordLength: passwordInput && passwordInput.length >= 8,
      passwordLowerCase: passwordInput && !!passwordInput.match('[a-z]'),
      passwordUpperCase: passwordInput && !!passwordInput.match('[A-Z]'),
      passwordNumber: passwordInput && !!passwordInput.match('[0-9]'),
      passwordComplexCase:
        passwordInput && !!passwordInput.match('[!@#$%^&*(),.?":{}|<>]'),
    };
  }
}
