<div
  *ngIf="type === TrustBox.MICRO"
  #microRef
  id="trustbox-micro"
  class="trustpilot-widget"
  data-automation="trustbox-micro"
  data-locale="en-GB"
  [attr.data-template-id]="templateId"
  [attr.data-businessunit-id]="businessUnitId"
  data-style-width="100%"
></div>

<div
  *ngIf="type === TrustBox.SLIDER"
  #sliderRef
  id="trustbox-slider"
  class="trustpilot-widget"
  data-automation="trustbox-slider"
  data-locale="en-GB"
  [attr.data-template-id]="templateId"
  [attr.data-businessunit-id]="businessUnitId"
  data-style-height="240px"
  data-style-width="100%"
  data-theme="light"
  data-stars="4,5"
></div>
