import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RadioListItemValue } from '@domgen/dgx-fe-components-core';

export interface Product {
  id: RadioListItemValue;
  title: string;
  perMonthPrice: number;
  yearlyPrice: number;
  extraCost?: number;
  desc?: string;
  isUpgrade?: boolean;
}

@Component({
  selector: 'wl-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent {
  @Input() selected = false;
  @Input() product: Product | undefined;
  @Input() selectable = false;

  @Output()
  selectionChange: EventEmitter<RadioListItemValue> = new EventEmitter();

  onClick() {
    if (
      this.selectable &&
      this.selectionChange &&
      this.selectionChange.observers &&
      this.selectionChange.observers.length
    ) {
      this.selectionChange.emit(this.product && this.product.id);
    }
  }

  // TODO review this logic, triggers the same analysis event twice
  // TODO Test: On radio button on payment selection or cover type selection
  isSelected() {
    // if (
    //   this.selectionChange &&
    //   this.selectionChange.observers &&
    //   this.selectionChange.observers.length
    // ) {
    //   if (value) {
    //     this.selectionChange.emit(this.product && this.product.id);
    //   }
    // } else {
    //   this.selected = value;
    // }
  }
}
