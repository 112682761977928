<header class="header" data-hook="app-header">
  <div class="container">
    <div class="grid-row">
      <div class="header__banner xs-12 md-6 flex align--center">
        <div class="header__banner-wrapper flex align--center justify--end">
          <div class="header__tag-line">Powered by</div>
          <img
            class="dg-logo"
            src="assets/images/dg-logo-colour.png"
            role="img"
            height="30"
            alt="Domestic & General"
          />
        </div>
      </div>

      <div class="header__logo-wrapper xs-12 md-6 flex align--center">
        <a href="/">
          <img
            src="assets/images/sky-protect-v3.png"
            class="sky-logo"
            role="img"
            alt="Sky Protect"
            height="30"
          />
        </a>
      </div>
    </div>
  </div>
</header>
