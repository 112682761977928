import {
  CardPaymentPartialState,
  CardPaymentSelectors,
} from '@common/data-access-card-payment';
import {
  CheckoutBasketPartialState,
  CheckoutBasketSelectors,
} from '@common/data-access-checkout-basket';
import {
  PersonalDetailsPartialState,
  PersonalDetailsSelectors,
} from '@common/data-access-personal-details';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';
import {
  UserProfilePartialState,
  UserProfileSelectors,
} from '@common/data-access-user-profile';
import {
  CheckoutBasket,
  CheckoutBasketItem,
  CheckoutRequest,
  isRemoteDataError,
  isRemoteDataOK,
  PaymentByCreditCard,
  PaymentByDirectDebit,
  PaymentByDirectDebitToken,
  PaymentDetails,
  PaymentType,
  Person,
  PersonalDetailsState,
  PlanDocumentsSendMethod,
  RemoteData,
  StopAutoRenewalCode,
} from '@common/util-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  checkoutAdapter,
  CheckoutPartialState,
  CheckoutState,
  CHECKOUT_FEATURE_KEY,
} from './checkout.reducer';

export const getCheckoutState = createFeatureSelector<
  CheckoutPartialState,
  CheckoutState
>(CHECKOUT_FEATURE_KEY);

const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = checkoutAdapter.getSelectors();

export const getCheckoutRemoteState = createSelector(
  getCheckoutState,
  (state: CheckoutState) => state.remoteStateCheckout
);

export const getValidateDirectDebitDetailsRemoteState = createSelector(
  getCheckoutState,
  (state: CheckoutState) => state.remoteStateValidateDirectDebitDetails
);

export const getCheckoutError = createSelector(
  getCheckoutRemoteState,
  (remoteState: RemoteData) => {
    if (isRemoteDataError(remoteState)) {
      return remoteState.error;
    }
    return undefined;
  }
);

export const getValidateDirectDebitDetailsError = createSelector(
  getValidateDirectDebitDetailsRemoteState,
  (remoteState: RemoteData) => {
    if (isRemoteDataError(remoteState)) {
      return remoteState.error;
    }
    return undefined;
  }
);

export const getDirectDebitDetailsValid = createSelector(
  getCheckoutState,
  getValidateDirectDebitDetailsRemoteState,
  (state: CheckoutState, remoteState: RemoteData) => {
    if (isRemoteDataOK(remoteState)) {
      return state.directDebitDetailsValid;
    }
    return undefined;
  }
);

export const getCheckedOutItems = createSelector(
  getCheckoutState,
  (state: CheckoutState) => selectAll(state)
);

export const getCheckedOutEntities = createSelector(
  getCheckoutState,
  (state: CheckoutState) => selectEntities(state)
);

export const getAutoRenewalPreference = createSelector(
  getCheckoutState,
  (state: CheckoutState | undefined): StopAutoRenewalCode | undefined =>
    state?.autoRenewalPreference
);

export const getTotalCheckedOutItems = createSelector(
  getCheckoutState,
  (state: CheckoutState) => selectTotal(state)
);

export const getCheckedOutIds = createSelector(
  getCheckoutState,
  (state: CheckoutState) => selectIds(state)
);

export const getLoggedInUserCheckoutRequest = createSelector<
  CheckoutPartialState &
    CheckoutBasketPartialState &
    UserProfilePartialState &
    SelectPaymentPartialState &
    CardPaymentPartialState,
  CheckoutBasket,
  boolean | undefined,
  Person | undefined,
  PaymentDetails | undefined,
  PaymentType | undefined,
  string | undefined,
  StopAutoRenewalCode | undefined,
  CheckoutRequest | undefined
>(
  CheckoutBasketSelectors.getCheckoutBasketState,
  UserProfileSelectors.getPaperlessPlanDocumentPreference,
  UserProfileSelectors.getPersonalDetails,
  UserProfileSelectors.getNewOrSavedDirectDebitPaymentDetails,
  SelectPaymentSelectors.getSelectedPaymentType,
  CardPaymentSelectors.getCardPaymentOrderNumber,
  getAutoRenewalPreference,
  (
    basket: CheckoutBasket | undefined,
    paperlessLess: boolean | undefined,
    personalDetails: Person | undefined,
    directDebitPaymentDetails: PaymentDetails | undefined,
    paymentType: PaymentType | undefined,
    cardPaymentOrderNumber: string | undefined,
    stopAutoRenewalCode: StopAutoRenewalCode | undefined
  ) => {
    return {
      basketId: basket?.basketId,
      items: basket?.items.map(
        ({ itemId, quoteId, itemType }: CheckoutBasketItem) => {
          return {
            itemId,
            quoteId,
            itemType,
            stopAutoRenewalCode,
          };
        }
      ),
      customer: {
        address: {
          addressLine1: personalDetails?.correspondenceAddress.addressLine1,
          addressLine2: personalDetails?.correspondenceAddress.addressLine2,
          addressLine3: personalDetails?.correspondenceAddress.addressLine3,
          addressLine4: personalDetails?.correspondenceAddress.addressLine4,
          postalCode: personalDetails?.correspondenceAddress.postalCode,
          countryCode: personalDetails?.correspondenceAddress.countryCode,
        },
        email: {
          main: personalDetails?.contact.email,
        },
        title: personalDetails?.personalDetails.title,
        firstName: personalDetails?.personalDetails.firstName,
        surname: personalDetails?.personalDetails.surname,
        sendMethod:
          paperlessLess === undefined || paperlessLess === true
            ? PlanDocumentsSendMethod.EMAIL
            : PlanDocumentsSendMethod.BOTH,
        isOverrideAddress: true,
        preferredContactMethod: PlanDocumentsSendMethod.EMAIL,
        telephone: {
          mobile:
            personalDetails?.contact.mobilePhoneNumber ||
            personalDetails?.contact.landlinePhoneNumber,
        },
        marketingPreferences: {
          email: personalDetails?.marketingPreferences?.email,
          post: personalDetails?.marketingPreferences?.post,
          telephone: personalDetails?.marketingPreferences?.telephone,
        },
      },
      payment: getCheckoutPayment(
        paymentType,
        cardPaymentOrderNumber,
        directDebitPaymentDetails
      ),
    } as CheckoutRequest;
  }
);

export const getGuestUserCheckoutRequest = createSelector<
  CheckoutPartialState &
    CheckoutBasketPartialState &
    PersonalDetailsPartialState &
    SelectPaymentPartialState &
    CardPaymentPartialState,
  CheckoutBasket | undefined,
  PersonalDetailsState,
  PaymentType | undefined,
  string | undefined,
  StopAutoRenewalCode | undefined,
  CheckoutRequest | undefined
>(
  CheckoutBasketSelectors.getCheckoutBasketState,
  PersonalDetailsSelectors.getPersonalDetailsState,
  SelectPaymentSelectors.getSelectedPaymentType,
  CardPaymentSelectors.getCardPaymentOrderNumber,
  getAutoRenewalPreference,
  (
    basket: CheckoutBasket | undefined,
    personalDetailsState: PersonalDetailsState,
    paymentType: PaymentType | undefined,
    cardPaymentOrderNumber: string | undefined,
    stopAutoRenewalCode: StopAutoRenewalCode | undefined
  ) => {
    return {
      basketId: basket?.basketId,
      items: basket?.items.map(
        ({ itemId, quoteId, itemType }: CheckoutBasketItem) => {
          return {
            itemId,
            quoteId,
            itemType,
            stopAutoRenewalCode,
          };
        }
      ),
      customer: {
        address: {
          addressLine1: personalDetailsState?.address?.line1,
          addressLine2: personalDetailsState?.address?.line2,
          addressLine3: personalDetailsState?.address?.city,
          addressLine4: personalDetailsState?.address?.county,
          postalCode: personalDetailsState?.address?.postcode,
          countryCode: 'GBR',
        },
        email: {
          main: personalDetailsState.email,
        },
        title: personalDetailsState.title,
        firstName: personalDetailsState.firstName,
        surname: personalDetailsState.lastName,
        telephone: {
          // Pass a space if there is no mobile number
          mobile: personalDetailsState.mobileNumber || ' ',
        },
        marketingPreferences: {
          email: Boolean(personalDetailsState?.marketingPreferences?.email),
          post: Boolean(personalDetailsState?.marketingPreferences?.post),
          telephone: Boolean(personalDetailsState?.marketingPreferences?.phone),
        },
        sendMethod:
          personalDetailsState?.paperlessPlanDocuments === null ||
          personalDetailsState?.paperlessPlanDocuments === true
            ? PlanDocumentsSendMethod.EMAIL
            : PlanDocumentsSendMethod.BOTH,
        isOverrideAddress: true,
        preferredContactMethod: PlanDocumentsSendMethod.EMAIL,
      },
      payment: getCheckoutPayment(paymentType, cardPaymentOrderNumber, {
        accountName: personalDetailsState?.directDebitDetails?.accountName,
        accountNumber: personalDetailsState?.directDebitDetails?.accountNumber,
        // TODO: Why do i need to convert it to a number when the type is already a number
        paymentDay: Number(
          personalDetailsState?.directDebitDetails?.monthlyPaymentDay
        ),
        sortCode: personalDetailsState?.directDebitDetails?.sortCode,
      }),
    } as CheckoutRequest;
  }
);

const getCheckoutPayment = (
  paymentType: PaymentType | undefined,
  cardPaymentOrderNumber: string | undefined,
  directDebitPaymentDetails: PaymentDetails | undefined
): PaymentByDirectDebit | PaymentByDirectDebitToken | PaymentByCreditCard => {
  if (paymentType === PaymentType.Card) {
    return {
      cardPaymentReference: cardPaymentOrderNumber,
      paymentType: PaymentType.Card,
    } as PaymentByCreditCard;
  }

  return directDebitPaymentDetails?.paymentToken
    ? ({
        paymentType: PaymentType.DirectDebit,
        directDebitPaymentToken: directDebitPaymentDetails?.paymentToken,
        preferredPaymentDay: directDebitPaymentDetails?.paymentDay,
      } as PaymentByDirectDebitToken)
    : ({
        directDebitDetails: {
          accountName: directDebitPaymentDetails?.accountName,
          accountNumber: directDebitPaymentDetails?.accountNumber,
          sortCode: directDebitPaymentDetails?.sortCode,
        },
        paymentType: PaymentType.DirectDebit,
        preferredPaymentDay: directDebitPaymentDetails?.paymentDay,
      } as PaymentByDirectDebit);
};
