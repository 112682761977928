import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CheckboxToggleDef,
  ControlType,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'wl-payment-detail-confirmation',
  templateUrl: './payment-detail-confirmation.component.html',
  styleUrls: ['./payment-detail-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailConfirmationComponent
  implements OnChanges, AfterViewInit {
  public formGroup: FormGroup;
  @Input() validate = false;
  @Output() form: EventEmitter<FormGroup> = new EventEmitter();

  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  field: CheckboxToggleDef = {
    controlType: ControlType.CHECKBOX,
    controlName: 'isConfirmed',
    initialValue: false,
    validators: [Validators.requiredTrue],
    validationMessages: [
      {
        type: 'required',
        message:
          'Please check the box to confirm that your information is correct',
      },
    ],
    label: {
      text:
        'I confirm that I am the account holder, that these bank details are correct and that I am the only person required to authorise debits from this account.',
    },
  };

  constructor() {
    this.formGroup = new FormGroup({
      isConfirmed: new FormControl('', Validators.requiredTrue),
    });
  }

  ngAfterViewInit() {
    this.form.emit(this.formGroup);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validate?.currentValue === true) {
      this.formGroup.markAllAsTouched();
      this.handleAnalytics();
    }
  }

  handleAnalytics() {
    this.analytics.emit({
      controlName: 'account-holder',
      value: true,
      error: '',
    });
  }
}
