import { createAction, props } from '@ngrx/store';
import { CreateQuoteResponse, PaymentType } from '@common/util-models';

export const selectPaymentOption = createAction(
  '[CheckoutSelectPaymentComponent] Select Payment Option',
  props<{ paymentType: PaymentType }>()
);

export const selectFirstOrPreferredPaymentOption = createAction(
  '[Common] Select the first or preferred payment option from the first quote of the first item in the basket',
  props<{ quoteResponse: CreateQuoteResponse }>()
);

export const proceedToPayment = createAction(
  '[CheckoutSelectPaymentComponent] Clicked proceed to payment'
);

export const updatePlanDocumentPreferences = createAction(
  '[CheckoutSelectPaymentComponent] Update plan document preference',
  props<{ paperless: boolean }>()
);

export const exitingSelectPayment = createAction(
  '[CheckoutSelectPaymentComponent] Exiting select payment page'
);
