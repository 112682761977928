<div class="form-group">
  <div
    class="checkbox"
    [ngClass]="{
      'checkbox--large': field?.size === 'large',
      'checkbox--readonly': isReadonly,
      'checkbox--checked': isChecked,
      'checkbox--disabled': field?.disabled,
      'checkbox--invalid': validity === 'invalid'
    }"
  >
    <label class="checkbox__label">
      <input
        type="checkbox"
        class="checkbox__input"
        [name]="field?.controlName"
        [checked]="isChecked"
        [disabled]="isReadonly || field?.disabled"
        (click)="onClick()"
        (blur)="onBlur()"
      />

      <div class="checkbox__decorator" [attr.aria-hidden]="true">
        <svg width="12px" height="9px" viewBox="0 0 12 9" class="checkbox__svg">
          <polyline points="1 5 4 8 11 1"></polyline>
        </svg>
      </div>

      <span class="{{ field?.label?.classes }} checkbox__text">
        {{ field?.label?.text }}
      </span>
    </label>

    <p class="mt-1" [innerHTML]="field?.hint"></p>
  </div>
  <dgx-dfb-error [errorMessage]="errorMessage"></dgx-dfb-error>
</div>
