import { RedeyeTags } from '@common/util-models';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RedeyeTaggingService } from '../../services/redeye-tagging/redeye-tagging.service';
import { filterNullUndefined } from '../filter-null-undefined/filter-null-undefined';

export function pushToRedeye(
  redeyeTaggingService: RedeyeTaggingService
): MonoTypeOperatorFunction<RedeyeTags | RedeyeTags[]> {
  return pipe(
    filterNullUndefined(),
    tap((redeyeTags: RedeyeTags | RedeyeTags[]) =>
      Array.isArray(redeyeTags)
        ? redeyeTaggingService.tag(redeyeTags)
        : redeyeTaggingService.tag([redeyeTags])
    )
  );
}
