import { Injectable } from '@angular/core';
import {
  QuotesPartialState,
  QuotesSelectors,
} from '@common/data-access-quotes';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';
import { ResetStateActions } from '@common/data-access-shared';
import {
  CheckoutCompletedItem,
  getWrappedInvalidPaymentDetails,
  PaymentType,
  StopAutoRenewalCode,
  WrappedInvalidPaymentDetails,
} from '@common/util-models';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as CheckoutPageSelectors from './checkout-page.selectors';
import * as CheckoutActions from './checkout.actions';
import { CheckoutPartialState } from './checkout.reducer';
import * as CheckoutSelectors from './checkout.selectors';

@Injectable()
export class CheckoutFacade {
  showPopupToRestartJourney$ = this.store.pipe(
    select(CheckoutPageSelectors.showPopupToRestartCheckoutJourney),
    filter((showPopup) => !!showPopup)
  );

  invalidPaymentDetails$ = this.store.pipe(
    select(CheckoutSelectors.getDirectDebitDetailsValid),
    filter((directDebitDetailsValid) => directDebitDetailsValid !== undefined),
    map((directDebitDetailsValid) =>
      directDebitDetailsValid === false
        ? getWrappedInvalidPaymentDetails(true)
        : getWrappedInvalidPaymentDetails(false)
    )
  ) as Observable<WrappedInvalidPaymentDetails>;

  isHeating$ = this.store.pipe(
    select(QuotesSelectors.getCurrentItemIsHeatingAppliance)
  );

  checkedOutItems$: Observable<CheckoutCompletedItem[]> = this.store.select(
    CheckoutSelectors.getCheckedOutItems
  );

  autoRenewal$: Observable<StopAutoRenewalCode | undefined> = this.store.select(
    CheckoutSelectors.getAutoRenewalPreference
  );

  selectedPayment$ = this.store.pipe(
    select(SelectPaymentSelectors.getSelectedPaymentType)
  ) as Observable<PaymentType>;

  constructor(
    private store: Store<
      CheckoutPartialState & SelectPaymentPartialState & QuotesPartialState
    >
  ) {}

  checkoutGuest() {
    this.store.dispatch(CheckoutActions.checkoutGuest());
  }

  checkoutLoggedInUser() {
    this.store.dispatch(CheckoutActions.checkoutLoggedInUser());
  }

  exitCheckoutLoggedInUser() {
    this.store.dispatch(CheckoutActions.exitingCheckoutLoggedInUser());
  }

  exitCheckoutGuestUser() {
    this.store.dispatch(CheckoutActions.exitingCheckoutGuestUser());
  }

  saveAutoRenewalPreference(isAutoRenewal: boolean) {
    const autoRenewalPreference = isAutoRenewal
      ? undefined
      : StopAutoRenewalCode.EndOfCurrentRenewal;
    this.store.dispatch(
      CheckoutActions.saveAutoRenewalPreference({ autoRenewalPreference })
    );
  }

  restartCheckoutJourneyLoggedInUser() {
    this.store.dispatch(ResetStateActions.restartCheckoutLoggedInUser());
  }

  restartCheckoutJourneyGuestUser() {
    this.store.dispatch(ResetStateActions.restartCheckoutGuest());
  }

  paymentDetailsSummaryOnSubmit() {
    this.store.dispatch(CheckoutActions.paymentDetailsSummaryOnSubmit());
  }
}
