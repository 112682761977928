<dgx-ui-wl-footer
  copyrightOwner="Domestic & General Group Limited"
  [legalLinks]="legalLinks"
  [moreLinks]="moreLinks"
  [protectLinks]="protectLinks"
  [usefulLinks]="usefulLinks"
  [socialLinks]="socialLinks"
>
  <p>
    Service plans, maintenance & support plans are provided by Domestic &
    General Services Limited. Insurance policies are provided by Domestic &
    General Insurance PLC. Domestic & General Insurance PLC is an insurance
    undertaking, not an intermediary. We are the underwriter of the insurance
    policies and do not provide a personal recommendation or advice. Domestic &
    General Insurance PLC is authorised by the Prudential Regulation Authority
    and regulated by the Financial Conduct Authority and the Prudential
    Regulation Authority (Financial Services Register Number 202111). Our
    address and details of our authorisation can be checked on the FCA website
    or by contacting the FCA on 0800 111 6768.
  </p>
</dgx-ui-wl-footer>
