import {
  state,
  style,
  transition,
  trigger,
  group,
  animate,
} from '@angular/animations';

export const slideUpDown = trigger('slideInOut', [
  state('in', style({ height: '*', opacity: 0 })),
  transition(':leave', [
    style({ height: '*', opacity: 1 }),

    group([
      animate(200, style({ height: 0 })),
      animate('200ms ease-in-out', style({ opacity: '0' })),
    ]),
  ]),
  transition(':enter', [
    style({ height: '0', opacity: 0 }),

    group([
      animate(200, style({ height: '*' })),
      animate('200ms ease-in-out', style({ opacity: '1' })),
    ]),
  ]),
]);
