<div class="powered-by">
  <span class="mr-1">Powered by</span>
  <a
    [href]="linkUrl"
    target="_blank"
    class="powered-by__logo xs--middle flex-auto"
    (click)="onClick($event)"
    *ngIf="linkUrl; else justLogo"
  >
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </a>
</div>
<ng-template #justLogo>
  <span class="powered-by__logo xs--middle flex-auto">
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </span>
</ng-template>
<ng-template #logo>
  <img
    src="assets/images/dg-logo-colour.png"
    role="img"
    alt="Domestic & General"
  />
</ng-template>
