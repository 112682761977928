<sales-loader [@fadeInOut] *ngIf="loading$ | async"></sales-loader>
<dgx-ui-wl-header
  [clientLogoPath]="(clientLogoPath$ | async) || ''"
  [navigationLinks]="headerNavigationLinks"
></dgx-ui-wl-header>
<main>
  <router-outlet></router-outlet>
</main>
<dgx-ui-wl-ao-footer
  [protectLinks]="footerProtectLinks"
  [legalLinks]="footerLegalLinks"
  [usefulLinks]="footerUsefulLinks"
></dgx-ui-wl-ao-footer>
