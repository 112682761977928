import { Observable, OperatorFunction, pipe } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

export function mapToLatestFrom<T>(
  source$: Observable<T>
): OperatorFunction<unknown, T> {
  return pipe(
    withLatestFrom(source$),
    map((values: [unknown, T]) => values.pop() as T)
  );
}
