import { Inject, Injectable } from '@angular/core';
import {
  BuildConfig,
  ConfigAdapter,
  Environment,
  RuntimeConfig,
} from '@common/util-models';
import { CmsModuleConfig } from '@domgen/dgx-fe-content-management';
import { BehaviorSubject, from, isObservable, Observable, of } from 'rxjs';
import { map, mergeMap, reduce } from 'rxjs/operators';
import { filterNullUndefined } from '../../operators/filter-null-undefined/filter-null-undefined';
import { CONFIG_ADAPTER } from '../../tokens/config-adapter.token';
import { ENVIRONMENT } from '../../tokens/environment.token';
import { RUNTIME_CONFIG } from '../../tokens/runtime-config.token';

@Injectable({
  providedIn: 'root',
})
export class BuildConfigService {
  private _config$ = new BehaviorSubject<BuildConfig | undefined>(undefined);
  config$: Observable<BuildConfig> = this._config$.pipe(filterNullUndefined());
  defaultConfig!: BuildConfig;

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(RUNTIME_CONFIG) private runtimeConfig: RuntimeConfig,
    @Inject(CONFIG_ADAPTER) private configAdapters: ConfigAdapter[]
  ) {
    this.defaultConfig = {
      ...this.environment,
      ...this.runtimeConfig,
    } as BuildConfig;

    this.evaluateConfig();
  }

  getCmsConfig(
    componentMapping: CmsModuleConfig['componentMapping']
  ): CmsModuleConfig {
    return { ...this.config.cms_config, componentMapping } as CmsModuleConfig;
  }

  getFormbuilderConfig(): Partial<BuildConfig> {
    return {
      postcodeServiceKey: this.config.postcodeServiceKey,
      postcodeServiceFind: this.config.postcodeServiceFind,
      postcodeServiceRetrieve: this.config.postcodeServiceRetrieve,
    };
  }

  get config(): BuildConfig {
    return this._config$.value ?? this.defaultConfig;
  }

  private evaluateConfig(): void {
    from(this.configAdapters)
      .pipe(
        map((configAdapter: ConfigAdapter) => configAdapter.getConfig()),
        mergeMap(
          (config: Partial<BuildConfig> | Observable<Partial<BuildConfig>>) =>
            isObservable(config) ? config : of(config)
        ),
        reduce(
          (prevConfig: BuildConfig, config: Partial<BuildConfig>) => ({
            ...prevConfig,
            ...config,
          }),
          this.defaultConfig
        )
      )
      .subscribe((config: BuildConfig) => {
        this._config$.next(config);
      });
  }
}
