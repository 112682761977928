<div class="form-control form-control__submit">
  <button
    [class]="field?.classes"
    [class.btn--disabled]="disabled$ | async"
    type="submit"
    (click)="disabledValidate()"
  >
    <span [class]="field?.label?.classes || 'label'">
      {{ field?.label?.text }}
    </span>
    <i class="icon icon-arrow-right"></i>
  </button>
</div>
