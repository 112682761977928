import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromNavigation from './+state/navigation.reducer';
import { NavigationFacade } from './+state/navigation.facade';
import { NavigationConfig } from './navigation.types';
import { NAVIGATION_CONFIG } from './navigation.token';
import { StepCompletionNavigationGuard } from './step-completion-navigation.guard';
import { NavigationTriggerProvider } from './+state/navigation-trigger-provider';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromNavigation.NAVIGATION_FEATURE_KEY,
      fromNavigation.reducer
    ),
  ],
  providers: [NavigationFacade],
})
export class SharedDataAccessNavigationModule {
  static forRoot(
    navigationConfig: NavigationConfig
  ): ModuleWithProviders<SharedDataAccessNavigationModule> {
    return {
      ngModule: SharedDataAccessNavigationModule,
      providers: [
        {
          provide: NAVIGATION_CONFIG,
          useValue: navigationConfig,
        },
        StepCompletionNavigationGuard,
        NavigationTriggerProvider,
      ],
    };
  }
}
