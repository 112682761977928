import { Component, Input } from '@angular/core';
import { CmsDynamicComponentContent } from '@domgen/dgx-fe-content-management';

@Component({
  selector: 'common-cms-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
})
export class ParagraphComponent {
  @Input() content!: CmsDynamicComponentContent;
}
