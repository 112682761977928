import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import {
  CmsFormField,
  DirectDebitFormField,
} from '@domgen/dgx-fe-business-models';
import {
  DynamicFormbuilderService,
  FieldDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DirectDebitFormConfigService } from './direct-debit-form-config.service';

export interface DirectDebitFormState {
  formBuilderConfig: FieldDef[];
  cmsFormData: CmsFormField[];
  form: FormGroup;
  validate: boolean;
  resetDirectDebit: boolean;
}

@Injectable()
export class DirectDebitFormStateService extends ComponentStore<DirectDebitFormState> {
  // Selectors
  readonly formBuilderConfig$ = this.select(
    (state: DirectDebitFormState) => state.formBuilderConfig
  );
  readonly formGroup$ = this.select(
    (state: DirectDebitFormState) => state.form
  );
  readonly cmsFormData$ = this.select(
    (state: DirectDebitFormState) => state.cmsFormData
  );
  readonly validate$ = this.select(
    (state: DirectDebitFormState) => state.validate
  );

  readonly resetDirectDebit$ = this.select(
    (state: DirectDebitFormState) => state.resetDirectDebit
  );

  readonly valueChanges$ = this.formGroup$.pipe(
    switchMap((formGroup) => formGroup.valueChanges)
  );

  readonly setCmsFormData = this.updater(
    (state: DirectDebitFormState, value: CmsFormField[]) => ({
      ...state,
      cmsFormData: value,
    })
  );

  // *** Updater Sources ****
  private fieldDefinitionUpdater$ = this.cmsFormData$.pipe(
    switchMap((cmsData) =>
      of(cmsData).pipe(
        map((formFieldsData) => {
          return formFieldsData.length
            ? this.formConfig.getFormbuilderConfig(formFieldsData)
            : [];
        })
      )
    )
  );

  readonly validate = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state: DirectDebitFormState) => ({
      ...state,
      validate: true,
    })
  );

  readonly resetDirectDebitForm = this.updater(
    (state: DirectDebitFormState) => ({
      ...state,
      resetDirectDebit: true,
    })
  );

  private readonly cmsFieldDefinitionUpdater = this.updater(
    (state: DirectDebitFormState, value: FieldDef[]) => ({
      ...state,
      formBuilderConfig: value,
    })
  )(this.fieldDefinitionUpdater$);

  private formGroupUpdater$ = this.formBuilderConfig$.pipe(
    switchMap((formBuilderConfig) =>
      of(formBuilderConfig).pipe(
        map((conf) => this.dynamicFormBuilder.generateFormGroup(conf))
      )
    )
  );

  // Effects
  readonly resetDirectDebitFormFields = this.effect(
    (trigger$: Observable<boolean>) => {
      return trigger$.pipe(
        withLatestFrom(this.formGroup$),
        tap(([reset, formGroup]) => {
          if (reset) {
            formGroup?.patchValue({
              [DirectDebitFormField.SortCode]: '',
              [DirectDebitFormField.AccountNumber]: '',
              [DirectDebitFormField.AccountName]: '',
            });
          }
        })
      );
    }
  )(this.resetDirectDebit$);

  private readonly formGroupUpdater = this.updater(
    (state: DirectDebitFormState, value: FormGroup) => ({
      ...state,
      form: value,
    })
  )(this.formGroupUpdater$);

  readonly vm$ = this.select(
    this.formBuilderConfig$,
    this.formGroup$,
    this.validate$,
    this.resetDirectDebit$,
    (fieldDef, formGroup, validate, resetDirectDebit) => ({
      fieldDef,
      formGroup,
      validate,
      resetDirectDebit,
    })
  );

  constructor(
    private formConfig: DirectDebitFormConfigService,
    private dynamicFormBuilder: DynamicFormbuilderService
  ) {
    super({
      formBuilderConfig: [],
      cmsFormData: [],
      form: new FormGroup({}),
      validate: false,
      resetDirectDebit: false,
    });
  }
}
