import { Normalize } from '../../interfaces/normalize.interface';

// These have to be duplicated here as this is a publishable library and cannot depend on common.
type KeysOfUnion<T> = T extends T ? keyof T : never;

export function normalize<T>(
  items: T[],
  identifier: KeysOfUnion<T>
): Normalize<T> {
  return items.reduce((normalized: Normalize<T>, item: T) => {
    const key: unknown = item[identifier];

    if (typeof key === 'string' || typeof key === 'number') {
      normalized[key] = item;
    }
    return normalized;
  }, {});
}
