<dgx-dfb-field-layout
  [id]="id"
  [label]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
>
  <div class="month-year-wrapper">
    <div
      class="select-wrapper select form-control"
      [ngClass]="['is-' + validity]"
    >
      <select
        (change)="saveYear($event, yearSelect.value)"
        [attr.aria-label]="field?.yearLabel?.text"
        [class.select--empty]="value.year === undefined"
        [disabled]="disabled"
        [name]="field?.controlName + '-year'"
        #yearSelect
        class="form-control"
      >
        <option disabled selected>Select year</option>
        <option
          *ngFor="let option of field?.values"
          [value]="option.year"
          [selected]="option.year == ngControl?.control?.value?.year"
        >
          {{ option.label }}
        </option>
      </select>
      <i class="icon icon-arrow-down"></i>
      <ui-form-validation-icon
        [validityStatus]="validity"
      ></ui-form-validation-icon>
    </div>
  </div>
</dgx-dfb-field-layout>
