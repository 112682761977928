import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@common/util-foundation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppliancesApiService } from '../../services/appliances-api.service';
import * as AppliancesActions from '../appliances/appliances.actions';
import * as BrandsActions from './brands.actions';
@Injectable()
export class BrandsEffects {
  applianceSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppliancesActions.applianceSelected),
      map(({ code }) => BrandsActions.loadBrands({ code }))
    )
  );

  loadBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BrandsActions.loadBrands),
      switchMap(({ code }) =>
        this.appliancesService.getBrandsForAppliance(code).pipe(
          map((brands) => BrandsActions.loadBrandsSuccess({ brands })),
          catchError((error: HttpErrorResponse) => {
            this.handleError();
            return of(BrandsActions.loadBrandsFailure({ error }));
          })
        )
      )
    )
  );

  handleError() {
    this.errorService.handleError();
  }

  constructor(
    private actions$: Actions,
    private appliancesService: AppliancesApiService,
    private errorService: ErrorService
  ) {}
}
