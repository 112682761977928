import { CheckoutBasket, CheckoutBasketItem } from '@common/util-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CheckoutBasketPartialState,
  CHECKOUT_BASKET_FEATURE_KEY,
} from './checkout-basket.reducer';

// Lookup the 'CheckoutBasket' feature state managed by NgRx
export const getCheckoutBasketState = createFeatureSelector<
  CheckoutBasketPartialState,
  CheckoutBasket
>(CHECKOUT_BASKET_FEATURE_KEY);

export const getBasketId = createSelector(
  getCheckoutBasketState,
  (basket: CheckoutBasket | undefined) => basket?.basketId
);

export const getFirstItem = createSelector(
  getCheckoutBasketState,
  (basket: CheckoutBasket | undefined) => basket?.items[0]
);

// TODO: add support for multi item basket if required
export const getPaymentOptions = createSelector(
  getFirstItem,
  (basketItem: CheckoutBasketItem | undefined) => basketItem?.paymentOptions
);

export const getDDNumPayments = createSelector(
  getFirstItem,
  (basketItem: CheckoutBasketItem | undefined) =>
    basketItem?.paymentOptions.directDebit?.numPayments
);

// Based on single item basket selectors
export const isDirectDebitOnlyPaymentOption = createSelector(
  getPaymentOptions,
  (paymentOptions) => {
    if (paymentOptions) {
      return paymentOptions.directDebit && !paymentOptions.card;
    }

    return undefined;
  }
);
