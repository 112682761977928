<div class="form-group">
  <div class="toggle">
    <label
      class="switch"
      [ngClass]="{
        'toggle--readonly': isReadonly,
        'toggle--checked': isChecked,
        'toggle--disabled': field?.disabled
      }"
    >
      <input
        type="checkbox"
        [checked]="isChecked"
        [disabled]="isReadonly || field?.disabled"
        (click)="onClick()"
      />
      <span class="slider round" [class.active]="isChecked"></span>
    </label>

    <div [class]="field?.label?.classes || 'toggle__text'">
      {{ field?.label?.text }}
    </div>
  </div>

  <dgx-dfb-error [errorMessage]="errorMessage"></dgx-dfb-error>
</div>
