import { Injectable } from '@angular/core';
import { filterNullUndefined } from '@common/util-foundation';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getAWC, getStore } from './query-params.selector';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsFacade {
  constructor(private store: Store) {}

  awc$: Observable<string> = this.store
    .select(getAWC)
    .pipe(filterNullUndefined());

  store$: Observable<string | undefined> = this.store.select(getStore);
}
