import { KeysOfUnion, Normalize } from '@common/util-models';

export function normalize<T>(
  items: T[],
  identifier: KeysOfUnion<T>
): Normalize<T> {
  return items.reduce((normalized: Normalize<T>, item: T) => {
    const key: unknown = item[identifier];

    if (typeof key === 'string' || typeof key === 'number') {
      normalized[key] = item;
    }
    return normalized;
  }, {});
}
