import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { MarketingPreferencesModule } from '@domgen/dgx-fe-business-components';
import { PersonalDetailsFormComponent } from './personal-details-form/personal-details-form.component';
import { BannerModule } from './banner/banner.module';
import { RetryCardPaymentComponent } from './retry-card-payment/retry-card-payment.component';
import { CheckoutHeaderComponent } from './checkout-header/checkout-header.component';
import { CheckoutSafeFastSimpleComponent } from './checkout-safe-fast-simple/checkout-safe-fast-simple.component';
import { CommonUiSalesComponentsModule } from './common-ui-sales-components.module';
import { CommonUiWhitelabelComponentsModule } from '@common/ui-whitelabel-components';
import { CoverStartingDateComponent } from './cover-starting-date/cover-starting-date.component';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { OrderConfirmationThankyouTextComponent } from './order-confirmation-thankyou-text/order-confirmation-thankyou-text.component';
import { GotAQuestionComponent } from './got-a-question/got-a-question.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUiDynamicFormBuilderModule,
    MatDialogModule,
    BannerModule,
    MarketingPreferencesModule,
    CommonUiSalesComponentsModule,
    CommonUiWhitelabelComponentsModule,
    SharedUiComponentsCoreModule,
  ],
  declarations: [
    PersonalDetailsFormComponent,
    RetryCardPaymentComponent,
    CheckoutSafeFastSimpleComponent,
    CheckoutHeaderComponent,
    CoverStartingDateComponent,
    OrderConfirmationThankyouTextComponent,
    GotAQuestionComponent,
  ],
  exports: [
    PersonalDetailsFormComponent,
    CheckoutSafeFastSimpleComponent,
    CheckoutHeaderComponent,
    CoverStartingDateComponent,
    OrderConfirmationThankyouTextComponent,
    GotAQuestionComponent,
  ],
})
export class CheckoutUiModule {}
