<ui-panel>
  <ui-panel-header>
    <ui-panel-title>{{ title }}</ui-panel-title>
  </ui-panel-header>
  <ui-panel-body>
    <ng-content></ng-content>
    <p>
      By submitting your payment details, you are confirming that you are the
      account holder and no authorisation other than your own is required.
    </p>
    <p class="direct-debit-guarantee__link">
      <a
        href="https://www.domesticandgeneral.com/direct-debit-guarantee"
        target="_blank"
        class="link link--standalone"
      >
        The Direct Debit Guarantee
      </a>
    </p>
  </ui-panel-body>
</ui-panel>
