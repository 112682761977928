import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { slideUpDown } from '../../animations/animations';

@Component({
  selector: 'dgx-dfb-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideUpDown],
})
export class TooltipComponent {
  @Input() tooltip: string | undefined;
  @Input() tooltipName!: string;
}
