import { Injectable } from '@angular/core';
import { SelectPaymentPartialState } from '@common/data-access-select-payment';
import { filterNullUndefined } from '@common/util-foundation';
import {
  getWrappedInvalidPaymentDetails,
  WrappedInvalidPaymentDetails,
} from '@common/util-models';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { showPopupToRestartCheckoutJourney } from './checkout-page.selectors';
import { CheckoutPartialState } from './checkout.reducer';
import {
  getCheckoutError,
  getDirectDebitDetailsValid,
} from './checkout.selectors';

/**
 * This will only contain selectors that we need to reuse across pages. This will ensure that we do not duplicate selectors across
 * pages going forward in each and every facade. There will never be methods here that dispatch actions to the store. These
 * will be injected across various feature pages/ component services/ state services. So having methods here that dispatch actions
 * will lead to action reuse and use of actions as commands. We want to avoid actions reuse and have unique actions for every specific
 * event that happens across pages.
 *
 * @export
 * @class CheckoutCommonSelectorsFacade
 */
@Injectable()
export class CheckoutCommonSelectorsFacade {
  showPopupToRestartJourney$ = this.store.pipe(
    select(showPopupToRestartCheckoutJourney),
    filter((showPopup) => !!showPopup)
  );

  invalidPaymentDetails$ = this.store.pipe(
    select(getDirectDebitDetailsValid),
    filterNullUndefined(),
    map((directDebitDetailsValid) =>
      directDebitDetailsValid === false
        ? getWrappedInvalidPaymentDetails(true)
        : getWrappedInvalidPaymentDetails(false)
    )
  ) as Observable<WrappedInvalidPaymentDetails>;

  planCreationError$ = this.store.pipe(
    select(getCheckoutError),
    filterNullUndefined()
  );

  constructor(
    private store: Store<CheckoutPartialState & SelectPaymentPartialState>
  ) {}
}
