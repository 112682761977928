import {
  EmbeddedViewRef,
  Injectable,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Injectable()
export class TooltipService {
  private targets: Map<string, ViewContainerRef>;

  constructor() {
    this.targets = new Map<string, ViewContainerRef>();
  }

  registerTarget(targetName: string, viewContainer: ViewContainerRef) {
    this.targets.set(targetName, viewContainer);
  }

  attachTemplateToTarget(
    template: TemplateRef<unknown>,
    targetName: string
  ): EmbeddedViewRef<unknown> | undefined {
    const targetViewContainer = this.getTarget(targetName);
    const embeddedView = targetViewContainer?.createEmbeddedView(template);
    embeddedView?.detectChanges();
    return embeddedView;
  }

  clear(targetName: string) {
    this.getTarget(targetName)?.clear();
  }

  getTarget(targetName: string) {
    return this.targets.get(targetName);
  }
}
