export const CHECKOUT_DIRECT_DEBIT_FORM = {
  results: [
    {
      formName: 'checkoutDirectDebitForm',
      application: 'sales',
      label: 'Name of account holder',
      controlName: 'accountName',
      placeholder: 'Name of account holder',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Name of account holder is required',
        },
      },
    },

    {
      formName: 'checkoutDirectDebitForm',
      application: 'sales',
      label: 'Account number',
      controlName: 'accountNumber',
      placeholder: 'Account number',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Account number is required',
        },
        '1': {
          type: 'invalidAccountNumber',
          message: 'Account number must be 8 digits',
        },
      },
    },

    {
      formName: 'checkoutDirectDebitForm',
      application: 'sales',
      label: 'Sort code',
      controlName: 'sortCode',
      placeholder: '',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Sort code is required',
        },
        '1': {
          type: 'minLength',
          message: 'Sort code should contain 6 digits',
        },
        '2': {
          type: 'invalidSortCode',
          message: 'Enter a valid sort code',
        },
      },
    },

    {
      formName: 'checkoutDirectDebitForm',
      application: 'sales',
      label:
        'Which day of the month you would like monthly payments to be made?',
      controlName: 'monthlyPaymentDay',
      placeholder: 'Select',
      options: '',
      tooltip: '',
      hint: '',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Payment day is required',
        },
      },
    },
    {
      formName: 'checkoutDirectDebitForm',
      label: '',
      tooltip: '',
      controlName: 'termsAndConditions',
      hint: '',
      jcrName: 'sales_directDebitForm_termsAndConditions',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Please confirm that you have read and agree',
        },
      },
    },
  ],
};
