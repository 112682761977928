<dgx-dfb-field-layout
  [id]="id"
  [label]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
  data-hook="dgx-sales-input"
>
  <div class="input-wrapper">
    <textarea
      #input
      [id]="id"
      [name]="field?.controlName"
      class="form-control"
      [dgxFdnQmSanitise]="field?.sanitise"
      [disabled]="disabled"
      [placeholder]="field?.placeholder"
      (input)="onChange($any($event))"
      (change)="onChange($any($event))"
      (blur)="onBlur($any($event))"
      autocomplete="off"
      [attr.maxlength]="field?.maxlength || undefined"
      >{{ ngControl?.control?.value }}</textarea
    >
    <ui-form-validation-icon
      [validityStatus]="validity"
    ></ui-form-validation-icon>
  </div>
  <div class="chars-left-indicator" *ngIf="field?.maxlength">
    {{ charsLeft }} characters remaining
  </div>
</dgx-dfb-field-layout>
