import {
  Directive,
  Input,
  HostListener,
  Renderer2,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: 'input[dgxDfbNumericMask]',
})
export class NumericMaskDirective {
  private enabled!: boolean;
  @Input() precision = '0';

  @Input('dgxDfbNumericMask')
  get numericMask(): boolean | string {
    return this.enabled;
  }

  set numericMask(value: boolean | string) {
    this.enabled = value === '' || value === true || value === 'true';
  }

  private nativeElement: HTMLInputElement | null = null;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.nativeElement = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent): void {
    if (!this.enabled) {
      return;
    }
    const newInputValue = this.applyNumericMask(
      (event.target as HTMLInputElement).value
    );

    this.renderer.setProperty(this.nativeElement, 'value', newInputValue);
  }

  applyNumericMask(inputValue: string) {
    inputValue = this.filterNumericTextFromInput(inputValue);
    inputValue = this.checkInputPrecision(inputValue, Number(this.precision));
    return inputValue;
  }

  private filterNumericTextFromInput(inputValue: string): string {
    return inputValue
      .split('')
      .filter((i: string) => {
        return i.match('\\d') || i === '.';
      })
      .join('');
  }

  private checkInputPrecision(inputValue: string, precision: number) {
    const precisionRegEx = new RegExp('\\.' + `\\d{${precision}}.*$`);
    const precisionMatch = inputValue.match(precisionRegEx);
    if (precisionMatch && precisionMatch[0].length - 1 > precision) {
      const diff = precisionMatch[0].length - 1 - precision;
      inputValue = inputValue.substring(0, inputValue.length - diff);
    }
    return inputValue;
  }
}
