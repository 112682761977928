import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSelectPayment from './+state/select-payment.reducer';
import { SelectPaymentEffects } from './+state/select-payment.effects';
import { SelectPaymentFacade } from './+state/select-payment.facade';
import { SelectedPaymentTypeGuard } from './guards/selected-payment-type/selected-payment-type.guard';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSelectPayment.SELECTPAYMENT_FEATURE_KEY,
      fromSelectPayment.reducer
    ),
    EffectsModule.forFeature([SelectPaymentEffects]),
  ],
  providers: [SelectPaymentFacade, SelectedPaymentTypeGuard],
})
export class CommonDataAccessSelectPaymentModule {}
