import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CorrelationIdActions } from '@common/data-access-shared';
import { ErrorService } from '@common/util-foundation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AppliancesApiService } from '../../services/appliances-api.service';
import * as AppliancesActions from './appliances.actions';

@Injectable()
export class AppliancesEffects {
  loadAppliances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppliancesActions.loadAppliances),
      switchMap(() =>
        this.appliancesService.getAppliancesWithCorrelationId().pipe(
          map(({ appliances, correlationId }) =>
            AppliancesActions.loadAppliancesSuccess({
              appliances,
              correlationId,
            })
          ),
          catchError((error: HttpErrorResponse) => {
            this.handleError();
            return of(AppliancesActions.loadAppliancesFailure({ error }));
          })
        )
      )
    )
  );

  loadAppliancesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppliancesActions.loadAppliancesSuccess),
      filter(({ correlationId }) => !!correlationId),
      map(({ correlationId }) =>
        CorrelationIdActions.setCorrelationID({ correlationId })
      )
    )
  );

  handleError() {
    this.errorService.handleError();
  }

  constructor(
    private actions$: Actions,
    private appliancesService: AppliancesApiService,
    private errorService: ErrorService
  ) {}
}
