<div class="you-are-in-safe-hands">
  <div class="md--flex-row">
    <!-- Banner -->
    <div class="xs-12 md-6 lg-6 ml-0 no-gutter">
      <img
        class="banner"
        alt="You are in safe hands"
        src="./assets/images/img-son-and-father-with-oven.jpg"
      />
    </div>
    <div class="xs-12 md-6 lg-6 ml-0 mr-0 no-gutter pb-1 stats-container">
      <div class="m-4">
        <h3 class="protect-things">
          Count on us to look after the things you need
        </h3>
        <dl class="md--flex-row">
          <div class="xs-12 md-5 lg-5 stats">
            <dt class="stats-label">
              Number of UK<br />
              customers
            </dt>
            <dd class="stats-amount mt-1">7.3 million</dd>
          </div>

          <div class="md-2 lg-2"></div>

          <div class="xs-12 md-5 lg-5 stats">
            <dt class="stats-label">Appliances repaired every year</dt>
            <dd class="stats-amount mt-1">2.4 million</dd>
          </div>

          <div class="xs-12 md-5 lg-5 stats">
            <dt class="stats-label">Appliances repaired every day</dt>
            <dd class="stats-amount mt-1">6,700</dd>
          </div>

          <div class="md-2 lg-2"></div>

          <div class="xs-12 md-5 lg-5 stats">
            <dt class="stats-label">Appliances replaced last year</dt>
            <dd class="stats-amount mt-1">490,000</dd>
          </div>
        </dl>
      </div>
      <div class="xs-12 md-12 lg-12 ml-0 mr-0 no-gutter">
        <div class="ml-4 mr-4 note">
          <span>(All figures accurate, as of March 2022.)</span>
        </div>
      </div>
    </div>
  </div>
</div>
