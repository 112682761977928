import {
  ApplianceResponse,
  Basket,
  BasketItem,
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  calculateNumberSubsequentPayments,
  CreateQuoteResponse,
  Normalize,
} from '@common/util-models';
import { produce } from 'immer';

export function addNumberSubsequentPaymentsToBasket(basket: Basket): Basket {
  return produce(basket, (draft) => {
    draft.items.map((basketItem: BasketItem) => {
      basketItem.data.quotes.map((quote: BasketItemQuoteDetails) =>
        quote.paymentOptions.map(
          (paymentOption: BasketItemPaymentOption) =>
            (paymentOption.numSubsequentPayments = calculateNumberSubsequentPayments(
              paymentOption
            ))
        )
      );
    });
  });
}

export function addNumberSubsequentPaymentsToQuoteResponse(
  quoteResponse: CreateQuoteResponse
): CreateQuoteResponse {
  return {
    ...quoteResponse,
    basket: addNumberSubsequentPaymentsToBasket(quoteResponse.basket),
  };
}

export function addApplianceCategoryToBasket(
  basket: Basket,
  appliances: Normalize<ApplianceResponse> | undefined
): Basket {
  if (!appliances) {
    return basket;
  }
  return {
    ...basket,
    items: basket.items.map((item: BasketItem) => ({
      ...item,
      data: {
        ...item.data,
        applianceDetails: {
          ...item.data.applianceDetails,
          applianceCategory:
            appliances[item.data.applianceDetails.applianceCode]?.category ??
            '',
        },
      },
    })),
  };
}
