export const CHECKOUT_SELECT_PAYMENT_FORM = {
  results: [
    {
      formName: 'checkoutSelectPaymentForm',
      label: 'Would you also like to receive a copy of your plan in the post?',
      placeholder: 'None',
      tooltip: '',
      controlName: 'paperless',
      hint: '',
      message:
        'Going paperless means there’s less post to deal with, file or recycle.',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      jcrName: 'sales_checkoutSelectPaymentForm_paperless',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Select one of the options',
          '@nodes': [],
        },
      },
    },
    {
      formName: 'checkoutSelectPaymentForm',
      label: '',
      tooltip: '',
      controlName: 'termsAndConditions',
      hint: '',
      jcrName: 'sales_checkoutSelectPaymentForm_termsAndConditions',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Please confirm that you have read and agree',
        },
      },
    },
  ],
};
