import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { QuotesFacade } from '../../facades/quotes.facade';
import { BuildConfigService } from '@common/util-foundation';
import { SelectedBasketItem } from '@common/util-models';
import { combineLatest, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Injectable()
export class HasSelectedBasketItemsGuard implements CanActivate {
  constructor(
    private buildConfigService: BuildConfigService,
    private quotesFacade: QuotesFacade,
    private router: Router
  ) {}

  public canActivate(): Observable<boolean> {
    return combineLatest([
      this.quotesFacade.selectedItems$,
      this.quotesFacade.currentItemId$,
    ]).pipe(
      first(),
      tap(
        ([selectedItems, currentItemId]: [
          SelectedBasketItem[],
          string | undefined
        ]) =>
          this.checkCurrentItemIsSelectedBasketItem(
            selectedItems,
            currentItemId
          )
      ),
      map(([selectedItems]: [SelectedBasketItem[], string | undefined]) => {
        const hasSelectedBasketItems = selectedItems?.length > 0;
        return hasSelectedBasketItems;
      }),
      tap((hasSelectedBasketItems: boolean) => {
        if (!hasSelectedBasketItems) {
          this.router.navigateByUrl(
            this.buildConfigService.config.applianceDetailsPage
          );
        }
      })
    );
  }

  private checkCurrentItemIsSelectedBasketItem(
    selectedItems: SelectedBasketItem[],
    currentItemId: string | undefined
  ): void {
    const selectedItemIds: string[] = selectedItems.map(
      (selectedItem) => selectedItem.itemId
    );

    if (!selectedItemIds.length) {
      return;
    } else if (!currentItemId) {
      this.quotesFacade.setCurrentItemIdToFirstSelectedItem();
    } else if (!selectedItemIds.includes(currentItemId)) {
      this.quotesFacade.setCurrentItemIdToFirstSelectedItem();
    }
  }
}
