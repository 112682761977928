import { Injectable } from '@angular/core';
import {
  CmsFormField,
  PlanDocumentsFormField,
} from '@domgen/dgx-fe-business-models';
import {
  CheckboxToggleDef,
  ControlType,
  FieldDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import * as CmsHelpers from '../cms-form-helper/cms-form-helpers';

@Injectable()
export class PlanDocumentsFormConfigService {
  public getFormbuilderConfig(
    cmsFormData: CmsFormField[],
    prefilledForm?: boolean | null
  ): FieldDef[] {
    return [
      this.getInputDefByFieldName(
        cmsFormData,
        PlanDocumentsFormField.Paperless,
        prefilledForm
      ),
    ] as FieldDef[];
  }

  private getInputDefByFieldName(
    cmsFormData: CmsFormField[],
    formField: PlanDocumentsFormField,
    initialValue: boolean | null | undefined
  ): FieldDef {
    let fieldDef: FieldDef;
    const cmsFormField = CmsHelpers.getFormField<CmsFormField>(
      cmsFormData,
      formField
    );
    const defaultFieldDef = CmsHelpers.getDefaultFieldDef(
      cmsFormField,
      formField
    );

    switch (formField) {
      case PlanDocumentsFormField.Paperless:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.CHECKBOX,
          initialValue: !!initialValue,
          label: {
            text:
              'If you still require a paper copy of your plan documents, tick here.',
          },
        } as CheckboxToggleDef;
        break;
      default:
        fieldDef = {
          ...defaultFieldDef,
        } as FieldDef;
        break;
    }

    return fieldDef;
  }
}
