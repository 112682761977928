import {
  ApplianceFormSubmit,
  ApplianceFormValueWithItemId,
} from '@common/util-models';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { ApplianceDetailsState } from '../appliance-details.reducer';
import { getApplianceDetailsState } from '../appliance-details.selectors';
import {
  applianceFormDataAdapter,
  ApplianceFormDataState,
} from './appliance-form-data.reducer';

export const { selectEntities } = applianceFormDataAdapter.getSelectors();

export const getFormDataState = createSelector(
  getApplianceDetailsState,
  (state: ApplianceDetailsState): ApplianceFormDataState => state.formData
);

export const getPrepopulatedFormValue = createSelector(
  getFormDataState,
  (state: ApplianceFormDataState): ApplianceFormSubmit | undefined =>
    state.prepopulatedFormValue
);

export const selectFormById = (props: { itemId: string }) =>
  createSelector(
    getFormDataState,
    (state: ApplianceFormDataState): ApplianceFormValueWithItemId | undefined =>
      state.entities[props.itemId]
  );

export const getFormValueEntitiesByItemId = (props: { itemIds: string[] }) =>
  createSelector(
    selectEntities,
    (
      applianceFormDataEntities: Dictionary<ApplianceFormValueWithItemId>
    ): Dictionary<ApplianceFormValueWithItemId> =>
      Object.entries(applianceFormDataEntities).reduce(
        (
          result: Dictionary<ApplianceFormValueWithItemId>,
          [itemId, formData]: [string, ApplianceFormValueWithItemId | undefined]
        ) => {
          if (props.itemIds.includes(itemId)) {
            result[itemId] = formData;
          }
          return result;
        },
        {}
      )
  );

export const getFormValuesByItemId = (props: { itemIds: string[] }) =>
  createSelector(
    getFormValueEntitiesByItemId(props),
    (
      applianceFormDataEntities: Dictionary<ApplianceFormValueWithItemId>
    ): ApplianceFormValueWithItemId[] =>
      Object.entries(applianceFormDataEntities).reduce(
        (
          result: ApplianceFormValueWithItemId[],
          [itemId, formData]: [string, ApplianceFormValueWithItemId | undefined]
        ) => {
          if (props.itemIds.includes(itemId) && formData) {
            result.push(formData);
          }
          return result;
        },
        []
      )
  );
