import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TrustPilotMicroComponent } from './trust-pilot-micro/trust-pilot-micro.component';
import { TrustPilotComponent } from './trust-pilot/trust-pilot.component';
import { TrustboxComponent } from './trustbox/trustbox.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TrustboxComponent,
    TrustPilotComponent,
    TrustPilotMicroComponent,
  ],
  exports: [TrustboxComponent, TrustPilotComponent, TrustPilotMicroComponent],
})
export class TrustBoxUiModule {}
