import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { CmsData, CmsDataResolverConfig, CmsPage } from '@common/util-models';
import { forkJoin, Observable } from 'rxjs';
import { CmsDataService } from './cms-data.service';

@Injectable({
  providedIn: 'root',
})
export class CmsDataResolver implements Resolve<Observable<CmsData> | null> {
  constructor(private cmsDataService: CmsDataService) {}

  private buildMultiPageResult(pageMap: { [key: string]: CmsPage }) {
    const keys = Object.keys(pageMap);
    //get observable for each requested cms page
    const observableMap = keys.reduce((acc, key) => {
      return {
        ...acc,
        [key]: this.cmsDataService.getCmsDataForPage(pageMap[key] as CmsPage),
      };
    }, {});
    //combine into single result
    return forkJoin(observableMap);
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<CmsData> | null | Observable<{ [key: string]: CmsData }> {
    if (typeof route.data?.cmsDataPage === 'string') {
      return this.cmsDataService.getCmsDataForPage(
        route.data.cmsDataPage as CmsPage
      );
    } else if (typeof route.data?.cmsDataPage === 'object') {
      return this.buildMultiPageResult(route.data.cmsDataPage);
    }
    //fallback for passing config directly
    return route.data?.cmsDataConfig
      ? this.cmsDataService.getCmsData(
          route.data?.cmsDataConfig as CmsDataResolverConfig
        )
      : null;
  }
}
