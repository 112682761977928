import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { BuildConfigService } from '@common/util-foundation';
import { Router } from '@angular/router';
import * as fromSelectPaymentActions from './select-payment.actions';
import * as fromSelectPaymentSelectors from './select-payment.selectors';
import { PaymentType } from '@common/util-models';
import { SelectPaymentPartialState } from './select-payment.reducer';
import { AuthPartialState, getIsLoggedIn } from '@shared/data-access-auth';

@Injectable()
export class SelectPaymentEffects {
  proceedToPaymentPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromSelectPaymentActions.proceedToPayment),
        withLatestFrom(
          this.store.pipe(
            select(fromSelectPaymentSelectors.getSelectedPaymentType)
          ),
          this.store.pipe(select(getIsLoggedIn))
        ),
        tap(([, selectedPaymentType, isLoggedOnUser]) =>
          selectedPaymentType === PaymentType.Card
            ? this.router.navigateByUrl(
                this.buildConfig.config.checkoutCardPaymentPage
              )
            : isLoggedOnUser
            ? this.router.navigateByUrl(
                this.buildConfig.config.checkoutLoggedInUserPage
              )
            : this.router.navigateByUrl(
                this.buildConfig.config.checkoutDirectDebitPage
              )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private buildConfig: BuildConfigService,
    private actions$: Actions,
    private store: Store<SelectPaymentPartialState & AuthPartialState>
  ) {}
}
