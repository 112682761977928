/* istanbul ignore file */
export * from './lib/+state/feature-config.facade';
export {
  FeatureConfigPartialState,
  FeatureConfigState,
  FEATURE_CONFIG_FEATURE_KEY,
} from './lib/+state/feature-config.reducers';
export * from './lib/common-data-access-feature-config.module';
export * from './lib/services';
export * from './lib/utils';
import * as FeatureConfigSelectors from './lib/+state/feature-config.selectors';
export { FeatureConfigSelectors };
