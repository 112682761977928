<form *ngIf="form && fieldset" [formGroup]="form" (ngSubmit)="onSubmit()">
  <ng-container *ngFor="let field of fieldset">
    <ng-container [ngSwitch]="field.controlType">
      <ng-container *ngSwitchCase="controlType.INPUT">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-input
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-input>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.TEXTAREA">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-textarea
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-textarea>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.PASSWORD">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-password
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-password>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.TYPEAHEAD">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-type-ahead
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-type-ahead>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.ADDRESS">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-address-lookup
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-address-lookup>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.ADDRESSLIST">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-address-list
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-address-list>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.TOGGLE">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-toggle-switch
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-toggle-switch>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.ACCEPT">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-accept
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-accept>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.CHECKBOX">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-checkbox
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-checkbox>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.YESNO">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-yes-no
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-yes-no>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.RADIO">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-radio
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-radio>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.SELECT">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-select
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-select>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.MONTHYEARDATE">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-month-year-date
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            [monthYearDateUpdater]="monthYearDateUpdater"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-month-year-date>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.YEARDATE">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-year-date
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-year-date>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.SORTCODE">
        <ng-container *ngIf="getFieldToShow(field)">
          <dgx-dfb-sortcode
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-sortcode>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.LISTBOX">
        <ng-container *ngIf="getFieldToShow($any(field))">
          <dgx-dfb-list-box
            [formControlName]="$any(field).controlName"
            [field]="$any(field)"
            [validate]="validate"
            (analytics)="handleAnalytics($event)"
          ></dgx-dfb-list-box>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="controlType.SUBMIT">
        <ng-container *ngIf="getFieldToShow($any(field))">
          <dgx-dfb-submit-button [field]="$any(field)"></dgx-dfb-submit-button>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-content
          *ngTemplateOutlet="projected; context: { $implicit: field }"
        ></ng-content>
      </ng-container>
    </ng-container>
  </ng-container>
</form>
