import { MarketingPreferences } from '@domgen/dgx-fe-business-models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  DirectDebit,
  PersonalDetailsFormField,
  PersonalDetailsFormSubmitType,
  PersonalDetailsState,
} from '@common/util-models';
import * as PersonalDetailsActions from './personal-details.actions';
import { CheckoutBasketActions } from '@common/data-access-checkout-basket';
import { PersonalDetailsSharedActions } from '@common/data-access-shared';

export const PERSONAL_DETAILS_FEATURE_KEY = 'guestPersonalDetails';

export interface PersonalDetailsPartialState {
  readonly [PERSONAL_DETAILS_FEATURE_KEY]: PersonalDetailsState;
}

export const initialState: PersonalDetailsState = {
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  address: null,
  marketingPreferences: null,
  directDebitDetails: null,
  paperlessPlanDocuments: null,
};

export const personalDetailsReducer = createReducer(
  initialState,

  on(
    PersonalDetailsActions.updatePersonalDetails,
    PersonalDetailsSharedActions.setPersonalDetails,
    (state, action: { payload: Omit<PersonalDetailsFormSubmitType, 'payment'> }) => {
      return <PersonalDetailsState>{
        ...state,
        title: action.payload.title,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        mobileNumber: action.payload.mobileNumber,
        address: { ...action.payload[PersonalDetailsFormField.Address] },
      };
    }
  ),

  on(
    PersonalDetailsActions.updateDirectDebit,
    (state, action: { payload: DirectDebit }) => {
      return <PersonalDetailsState>{
        ...state,
        directDebitDetails: { ...action.payload },
      };
    }
  ),

  on(
    PersonalDetailsActions.updateMarketingPreference,
    (state, action: { payload: MarketingPreferences }) => {
      return <PersonalDetailsState>{
        ...state,
        marketingPreferences: { ...action.payload },
      };
    }
  ),

  on(PersonalDetailsActions.exitingPersonalDetails, (state) => {
    return <PersonalDetailsState>{
      ...state,
      paperlessPlanDocuments:
        state.paperlessPlanDocuments === null
          ? true
          : state.paperlessPlanDocuments,
    };
  }),

  on(
    PersonalDetailsActions.updatePlanDocumentPreference,
    (state, { paperless: paperlessPlanDocuments }) => {
      return {
        ...state,
        paperlessPlanDocuments,
      };
    }
  ),
  on(CheckoutBasketActions.setBasket, (state) => {
    return {
      ...state,
      paperlessPlanDocuments: null,
    };
  }),
);

export function reducer(
  state: PersonalDetailsState | undefined,
  action: Action
) {
  return personalDetailsReducer(state, action);
}
