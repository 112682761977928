import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  Brand,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
} from '@common/util-models';
import * as BrandsActions from './brands.actions';

export const BRANDS_KEY = 'brands';

export interface BrandsState extends EntityState<Brand> {
  remoteState: RemoteData;
}

export const brandsAdapter: EntityAdapter<Brand> = createEntityAdapter<Brand>({
  selectId: (brand) => brand.code,
});

export const initialBrandsState: BrandsState = brandsAdapter.getInitialState({
  remoteState: remoteDataNotFetched(),
});

export const brandsReducer = createReducer(
  initialBrandsState,
  on(BrandsActions.loadBrands, (state) =>
    brandsAdapter.removeAll({
      ...state,
      remoteState: remoteDataLoading(),
    })
  ),

  on(BrandsActions.loadBrandsSuccess, (state, { brands }) =>
    brandsAdapter.setAll(brands, {
      ...state,
      remoteState: remoteDataOK(null),
    })
  ),

  on(BrandsActions.loadBrandsFailure, (state, { error }) => ({
    ...state,
    remoteState: remoteDataError(error),
  })),

  on(BrandsActions.resetBrands, () => {
    return initialBrandsState;
  })
);
