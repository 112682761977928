<div *ngIf="faqs" class="faqs container">
  <h2 class="text--center">Frequently Asked Questions</h2>

  <p class="faqs__sub-title text--center">We’re here to help</p>

  <sales-faq
    *ngFor="let faq of faqs.results"
    question="{{ faq.question }}"
    answer="{{ faq.answer }}"
  ></sales-faq>

  <div class="faqs__link text--center">
    <a
      href="https://www.domesticandgeneral.com/content/help-advice-section/faq"
      target="_blank"
      class="link"
    >
      Need more information? View all our Frequently Asked Questions
    </a>
  </div>
</div>
