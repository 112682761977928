import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { ParseRequest, ParseResponse, ParseResult } from '@common/util-models';
import { Observable, of, OperatorFunction, pipe, ReplaySubject } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WINDOW } from '../../tokens';
import { UrlQueryParamsParser } from '../url-query-params-parser/url-query-params-parser.service';

@Injectable({
  providedIn: 'root',
})
export class QuoteParamsParserService<T> {
  constructor(
    @Inject(WINDOW) private window: Window,
    private queryParamParser: UrlQueryParamsParser
  ) {}

  private parsedResultSubject = new ReplaySubject<ParseResponse<T>>(1);
  parsedResult$ = this.parsedResultSubject.asObservable();

  parse(parseRequest: ParseRequest<T>): Observable<ParseResponse<T>> {
    return of(parseRequest).pipe(
      withLatestFrom(
        of(this.queryParamParser.parseQueryParams(this.window.location.search))
      ),
      map(([parseRequest, queryParams]) => ({
        parseRequest,
        queryParams,
      })),
      this.checkQueryParamsContainParseParams(),
      switchMap((param) =>
        !param.queryParamsContainParseRequestParams
          ? of({
              result: ParseResult.NotApplied,
            } as ParseResponse<T>)
          : of(param).pipe(this.validateParams())
      ),
      tap((parseResult) => this.parsedResultSubject.next(parseResult))
    );
  }

  private validateParams(): OperatorFunction<
    {
      parseRequest: ParseRequest<T>;
      queryParams: Params;
      queryParamsContainParseRequestParams: boolean;
    },
    ParseResponse<T>
  > {
    return pipe(
      map(({ parseRequest, queryParams }) => {
        const form = this.reduceControls(parseRequest, queryParams);
        return {
          form,
          result: form.valid ? ParseResult.Success : ParseResult.Failure,
          model: form.value,
        } as ParseResponse<T>;
      })
    );
  }

  private reduceControls(parseRequest: ParseRequest<T>, queryParams: Params) {
    const controls = Object.keys(parseRequest).reduce(
      (previousValue: { [key: string]: FormControl }, param) => ({
        ...previousValue,
        [param]: new FormControl(
          queryParams[param],
          parseRequest[(param as unknown) as keyof T]
        ),
      }),
      {}
    );
    return new FormGroup(controls);
  }

  private checkQueryParamsContainParseParams(): OperatorFunction<
    {
      parseRequest: ParseRequest<T>;
      queryParams: Params;
    },
    {
      parseRequest: ParseRequest<T>;
      queryParams: Params;
      queryParamsContainParseRequestParams: boolean;
    }
  > {
    return pipe(
      map(({ parseRequest, queryParams }) => {
        const parseRequestKeys = Object.keys(parseRequest);
        const queryParamsKeys = Object.keys(queryParams);

        const queryParamsContainParseRequestParams = parseRequestKeys.reduce(
          (queryParamContainsParseParam, parseRequestKey) =>
            queryParamContainsParseParam ||
            queryParamsKeys.indexOf(parseRequestKey) >= 0
              ? true
              : false,
          false
        );

        return {
          parseRequest: parseRequest,
          queryParams: queryParams,
          queryParamsContainParseRequestParams,
        };
      })
    );
  }
}
