<div *ngIf="isMultiBasket" class="appliance-details-form-header">
  <ng-container *ngIf="multiItemQuoteCountIsAtMaximum">
    <h2 class="appliance-details-form-header__title">
      You've reached the maximum number of appliances
    </h2>
    <p class="appliance-details-form-header__sub-title">
      To add protection for more appliances, checkout these
      {{ multiBasketMaximum }} items first and then start a new quote
    </p>
  </ng-container>

  <ng-container
    *ngIf="
      !multiItemQuoteCountIsAtMaximum && hasExistingWhiteGoodsApplianceQuote
    "
  >
    <h2 class="appliance-details-form-header__title">Add another appliance</h2>
    <p class="appliance-details-form-header__sub-title">
      <ng-container [ngTemplateOutlet]="multiItemSubtitle"></ng-container>
    </p>
  </ng-container>

  <ng-container
    *ngIf="
      !multiItemQuoteCountIsAtMaximum && !hasExistingWhiteGoodsApplianceQuote
    "
  >
    <h2 class="appliance-details-form-header__title">
      Get a {{ discountPercentage ? 'discounted' : 'quick' }} quote for up to
      {{ multiBasketMaximum }}
      home appliances
    </h2>
    <p class="appliance-details-form-header__sub-title">
      <ng-container [ngTemplateOutlet]="singleItemSubtitle"></ng-container>
    </p>
  </ng-container>
</div>

<div *ngIf="!isMultiBasket" class="appliance-details-form-header">
  <h2 class="appliance-details-form-header__title">Let's start your quote</h2>
  <p class="appliance-details-form-header__sub-title">
    <ng-container [ngTemplateOutlet]="singleItemSubtitle"></ng-container>
  </p>
</div>

<ng-template #singleItemSubtitle>
  <ng-content select="[single-item-subtitle]"></ng-content>
</ng-template>

<ng-template #multiItemSubtitle>
  <ng-content select="[multi-item-subtitle]"></ng-content>
</ng-template>
