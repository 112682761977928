import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DirectDebitFormField } from '@domgen/dgx-fe-business-models';
import {
  CheckboxToggleDef,
  ControlType,
  controlValid,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'wl-direct-debit-account-holder-checkbox',
  templateUrl: './direct-debit-account-holder-checkbox.component.html',
  styleUrls: ['./direct-debit-account-holder-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectDebitAccountHolderCheckboxComponent {
  field: CheckboxToggleDef = {
    controlType: ControlType.CHECKBOX,
    controlName: DirectDebitFormField.AccountHolderConfirmation,
    initialValue: false,
    validators: [Validators.requiredTrue],
    label: {
      text:
        'I confirm that I am the account holder, that these bank details are correct and that I am the only person required to authorise debits from this account.',
    },
  };
  statusValidSubject = new BehaviorSubject<boolean>(false);
  validateSubject = new BehaviorSubject<boolean>(false);
  displayError$ = combineLatest([
    this.statusValidSubject,
    this.validateSubject,
  ]).pipe(
    untilDestroyed(this),
    map(([statusValid, validate]) => !statusValid && validate)
  );
  formGroup: FormGroup;

  @Output() valid: EventEmitter<boolean> = new EventEmitter();
  @Input() set validate(validate: boolean) {
    this.validateSubject.next(validate);
  }

  constructor(@Inject(DOCUMENT) readonly document: Document) {
    this.formGroup = new FormGroup({
      [DirectDebitFormField.AccountHolderConfirmation]: new FormControl(
        false,
        Validators.requiredTrue
      ),
    });
    of(this.formGroup)
      .pipe(untilDestroyed(this), controlValid())
      .subscribe((result) => {
        this.statusValidSubject.next(result);
        this.valid.emit(result);
      });

    this.displayError$
      .pipe(
        filter((error) => Boolean(error)),
        tap(() => this.scrollToCheckbox())
      )
      .subscribe();
  }

  scrollToCheckbox(): void {
    const firstElementWithError = this.document.querySelector(
      'dgx-dfb-checkbox'
    ) as HTMLElement;
    if (firstElementWithError) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
