import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wl-upgrade-banner',
  templateUrl: './upgrade-banner.component.html',
  styleUrls: ['./upgrade-banner.component.scss'],
})
export class UpgradeBannerComponent {
  @Input() desc = '';
  @Input() linkText = '';
  @Output() linkClick = new EventEmitter();
  handleLinkClick($event: MouseEvent) {
    $event.preventDefault();
    this.linkClick.emit();
  }
}
