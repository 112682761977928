import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dgx-ui-wl-footer-legal',
  templateUrl: './footer-legal.component.html',
  styleUrls: ['./footer-legal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLegalComponent {
  @Input() copyrightOwner?: string;
  currentYear = new Date().getFullYear();
}
