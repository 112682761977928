import { ApplianceFormField } from './appliance-form-cms.types';

export interface ApplianceFormValue {
  [ApplianceFormField.Appliance]: string;
  [ApplianceFormField.Brand]: string;
  [ApplianceFormField.GoodWorkingOrder]: string;
  [ApplianceFormField.GuaranteeDuration]?: number;
  [ApplianceFormField.PurchaseDate]: { month: number; year: number };
  [ApplianceFormField.PurchasePrice]: string;
  [ApplianceFormField.UnderGuarantee]: string;
}

export interface ApplianceFormValueWithItemId extends ApplianceFormValue {
  itemId: string;
}
export interface ApplianceFormSubmit extends ApplianceFormValue {
  applianceCode: string;
  brandCode: string;
  applianceCategory?: string;
}

export interface ApplianceDetailsQueryParamsForm {
  applianceCategory?: string;
  applianceCode: string;
  brandCode: string;
  goodWorkingOrder: string;
  month: string;
  purchasePrice: string;
  underGuarantee: string;
  year: string;
  showErrors?: string;
  guaranteeDuration?: string;
}
