import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as ReinstateActions from './reinstate.actions';
import { MonoTypeOperatorFunction, Observable, of, pipe } from 'rxjs';
import { Action } from '@ngrx/store';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mapTo,
  tap,
} from 'rxjs/operators';
import { ReinstateApiService } from '../services/reinstate-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorService, LoaderService } from '@common/util-foundation';

@Injectable()
export class ReinstateEffects {
  private known4xxErrorCodes = [400, 404];

  reinstateDirectDebit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ReinstateActions.reinstateDirectDebit),
      exhaustMap((action) => {
        return this.reinstateService
          .reinstateDirectDebit(action.reinstateRequest)
          .pipe(
            map(() => ReinstateActions.reinstateDirectDebitSuccess()),
            catchError((error: HttpErrorResponse) => {
              return of(
                ReinstateActions.reinstateDirectDebitFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );

  reinstateDirectDebitSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReinstateActions.reinstateDirectDebitSuccess),
        tap(() => {
          this.router.navigateByUrl('/confirmation');
        })
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReinstateActions.reinstateDirectDebit),
        tap(() => this.loaderService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoaderOnFailures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReinstateActions.reinstateDirectDebitFailure),
        tap(() => this.loaderService.hideLoader())
      ),
    { dispatch: false }
  );

  handleUnknownFailures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReinstateActions.reinstateDirectDebitFailure),
        this.handleUnknownFailures()
      ),
    { dispatch: false }
  );

  handleKnown4xxFailures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReinstateActions.reinstateDirectDebitFailure),
      filter(({ error }) => this.isOneOfKnownHttp4xxErrorCodes(error)),
      mapTo(ReinstateActions.reinstateValidationFailure())
    )
  );

  constructor(
    private actions$: Actions,
    private reinstateService: ReinstateApiService,
    private loaderService: LoaderService,
    private router: Router,
    private errorService: ErrorService
  ) {}

  private isOneOfKnownHttp4xxErrorCodes(error: Error) {
    return error instanceof HttpErrorResponse &&
      !!this.known4xxErrorCodes.includes(error.status)
      ? true
      : false;
  }

  private handleUnknownFailures(): MonoTypeOperatorFunction<
    Action & { error: Error }
  > {
    return pipe(
      filter(({ error }) => !this.isOneOfKnownHttp4xxErrorCodes(error)),
      tap(() => {
        this.errorService.handleError();
      })
    );
  }
}
