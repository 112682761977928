import { createSelector } from '@ngrx/store';
import {
  CorrespondenceAddress,
  DirectDebitDetails,
  PaymentDetails,
  Person,
  PersonalDetails,
  YourDetails,
} from '@common/util-models';
import {
  getDirectDebitDetails,
  getPersonalDetails,
} from './user-profile.selectors';

const empty = '';

const getName = createSelector(getPersonalDetails, (person?: Person) => {
  return format(
    `${format(person?.personalDetails?.title)} ${format(
      person?.personalDetails?.firstName
    )} ${format(person?.personalDetails?.surname)}`
  );
});

const getEmail = createSelector(getPersonalDetails, (person?: Person) => {
  return format(person?.contact?.email);
});

const getMobile = createSelector(getPersonalDetails, (person?: Person) => {
  return format(person?.contact?.mobilePhoneNumber);
});

const getLandline = createSelector(getPersonalDetails, (person?: Person) => {
  return format(person?.contact?.landlinePhoneNumber);
});

const getAddress = createSelector(getPersonalDetails, (person?: Person) => {
  return person?.correspondenceAddress || ({} as CorrespondenceAddress);
});

export const personalDetails = createSelector(
  getMobile,
  getEmail,
  getPersonalDetails,
  (mobile, email, person?: Person) => {
    return {
      title: person?.personalDetails.title,
      firstName: person?.personalDetails.firstName,
      lastName: person?.personalDetails.surname,
      email: email,
      mobileNumber: mobile,
    } as PersonalDetails;
  }
);

export const getYourDetails = createSelector(
  getName,
  getEmail,
  getMobile,
  getLandline,
  getAddress,
  (
    name: string,
    email: string,
    mobile: string,
    landline: string,
    address: CorrespondenceAddress
  ) => {
    return {
      name,
      email,
      mobile,
      landline,
      address: {
        addressLine1: format(address.addressLine1),
        addressLine2: format(address.addressLine2),
        addressLine3: format(address.addressLine3),
        addressLine4: format(address.addressLine4),
        countryCode: format(address.countryCode),
        postalCode: format(address.postalCode),
      } as CorrespondenceAddress,
    } as YourDetails;
  }
);

export const getPaymentDetails = createSelector(
  getDirectDebitDetails,
  (directDebitDetails: DirectDebitDetails | undefined) => {
    return {
      accountName: directDebitDetails?.bankDetails.accountName,
      accountNumber: directDebitDetails?.bankDetails.accountNo,
      sortCode: directDebitDetails?.bankDetails.sortCode,
      paymentDay: directDebitDetails?.bankDetails.nominatedPerDay,
      paymentToken: directDebitDetails?.paymentToken,
    } as PaymentDetails;
  }
);

function format(input?: string) {
  return (input ?? empty).trim();
}
