import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CmsFAQsResponse } from '@common/util-models';

@Component({
  selector: 'sales-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqsComponent {
  @Input() faqs!: CmsFAQsResponse | null;
}
