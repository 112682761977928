import { Directive } from '@angular/core';
import { ErrorPageGaTaggingService } from './error-page-ga-tagging.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[errorPageGaTagging]',
  providers: [ErrorPageGaTaggingService],
})
export class ErrorPageGaTaggingDirective {
  constructor(private _errorPageGaTagging: ErrorPageGaTaggingService) {}
}
