<div class="covers-exclusions">
  <ui-tabs
    (tabSelected)="handleTabSelection($event)"
    class="tab-view"
    *ngIf="displayAs === 'tabs'"
  >
    <ui-tab title="What's included">
      <ng-container *ngTemplateOutlet="whatIncluded" ;></ng-container>
    </ui-tab>
    <ui-tab title="What's not included" (click)="handleWhatsExcludedClick()">
      <ng-container *ngTemplateOutlet="whatExcluded" ;></ng-container>
    </ui-tab>
  </ui-tabs>
  <div *ngIf="displayAs === 'stacked'" class="stack-view">
    <h3>What's included</h3>
    <ng-container *ngTemplateOutlet="whatIncluded" ;></ng-container>
    <h3>What's not included</h3>
    <ng-container *ngTemplateOutlet="whatExcluded" ;></ng-container>
  </div>
  <div *ngIf="deviceExcluded" class="stack-view mt-2">
    <p class="text--center">{{ deviceExcluded }}</p>
  </div>
</div>

<ng-template #whatIncluded>
  <ul class="list" [ngClass]="{ 'mb-1 py-1': displayAs === 'stacked' }">
    <li
      class="list__item list__item"
      [ngClass]="customTickIcon ? '' : 'icon--md icon-large-tick'"
      *ngFor="let includedItem of included"
    >
      <div *ngIf="customTickIcon" class="icon--md icon-container mr-2">
        <img [src]="customTickIcon" />
      </div>
      {{ includedItem }}
    </li>
  </ul>
</ng-template>

<ng-template #whatExcluded>
  <ul class="list" [ngClass]="{ 'pt-1 whatExcluded': displayAs === 'stacked' }">
    <li
      class="list__item list__item"
      [ngClass]="customCrossIcon ? '' : 'icon-large-cross'"
      *ngFor="let excludedItem of excluded"
    >
      <div *ngIf="customCrossIcon" class="icon-container mr-2">
        <img [src]="customCrossIcon" />
      </div>
      {{ excludedItem }}
    </li>
  </ul>
</ng-template>
