export const toOrdinal = (number: number): string => {
  const ordinalNumbers = [
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
  ];

  return number > 0 && number < ordinalNumbers.length
    ? ordinalNumbers[number - 1]
    : number.toString();
};

export const toCardinal = (number: number): string => {
  const cardinalNumbers = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
  ];

  return number > 0 && number < cardinalNumbers.length
    ? cardinalNumbers[number - 1]
    : number.toString();
};
