import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

@NgModule({
  imports: [CommonModule],
})
export class SharedFeatureAuthModule {
  constructor(
    @Optional() @SkipSelf() sharedFeatureAuthModule: SharedFeatureAuthModule
  ) {
    if (sharedFeatureAuthModule) {
      throw new TypeError(`SharedFeatureAuthModule can be imported only once`);
    }
  }
}
