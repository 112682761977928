import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
@Component({
  selector: 'wl-order-confirmation-thankyou-text',
  templateUrl: './order-confirmation-thankyou-text.component.html',
  styleUrls: ['./order-confirmation-thankyou-text.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderConfirmationThankyouTextComponent {
  @Input() selectedCoverName: string | null = '';
  @Input() firstName: string | null = '';
  @Input() isInsurance: boolean | null = false;
}
