import { Inject, Injectable } from '@angular/core';
import { FormbuilderConfig } from '../interfaces/form-builder-config.interface';
import { formbuilderConfigToken } from '../tokens/formbuilder-config.token';

@Injectable()
export class FormbuilderConfigService {
  config!: FormbuilderConfig;
  constructor(
    @Inject(formbuilderConfigToken) formbuilderConfig: FormbuilderConfig
  ) {
    this.config = formbuilderConfig;
  }
}
