<div>
  <div class="need-to-know-container">
    <div class="title">Things you need to know</div>
    <div class="desc">
      <div [innerHTML]="content"></div>

      <div class="tc-contaier" *ngIf="displayTCLink">
        <br />
        <div *ngIf="isInsurance; else elseBlock">
          See the
          <span>
            <a
              class="link"
              target="_blank"
              [href]="productInfo?.link"
              title="Insurance Product Information Document"
              >Insurance Product Information Document</a
            ></span
          >, and
          <span>
            <a
              class="link"
              target="_blank"
              [href]="terms?.link"
              title="Terms & Conditions"
              >Terms & Conditions</a
            ></span
          >. Please check the policy carefully to make sure it meets your
          demands and needs.
        </div>
        <ng-template #elseBlock>
          See the
          <span>
            <a
              class="link"
              target="_blank"
              [href]="terms?.link"
              title="Terms & Conditions"
              >Terms & Conditions</a
            ></span
          >.
        </ng-template>
      </div>
    </div>
  </div>
</div>
