import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  setDescription(desc: string) {
    this.metaTagService.addTag({ name: 'description', content: desc });
  }

  setMetaTags(metaTags: MetaDefinition[]): void {
    this.metaTagService.addTags(metaTags);
  }
}
