import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { BuildConfig } from '@common/util-models';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'sales-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  config: BuildConfig = this.buildConfigService.config;
  protectLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'protect'
  );
  moreLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'more'
  );
  usefulLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'useful'
  );
  legalLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'legal'
  );
  socialLinks = [
    {
      href: 'https://www.facebook.com/DandGUK/',
      icon: 'facebook',
      label: "Domestic and General's Facebook profile (opens in new window)",
    },
    {
      href: 'https://twitter.com/DandG_UK',
      icon: 'twitter',
      label: "Domestic and General's Twitter profile (opens in new window)",
    },
    {
      href: 'https://www.instagram.com/domesticandgeneral/',
      icon: 'instagram',
      label: "Domestic and General's Instagram profile (opens in new window)",
    },
    {
      href: 'https://www.linkedin.com/company/domestic-&-general/mycompany/',
      icon: 'linkedin',
      label: "Domestic and General's LinkedIn profile (opens in new window)",
    },
  ];

  constructor(private buildConfigService: BuildConfigService) {}
}
