import { Injectable } from '@angular/core';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor(private router: Router) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  private loadingSub$ = new BehaviorSubject(false);
  loading$ = this.loadingSub$.asObservable();

  private updateLoader(value: boolean) {
    this.loadingSub$.next(value);
  }

  showLoader() {
    this.updateLoader(true);
  }

  hideLoader() {
    this.updateLoader(false);
  }

  // Show or hide a loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.updateLoader(true);
    }
    if (event instanceof NavigationEnd) {
      this.updateLoader(false);
    }

    // Handle request failures
    if (event instanceof NavigationCancel) {
      this.updateLoader(false);
    }
    if (event instanceof NavigationError) {
      this.updateLoader(false);
    }
  }
}
