import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
} from '@angular/animations';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'dgx-dfb-error',
  templateUrl: './validation-error-message.component.html',
  styleUrls: ['./validation-error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideUpDown', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([
          animate(200, style({ height: 0 })),
          animate('200ms ease-in-out', style({ opacity: '0' })),
        ]),
      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),
        group([
          animate(200, style({ height: '*' })),
          animate('200ms ease-in-out', style({ opacity: '1' })),
        ]),
      ]),
    ]),
  ],
})
export class ValidationErrorMessageComponent {
  @Input() errorMessage!: string;
}
