<ng-container *ngIf="vm$ | async as vm">
  <div [formGroup]="vm.addressForm">
    <div class="address-lookup">
      <div class="address-lookup__title-section">
        <div class="address-lookup__title">Your address</div>
        <div class="address-lookup__description">
          Please provide your home address before continuing to payment.
        </div>
      </div>

      <div class="address-lookup__tabs">
        <div class="address-lookup__mode-section">
          <button
            class="address-lookup__mode-button"
            [ngClass]="{
              'address-lookup__mode-button--active':
                vm.selectionMode === 'lookup'
            }"
            (click)="setSelectionMode($any('lookup'), $event)"
          >
            Find address
          </button>
          <button
            class="address-lookup__mode-button"
            [ngClass]="{
              'address-lookup__mode-button--active':
                vm.selectionMode === 'manual'
            }"
            (click)="setSelectionMode($any('manual'), $event)"
          >
            Manual address
          </button>
        </div>

        <div
          class="address-lookup__lookup-mode"
          *ngIf="vm.selectionMode === 'lookup'"
        >
          <dgx-dfb-type-ahead
            *ngIf="vm.typeAheadDef"
            [field]="vm.typeAheadDef"
            [validate]="vm.validate"
            [typeInput]="vm.typeAheadValueOverride"
            (value)="handleEnteredInput($event)"
            [formControl]="vm.typeAheadFormControl"
          >
          </dgx-dfb-type-ahead>
          <dgx-dfb-error
            *ngIf="vm.errorMessage"
            [errorMessage]="vm.errorMessage"
          >
          </dgx-dfb-error>
        </div>

        <div
          class="address-lookup__manual-mode"
          *ngIf="vm.selectionMode === 'manual'"
        >
          <dgx-dfb-manual-address-entry
            [fieldDef]="vm.fieldDef"
            [formControlName]="vm.addressFormControlName"
            [validateForm]="vm.validate"
          ></dgx-dfb-manual-address-entry>
        </div>
      </div>
    </div>

    <div
      *ngIf="vm.isCommercialAddressSelected && vm.selectionMode === 'lookup'"
      class="residential-address-override ng-star-inserted"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
        class="residential-address-override__icon"
      >
        <circle cx="30" cy="30" r="30" fill="currentColor"></circle>
        <path
          fill="#ffffff"
          d="M30.217 13.874L14.66 40.807a.2.2 0 00.171.3l30.66-.018a.2.2 0 00.179-.3l-15.024-26.9a.246.246 0 00-.429 0zM13.828 40.785l.635.367-.635-.367zm32.075 1.771H14.487a1.473 1.473 0 01-1.055-.4 1.423 1.423 0 01-.239-1.734l16-27.7a1.435 1.435 0 011.243-.715 1.433 1.433 0 011.253.736l15.439 27.642a1.451 1.451 0 01.161 1.1 1.433 1.433 0 01-1.389 1.061z"
        ></path>
        <path
          fill="#ffffff"
          d="M30.328 32.749a1.467 1.467 0 01-1.467-1.467v-8.09a1.466 1.466 0 112.933 0v8.09a1.466 1.466 0 01-1.466 1.467z"
        ></path>
        <path
          fill="#ffffff"
          d="M31.871 35.692a1.541 1.541 0 11-1.541-1.541 1.541 1.541 0 011.541 1.541z"
        ></path>
      </svg>
      <p class="residential-address-override__title">
        This property is listed as a commercial address
      </p>
      <p class="residential-address-override__text ng-star-inserted">
        We can only register appliances for domestic use
      </p>

      <dgx-dfb-checkbox
        [field]="vm.domesticUseOnlyDef"
        [formControlName]="vm.domesticUseOnlyDef.controlName"
        [validate]="vm.validate"
        [customValidity]="customValidity"
        (checked)="onDomesticUseOnlyChecked($event)"
      >
      </dgx-dfb-checkbox>
    </div>
  </div>
</ng-container>
