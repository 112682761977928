import { DOCUMENT } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';
import {
  FeatureConfigFacade,
  featureConfigInitializer,
} from '@common/data-access-feature-config';
import {
  AddCorrelationIdHeaderInterceptor,
  CMS_DATA_URL,
} from '@common/data-access-shared';
import {
  AWIN_TRACKING_TEST_MODE,
  buildConfigInitializer,
  BuildConfigService,
  CONFIG_ADAPTER,
  cookieProInitializer,
  ENVIRONMENT,
  GA_TAGS,
  getRequestSource,
  GOOGLE_TAG_GLOBAL_PROPERTIES,
  RequestInterceptorService,
  REQUEST_SOURCE,
  RUNTIME_CONFIG,
} from '@common/util-foundation';
import { Environment } from '@common/util-models';
import { AUTH_FORMS, AUTH_URLS_CONFIG_JSON } from '@domgen/dgx-fe-auth';
import { REFRESH_API_CONFIG } from '@shared/feature-auth';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { CmsConfigAdapter } from '../config-adapters/cms/cms.config-adapter';
import { RequestSourceConfigAdapter } from '../config-adapters/request-source/request-source.config-adapter';
import { WorldpayConfigAdapter } from '../config-adapters/world-pay/world-pay.config-adapter';
import { AppProvidersConfig } from '../models/app-providers-config.interface';
import { AppProvidersConfigToken } from '../tokens/app-providers-config.token';
import { CMS_CONFIG } from '../tokens/cms-config.token';
import { authenticationModuleFormsConfig } from './authentication-module-forms-config.constant';

export const DefaultSalesProviders: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: buildConfigInitializer,
    multi: true,
    deps: [BuildConfigService],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: cookieProInitializer,
    multi: true,
    deps: [DOCUMENT, BuildConfigService],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: featureConfigInitializer,
    multi: true,
    deps: [FeatureConfigFacade],
  },
  {
    provide: AWIN_TRACKING_TEST_MODE,
    useFactory: (buildConfigService: BuildConfigService) => {
      return buildConfigService.config.awinTrackingTestMode;
    },
    deps: [BuildConfigService],
  },
  {
    provide: CMS_CONFIG,
    useFactory: (
      appProvidersConfig: AppProvidersConfig,
      buildConfigService: BuildConfigService
    ) => {
      return buildConfigService.getCmsConfig(
        appProvidersConfig.componentMapping
      );
    },
    deps: [AppProvidersConfigToken, BuildConfigService],
  },
  {
    provide: CMS_DATA_URL,
    useFactory: (appProvidersConfig: AppProvidersConfig) => {
      return appProvidersConfig.cmsDataUrl;
    },
    deps: [AppProvidersConfigToken],
  },
  {
    provide: CONFIG_ADAPTER,
    useClass: WorldpayConfigAdapter,
    deps: [ENVIRONMENT, RUNTIME_CONFIG],
    multi: true,
  },
  {
    provide: CONFIG_ADAPTER,
    useClass: RequestSourceConfigAdapter,
    deps: [REQUEST_SOURCE],
    multi: true,
  },
  {
    provide: CONFIG_ADAPTER,
    useClass: CmsConfigAdapter,
    deps: [ENVIRONMENT, RUNTIME_CONFIG, FeatureConfigFacade],
    multi: true,
  },
  {
    provide: GA_TAGS,
    useFactory: (appProvidersConfig: AppProvidersConfig) => {
      return appProvidersConfig.gaTagsConfig;
    },
    deps: [AppProvidersConfigToken],
  },
  {
    provide: GOOGLE_TAG_GLOBAL_PROPERTIES,
    useFactory: (appProvidersConfig: AppProvidersConfig) => {
      return appProvidersConfig.googleTagGlobalProps;
    },
    deps: [AppProvidersConfigToken],
  },

  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AddCorrelationIdHeaderInterceptor,
    multi: true,
  },
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useFactory: (buildConfigService: BuildConfigService) => {
      return buildConfigService.config.recaptchaSiteKey;
    },
    deps: [BuildConfigService],
  },
  {
    provide: REQUEST_SOURCE,
    useFactory: (environment: Environment) => {
      return getRequestSource({
        catalogueStore: environment.catalogueStore,
        domainBasedRequestSources: environment.domainBasedRequestSources,
      });
    },
    deps: [ENVIRONMENT],
  },

  {
    provide: AUTH_FORMS,
    useValue: authenticationModuleFormsConfig,
  },
  {
    provide: AUTH_URLS_CONFIG_JSON,
    useFactory: (environment: Environment) => {
      return environment.configPath;
    },
    deps: [ENVIRONMENT],
  },
  {
    provide: REFRESH_API_CONFIG,
    useFactory: (buildConfigService: BuildConfigService) => {
      return buildConfigService.config.refreshAPI;
    },
    deps: [BuildConfigService],
  },
];
