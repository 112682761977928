import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCheckout from './+state/checkout.reducer';
import { CheckoutEffects } from './+state/checkout.effects';
import { CheckoutFacade } from './+state/checkout.facade';
import { CheckoutApiService } from './services/checkout-api.service';
import { CheckoutCommonSelectorsFacade } from './+state/checkout-common-selectors.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCheckout.CHECKOUT_FEATURE_KEY,
      fromCheckout.reducer
    ),
    EffectsModule.forFeature([CheckoutEffects]),
  ],
  providers: [
    CheckoutFacade,
    CheckoutApiService,
    CheckoutCommonSelectorsFacade,
  ],
})
export class CommonDataAccessCheckoutModule {}
