import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { ReinstateDirectDebitRequest } from '@common/util-models';
import { Observable } from 'rxjs';

@Injectable()
export class ReinstateApiService {
  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService
  ) {}

  reinstateDirectDebit(
    requestData: ReinstateDirectDebitRequest
  ): Observable<void> {
    const config = this.buildConfigService.config;
    const reinstateDirectDebitUrl = `${config.common}/directdebit/reinstate`;
    return this.httpClient.post<void>(reinstateDirectDebitUrl, requestData, {
      headers: {
        ...this.getHeaders(),
      },
    });
  }

  private getHeaders() {
    return {
      'wl-client': this.buildConfigService.config.wlClient,
      'request-source': this.buildConfigService.config.catalogueStore,
      'request-action': this.buildConfigService.config.action,
    };
  }
}
