import { FEATURE_CONFIG_FEATURE_KEY } from '@common/data-access-feature-config';
import { Action, ActionReducer } from '@ngrx/store';
import { CORRELATION_ID_KEY } from './correlation-id.reducer';
import * as ResetStateActions from './reset-state.actions';

export function resetStateMetaReducer(
  reducer: ActionReducer<Record<string, unknown>>
) {
  const persistedStateKeys: string[] = [
    FEATURE_CONFIG_FEATURE_KEY,
    CORRELATION_ID_KEY,
  ];

  return function (state: Record<string, unknown> | undefined, action: Action) {
    if (shouldReset(action)) {
      const resetState = Object.entries(state ?? {}).reduce(
        (
          filteredState: Record<string, unknown>,
          [stateKey, stateSlice]: [string, unknown]
        ) => {
          if (persistedStateKeys.includes(stateKey)) {
            filteredState[stateKey] = stateSlice;
          }
          return filteredState;
        },
        {}
      );
      return reducer(resetState, action);
    }

    return reducer(state, action);
  };
}

const shouldReset = (action: Action) => {
  const resetActionTypes = [
    ResetStateActions.resetState,
    ResetStateActions.restartCheckoutBackButton,
    ResetStateActions.restartCheckoutGuest,
    ResetStateActions.restartCheckoutLoggedInUser,
    ResetStateActions.sessionInactivityTimeout,
  ];
  return (
    resetActionTypes.find((resetAction) => resetAction.type === action.type) !==
    undefined
  );
};
