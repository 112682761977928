import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class CookieManagerService {
  private readonly AUTH_KEY = 'AWS_IDT';

  getAuthCookie(): string {
    return this.getCookieByName(this.AUTH_KEY);
  }

  getCookieByName(cookieName: string): string {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].replace(' ', '');
      const index = cookie.indexOf('=');
      const name = index > -1 ? cookie.substr(0, index) : cookie;
      if (name === cookieName) {
        return cookie.substr(index + 1);
      }
    }
    return '';
  }
}
