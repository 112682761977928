export const ORDER_CONFIRMATION_DETAIL = {
  results: [
    {
      '@name': 'order-confirmation-detail',
      '@path': '/order-confirmation-detail',
      '@id': 'fa7f97bc-7b89-45d0-82d0-acc67df356e0',
      '@nodeType': 'detail',
      jcrName: 'order-confirmation-detail',
      formName: 'orderConfirmationDetail',
      banner: 'Good news! We can protect your appliance',
      goToMyAccount: {
        title: 'Discover the benefits of My Account',
        summary:
          'Manage your plans, book repairs, and access help and advice all in the one place.',
      },
      '@nodes': [],
    },
  ],
};
