import { Inject, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonDataAccessApplianceDetailsModule } from '@common/data-access-appliance-details';
import { CommonDataAccessQuotesModule } from '@common/data-access-quotes';
import { DefaultSalesDependencies } from './constants/default-sales-dependencies.constant';
import { DefaultSalesProviders } from './constants/default-sales-providers.constant';
import { AppProvidersConfig } from './models/app-providers-config.interface';
import { AppProvidersConfigToken } from './tokens/app-providers-config.token';

@NgModule({
  imports: [
    ...DefaultSalesDependencies,
    CommonDataAccessQuotesModule,
    CommonDataAccessApplianceDetailsModule,
  ],
  providers: [...DefaultSalesProviders],
})
export class CommonFeatureCoreModule {
  constructor(
    @Optional() @SkipSelf() commonFeatureCoreModule: CommonFeatureCoreModule,
    @Optional()
    @Inject(AppProvidersConfigToken)
    appProvidersConfig: AppProvidersConfig
  ) {
    if (commonFeatureCoreModule) {
      throw new TypeError(`CommonFeatureCoreModule can be imported only once`);
    }
    if (!appProvidersConfig) {
      throw new TypeError(
        'CommonFeatureCoreModule requires AppProvidersConfig'
      );
    }
  }
}
