import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { applianceIsWhiteGoods } from '@common/util-foundation';
import {
  BasketItem,
  BasketItemApplianceDetails,
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  PaymentType,
} from '@common/util-models';

@Component({
  selector: 'sales-coverage-banner',
  templateUrl: './coverage-banner.component.html',
  styleUrls: ['./coverage-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageBannerComponent implements OnChanges {
  @Input() data: BasketItem | undefined = undefined;
  @Input() loading = false;
  @Input() discountPercentage: number | undefined = undefined;
  @Output() analytics = new EventEmitter<BasketItem>();

  applianceBenefitsList: string[] = [];
  applianceIsWhiteGoods = false;
  applianceName = '';
  brandName = '';
  monthlyPrice: number | undefined;
  price: number | undefined;

  private readonly whiteGoodsApplianceBenefitsList: string[] = [
    'Accidental damage cover',
    'Unlimited repairs',
    'If we can’t fix it, we will replace it',
  ];
  private readonly heatingApplianceBenefitsList: string[] = [
    'Exclusive access to our Boiler Care portal',
    'An annual service on your boiler',
    'Repairs and annual service by Gas Safe engineers',
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.data || !this.data?.data || this.loading) {
      return;
    }

    const applianceDetails: BasketItemApplianceDetails = this.data.data
      .applianceDetails;
    this.brandName = applianceDetails?.brandName;
    this.applianceName = applianceDetails?.applianceName;
    this.applianceIsWhiteGoods = applianceIsWhiteGoods(applianceDetails);

    this.setPrice();
    this.setMonthlyPrice();
    this.setApplianceBenefits();
    this.analytics.emit(this.data);
  }

  private setPrice(): void {
    const cardPaymentOptions = this.paymentOptions
      .filter(
        (paymentOption: BasketItemPaymentOption) =>
          paymentOption.paymentType === PaymentType.Card
      )
      .sort(
        (optionA: BasketItemPaymentOption, optionB: BasketItemPaymentOption) =>
          optionA.fee - optionB.fee
      );

    this.price = cardPaymentOptions.length
      ? cardPaymentOptions[0].fee
      : undefined;
  }

  private setMonthlyPrice(): void {
    const directDebitPaymentOptions = this.paymentOptions
      .filter(
        (paymentOption: BasketItemPaymentOption) =>
          paymentOption.paymentType === PaymentType.DirectDebit
      )
      .sort(
        (optionA: BasketItemPaymentOption, optionB: BasketItemPaymentOption) =>
          optionA.subsequentPayment - optionB.subsequentPayment
      );
    this.monthlyPrice = directDebitPaymentOptions.length
      ? directDebitPaymentOptions[0].subsequentPayment
      : undefined;
  }

  private setApplianceBenefits(): void {
    this.applianceBenefitsList = this.applianceIsWhiteGoods
      ? this.whiteGoodsApplianceBenefitsList
      : this.heatingApplianceBenefitsList;
  }

  private get paymentOptions(): BasketItemPaymentOption[] {
    return (
      this.data?.data.quotes.reduce(
        (result: BasketItemPaymentOption[], quote: BasketItemQuoteDetails) => [
          ...result,
          ...Object.values(quote.paymentOptions),
        ],
        []
      ) ?? []
    );
  }
}
