import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { OptionItem } from '@domgen/dgx-fe-dynamic-form-builder';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

export const validTypeAheadSelection = (
  optionsStream$: Observable<string[] | OptionItem[]>
): AsyncValidatorFn => {
  return (control: AbstractControl) => {
    return optionsStream$.pipe(
      map((options: (string | OptionItem)[]) => {
        return options?.length ? validateInput(control.value, options) : null;
      }),
      first()
    );
  };
};

function validateInput(
  input: string,
  options: (string | OptionItem)[]
): ValidationErrors | null {
  const optionValues: string[] = options.map((option: string | OptionItem) =>
    typeof option === 'string' ? option : option.value
  );
  const match = optionValues.find(
    (option: string) => option.toLowerCase() === input?.toLowerCase()
  );
  return match ? null : { invalid: true };
}
