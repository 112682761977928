import { createSelector } from '@ngrx/store';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';
import { PaymentType } from '@common/util-models';
import { CheckoutPartialState } from './checkout.reducer';
import {
  getCheckoutError,
  getValidateDirectDebitDetailsError,
} from './checkout.selectors';

export const showPopupToRestartCheckoutJourney = createSelector<
  SelectPaymentPartialState & CheckoutPartialState,
  Error | undefined,
  Error | undefined,
  PaymentType | undefined,
  boolean
>(
  getCheckoutError,
  getValidateDirectDebitDetailsError,
  SelectPaymentSelectors.getSelectedPaymentType,
  (
    checkoutError: Error | undefined,
    validateDirectDebitError: Error | undefined,
    paymentType: PaymentType | undefined
  ) => {
    if (
      (checkoutError && !(paymentType === PaymentType.Card)) ||
      validateDirectDebitError
    ) {
      return true;
    }

    return false;
  }
);
