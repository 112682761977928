<a
  *ngIf="data?.routerPath; else externalNavigationLink"
  (click)="onClick($event, true)"
  [routerLink]="[data?.routerPath]"
  [ngClass]="{
    'with-icon': data?.icon,
    'navigation-dropdown': data?.additionalNavigationLinks?.length
  }"
  [attr.rel]="data?.newWindow ? 'noopener noreferrer' : undefined"
  [attr.target]="data?.newWindow ? '_blank' : undefined"
  data-automation="navigationLink"
>
  <span
    *ngIf="data?.icon"
    [ngClass]="data?.icon ? 'icon-' + data?.icon : ''"
    class="icon icon--xl"
  ></span>
  <span [ngClass]="{ 'icon-label': data?.icon }">{{ data?.label }}</span>
  <ng-container *ngTemplateOutlet="subMenuNavigationLink"></ng-container>
</a>

<ng-template #externalNavigationLink>
  <a
    (click)="onClick($event, true)"
    [attr.rel]="data?.newWindow ? 'noopener noreferrer' : undefined"
    [attr.target]="data?.newWindow ? '_blank' : undefined"
    [attr.href]="data?.href"
    [ngClass]="{
      'with-icon': data?.icon,
      'navigation-dropdown': data?.additionalNavigationLinks?.length
    }"
    data-automation="navigationLink"
  >
    <span
      *ngIf="data?.icon"
      [ngClass]="data?.icon ? 'icon-' + data?.icon : ''"
      class="icon icon--xl"
    ></span>
    <span [ngClass]="{ 'icon-label': data?.icon }">{{ data?.label }}</span>
    <ng-container *ngTemplateOutlet="subMenuNavigationLink"></ng-container>
  </a>
</ng-template>

<ng-template #subMenuNavigationLink>
  <div
    *ngIf="data?.additionalNavigationLinks?.length"
    class="additional-navigation-links"
  >
    <ng-container *ngFor="let link of data?.additionalNavigationLinks">
      <a
        class="navigation-link"
        *ngIf="link?.routerPath; else subMenuExternalNavigationLink"
        (click)="onClick($event)"
        [routerLink]="[link?.routerPath]"
        [ngClass]="{ 'with-icon': link?.icon, heading: link?.isHeading }"
        [attr.rel]="link?.newWindow ? 'noopener noreferrer' : undefined"
        [attr.target]="link?.newWindow ? '_blank' : undefined"
        data-automation="navigationLink"
      >
        <span
          *ngIf="link?.icon"
          [ngClass]="link?.icon ? 'icon-' + link?.icon : ''"
          class="icon icon--xl"
        ></span>
        <span [ngClass]="{ 'icon-label': link?.icon }">{{ link?.label }}</span>
      </a>
      <ng-template #subMenuExternalNavigationLink>
        <a
          class="navigation-link"
          (click)="onClick($event)"
          [attr.rel]="link?.newWindow ? 'noopener noreferrer' : undefined"
          [attr.target]="link?.newWindow ? '_blank' : undefined"
          [attr.href]="link?.href"
          [ngClass]="{ 'with-icon': link?.icon, heading: link?.isHeading }"
          data-automation="navigationLink"
        >
          <span
            *ngIf="link?.icon"
            [ngClass]="link?.icon ? 'icon-' + link?.icon : ''"
            class="icon icon--xl"
          ></span>
          <span [ngClass]="{ 'icon-label': link?.icon }">{{
            link?.label
          }}</span>
        </a>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-container *ngTemplateOutlet="mobileSubMenuNavigationLink"></ng-container>

<ng-template #mobileSubMenuNavigationLink>
  <div
    *ngIf="data?.additionalNavigationLinks?.length"
    class="mobile-sub-navigation-links"
    [ngClass]="{ 'show-submenu': !!toggleMobileMenu }"
  >
    <ng-container *ngFor="let link of data?.additionalNavigationLinks">
      <a
        class="navigation-link"
        *ngIf="link?.routerPath; else subMenuExternalNavigationLink"
        (click)="onClick($event)"
        [routerLink]="[link?.routerPath]"
        [ngClass]="{ 'with-icon': link?.icon, heading: link?.isHeading }"
        [attr.rel]="link?.newWindow ? 'noopener noreferrer' : undefined"
        [attr.target]="link?.newWindow ? '_blank' : undefined"
        data-automation="navigationLink"
      >
        <span
          *ngIf="link?.icon"
          [ngClass]="link?.icon ? 'icon-' + link?.icon : ''"
          class="icon icon--xl"
        ></span>
        <span [ngClass]="{ 'icon-label': link?.icon }">{{ link?.label }}</span>
      </a>
      <ng-template #subMenuExternalNavigationLink>
        <a
          class="navigation-link"
          (click)="onClick($event)"
          [attr.rel]="link?.newWindow ? 'noopener noreferrer' : undefined"
          [attr.target]="link?.newWindow ? '_blank' : undefined"
          [attr.href]="link?.href"
          [ngClass]="{ 'with-icon': link?.icon, heading: link?.isHeading }"
          data-automation="navigationLink"
        >
          <span
            *ngIf="link?.icon"
            [ngClass]="link?.icon ? 'icon-' + link?.icon : ''"
            class="icon icon--xl"
          ></span>
          <span [ngClass]="{ 'icon-label': link?.icon }">{{
            link?.label
          }}</span>
        </a>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
