import { createReducer, on, Action } from '@ngrx/store';
import * as NavigationActions from './navigation.actions';

export const NAVIGATION_FEATURE_KEY = 'navigation';

export interface NavigationState {
  completedSteps: string[];
}

export interface NavigationPartialState {
  readonly [NAVIGATION_FEATURE_KEY]: NavigationState;
}

export const initialState: NavigationState = {
  completedSteps: [],
};

const navigationReducer = createReducer(
  initialState,
  on(NavigationActions.stepCompleted, (state, { step }) => ({
    ...state,
    completedSteps: [...state.completedSteps, step],
  }))
);

export function reducer(state: NavigationState | undefined, action: Action) {
  return navigationReducer(state, action);
}
