import { CmsFormField } from '@domgen/dgx-fe-business-models';
import {
  CheckoutPageCmsData,
  CheckoutCmsData,
  CheckoutCmsResult,
} from '@common/util-models';

export const mapCmsFormData = (cmsData: {
  results: CmsFormField[];
}): CmsFormField[] => {
  return cmsData.results;
};

export const mapCheckoutPageCmsData = (cmsData: CheckoutPageCmsData) => {
  const guestData = cmsData.results.filter((result: CheckoutCmsResult) => {
    return result['@name'] === 'new-user';
  })[0];

  const registeredData = cmsData.results.filter((result: CheckoutCmsResult) => {
    return result['@name'] === 'already-registered';
  })[0];

  return {
    guestCheckout: {
      bannerTitle: guestData.bannerTitle,
      bannerText: guestData.bannerText,
      description: guestData.description,
      hint: guestData.hint,
      title: guestData.title,
      subtitle: guestData.subtitle,
      buttonText: guestData.buttonText,
    },
    registeredCheckout: {
      bannerText: registeredData.bannerText,
      description: registeredData.description,
      // no hint
      title: registeredData.title,
      subtitle: registeredData.subtitle,
      // no button
    },
  } as CheckoutCmsData;
};
