import { InjectionToken } from '@angular/core';
import { FormbuilderConfig } from '../interfaces/form-builder-config.interface';

export const formbuilderConfigToken = new InjectionToken<FormbuilderConfig>(
  'Form Builder Config'
);

export const providedFormbuilderConfigToken = new InjectionToken(
  'Provided Form Builder Config'
);
