import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserProfilePartialState } from './user-profile.reducer';
import { isPaperlessPlanDocumentPreferenceSet } from './user-profile.selectors';

/**
 * This will only contain selectors that we need to reuse across pages. This will ensure that we do not duplicate selectors across
 * pages going forward in each and every facade. There will never be methods here that dispatch actions to the store. These facades
 * will be injected across various feature pages/ component services/ state services. So having methods here that dispatch actions
 * will lead to action reuse and use of actions as commands. We want to avoid actions reuse and have unique actions for every specific
 * event that happens across pages.
 * @export
 * @class UserProfileCommonSelectorsFacade
 */
@Injectable()
export class UserProfileCommonSelectorsFacade {
  isPaperlessPlanDocumentPreferenceSet$ = this.store.pipe(
    select(isPaperlessPlanDocumentPreferenceSet)
  );

  constructor(private store: Store<UserProfilePartialState>) {}
}
