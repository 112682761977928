import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { TooltipService } from './tooltip.service';

@Directive({
  selector: '[dgxDfbTooltipTarget]',
})
export class TooltipTargetDirective implements OnInit {
  @Input() tooltipName!: string;

  constructor(
    private tooltipService: TooltipService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.tooltipService.registerTarget(this.tooltipName, this.viewContainerRef);
  }
}
