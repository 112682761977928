import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import {
  CardPaymentRedirectRequest,
  CardPaymentRedirectRequestWithoutConfigParameters,
  CardPaymentRedirectResponse,
} from '@common/util-models';
import { Observable } from 'rxjs';

@Injectable()
export class CardPaymentApiService {
  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService
  ) {}

  setupCardPayment(
    orderInformation: CardPaymentRedirectRequestWithoutConfigParameters
  ): Observable<CardPaymentRedirectResponse> {
    const config = this.buildConfigService.config;
    const cardPaymentSetupUrl = `${config.common}/card/payment/redirect`;
    return this.httpClient.post<CardPaymentRedirectResponse>(
      cardPaymentSetupUrl,
      {
        description: config.cardPayment.description,
        request_source: config.catalogueStore,
        transaction_source: config.cardPayment.transactionSource,
        transaction_type: config.cardPayment.transactionType,
        ...orderInformation,
      } as CardPaymentRedirectRequest
    );
  }
}
