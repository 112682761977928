<ng-container [formGroup]="manualAddressEntryForm">
  <dgx-dfb-input
    [formControlName]="$any(addressLine1FieldDef).controlName"
    [field]="$any(addressLine1FieldDef)"
    [validate]="validateForm"
  ></dgx-dfb-input>

  <dgx-dfb-input
    [formControlName]="$any(addressLine2FieldDef).controlName"
    [field]="$any(addressLine2FieldDef)"
    [validate]="validateForm"
  ></dgx-dfb-input>

  <dgx-dfb-input
    [formControlName]="$any(cityFieldDef).controlName"
    [field]="$any(cityFieldDef)"
    [validate]="validateForm"
  ></dgx-dfb-input>

  <dgx-dfb-input
    [formControlName]="$any(countyFieldDef).controlName"
    [field]="$any(countyFieldDef)"
    [validate]="validateForm"
  ></dgx-dfb-input>

  <dgx-dfb-input
    [formControlName]="$any(postcodeFieldDef).controlName"
    [field]="$any(postcodeFieldDef)"
    [validate]="validateForm"
  ></dgx-dfb-input>

  <dgx-dfb-checkbox
    [formControlName]="$any(checkboxDomesticUseFieldDef).controlName"
    [field]="$any(checkboxDomesticUseFieldDef)"
    [validate]="validateForm"
  ></dgx-dfb-checkbox>
</ng-container>
