import { Component, Input } from '@angular/core';

//todo: this needs reconsidering, the styles used are generic, and it just adds a title
//see CoverStartingDateComponent in sales
//suggest we add a variant to ui-panel and use that instead
@Component({
  selector: 'dgx-uib-cover-starting-date-info-dynamic',
  templateUrl: './cover-starting-date-info-dynamic.component.html',
  styleUrls: ['./cover-starting-date-info-dynamic.component.scss'],
})
export class CoverStartingDateInfoDynamicComponent {
  @Input() title = 'Plan start date';
}
