import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as NavigationActions from './navigation.actions';
import * as NavigationSelectors from './navigation.selectors';
import { map } from 'rxjs/operators';
import { NavigationPartialState } from './navigation.reducer';

@Injectable()
export class NavigationFacade {
  completedSteps$ = this.store.pipe(
    select(NavigationSelectors.getCompletedSteps)
  );

  constructor(private store: Store<NavigationPartialState>) {}

  markStepCompleted(step: string) {
    this.store.dispatch(NavigationActions.stepCompleted({ step }));
  }

  areStepsCompleted(...stepsToCheck: string[]) {
    return this.completedSteps$.pipe(
      map((steps) => {
        return stepsToCheck.reduce(
          (completed: boolean, stepToCheck: string) =>
            steps.indexOf(stepToCheck) > -1 && completed ? true : false,
          true
        );
      })
    );
  }
}
