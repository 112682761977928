import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';

export const DIGITAL_ID_CLAIM = 'custom:digital_id';

export enum CUSTOMER {
  LOGGED = 'L',
  GUEST = 'G',
}

export const getDigitalIdFromUser = (user: User | undefined): string => {
  return user?.[DIGITAL_ID_CLAIM] ? user[DIGITAL_ID_CLAIM] : '';
};
