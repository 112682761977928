import {
  CoverType,
  CreateQuoteResponse,
  QuotesConfig,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
  remoteDataUpdating,
  SelectedBasketItem,
} from '@common/util-models';
import { Action, createReducer, on } from '@ngrx/store';
import * as QuotesActions from './quotes.actions';
import { generateCurrentItemIdFromSelectedItems } from './quotes.utils';
import {
  firstQuoteInCurrentItem,
  generateSelectedFirstItemQuote,
  generateSelectedFirstItemQuoteByCoverType,
  generateSelectedItemQuoteBasedOnExcess,
} from './single-item.utils';

export const QUOTES_FEATURE_KEY = 'quotes';

export interface QuotesState {
  config: QuotesConfig;
  currentItemId?: string;
  enableAutoSelectCover?: boolean;
  initialCoverType: CoverType;
  mostRecentActionMessage: string | undefined;
  quoteApiResult?: CreateQuoteResponse;
  remoteState: RemoteData<null>;
  routeParamsAutoSelect?: { coverType: CoverType; excess: number };
  selectedItems: SelectedBasketItem[];
}

export interface QuotesPartialState {
  readonly [QUOTES_FEATURE_KEY]: QuotesState;
}

export const initialState: QuotesState = {
  config: {
    showAllCoverTypes: true,
    coverTypeUpgrades: {},
    coverTypesWhitelist: false,
  },
  currentItemId: undefined,
  enableAutoSelectCover: false,
  initialCoverType: CoverType.Standard,
  mostRecentActionMessage: undefined,
  quoteApiResult: undefined,
  remoteState: remoteDataNotFetched(),
  routeParamsAutoSelect: undefined,
  selectedItems: [],
};

const quotesReducer = createReducer(
  initialState,

  on(QuotesActions.init, (state) => ({
    ...state,
    remoteState: remoteDataOK(null),
  })),

  on(QuotesActions.createQuote, (state) => ({
    ...state,
    remoteState: remoteDataLoading(),
    enableAutoSelectCover: true,
  })),

  on(QuotesActions.createQuoteSuccess, (state, { quoteApiResult }) => ({
    ...state,
    quoteApiResult,
    remoteState: remoteDataOK(null),
    currentItemId: quoteApiResult.requestedItemId,
  })),

  on(QuotesActions.createQuoteFailure, (state, { error }) => ({
    ...state,
    remoteState: remoteDataError(error),
  })),

  // multi item basket reducer
  on(QuotesActions.removeCurrentItem, (state) => ({
    ...state,
    currentItemId: generateCurrentItemIdFromSelectedItems(state.selectedItems),
  })),

  // multi item basket reducer
  on(QuotesActions.addToSelectedItems, (state, { selectedItem }) => ({
    ...state,
    selectedItems: [...state.selectedItems, selectedItem],
  })),
  // multi item basket reducer
  on(
    QuotesActions.replaceSelectedItemsWithSingleItem,
    (state, { selectedItem }) => ({
      ...state,
      selectedItems: [selectedItem],
      currentItemId: selectedItem.itemId,
    })
  ),

  // multi item basket reducer
  on(QuotesActions.removeSelectedItem, (state, { selectedItem }) => {
    const selectedItems = state.selectedItems.filter(
      (item) =>
        item.itemId !== selectedItem.itemId &&
        item.quoteId !== selectedItem.quoteId
    );

    return {
      ...state,
      selectedItems,
      currentItemId:
        state.currentItemId === selectedItem.itemId
          ? generateCurrentItemIdFromSelectedItems(selectedItems)
          : state.currentItemId,
    };
  }),

  // multi item basket reducer
  on(QuotesActions.removeUnusedItems, (state) => ({
    ...state,
    remoteState: remoteDataUpdating(),
  })),

  // multi item basket reducer
  on(QuotesActions.removeUnusedItemsSuccess, (state, { basket }) => ({
    ...state,
    quoteApiResult: {
      ...(state.quoteApiResult as CreateQuoteResponse),
      basket,
    },
    remoteState: remoteDataOK(null),
  })),

  // multi item basket reducer
  on(QuotesActions.removeUnusedItemsFailure, (state, { error }) => ({
    ...state,
    remoteState: remoteDataError(error),
  })),

  // Single item basket reducer
  on(QuotesActions.selectCoverType, (state, { coverType }) => ({
    ...state,
    selectedItems: [
      generateSelectedFirstItemQuoteByCoverType(state, coverType),
    ],
  })),

  // Single item basket reducer
  on(
    QuotesActions.autoSelectCoverType,
    QuotesActions.setInitialSelectCoverType,
    (state, { coverType }) => ({
      ...state,
      initialCoverType: coverType,
      enableAutoSelectCover: false,
      selectedItems: [
        generateSelectedFirstItemQuoteByCoverType(state, coverType),
      ],
    })
  ),

  // Single item basket reducer
  on(QuotesActions.selectFirstCoverType, (state) => ({
    ...state,
    initialCoverType: firstQuoteInCurrentItem(state)?.coverType as CoverType,
    enableAutoSelectCover: false,
    selectedItems: [generateSelectedFirstItemQuote(state)],
  })),

  // Single item basket reducer
  on(QuotesActions.selectExcessValue, (state, { excessValue }) => ({
    ...state,
    selectedItems: [generateSelectedItemQuoteBasedOnExcess(state, excessValue)],
  })),

  on(
    QuotesActions.autoSelectFromRouteParams,
    (state, { coverType, excess }) => ({
      ...state,
      routeParamsAutoSelect: { coverType, excess },
    })
  ),

  on(QuotesActions.setCurrentItemIdToFirstSelectedItem, (state) => ({
    ...state,
    currentItemId: generateCurrentItemIdFromSelectedItems(state.selectedItems),
  })),

  on(
    QuotesActions.setMostRecentActionMessage,
    (state, { mostRecentActionMessage }) => ({
      ...state,
      mostRecentActionMessage,
    })
  ),

  on(QuotesActions.clearMostRecentActionMessage, (state) => ({
    ...state,
    mostRecentActionMessage: undefined,
  }))
);

export function reducer(state: QuotesState | undefined, action: Action) {
  return quotesReducer(state, action);
}
