import { AwinTrackedSale } from '@common/util-models';

declare global {
  interface Window {
    AWIN: {
      Tracking: {
        Sale: AwinTrackedSale;
        run: () => void;
      };
    };
  }
}

export function trackAwinSale(...sales: AwinTrackedSale[]): void {
  sales.forEach((sale: AwinTrackedSale) => {
    window.AWIN.Tracking.Sale = sale;
    window.AWIN.Tracking.run();
    console.log('awin::', sale);
  });
}
