import { addMonths, isWithinInterval, set, startOfToday } from 'date-fns';

export const isWithinMonthsFromCurrentYearMonth = (
  year: number,
  month: number,
  withinMonths: number
): boolean => {
  const today = startOfToday();

  const startDate = set(today, {
    year,
    month,
    date: 1,
  });

  const endDate = addMonths(startDate, withinMonths);

  return isWithinInterval(today, {
    start: startDate,
    end: endDate,
  });
};
