<ui-panel *ngIf="panel" variant="highlight-info">
  <ui-panel-header>
    <ui-panel-title>
      {{ isHeating ? 'Plan' : 'Cover' }} start date
    </ui-panel-title>
  </ui-panel-header>
  <ui-panel-body>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ui-panel-body>
</ui-panel>
<ng-container *ngIf="!panel">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<ng-template #content>
  <p *ngIf="isHeating">
    If your boiler is in guarantee, the plan will start at the end of the
    guarantee. If your boiler is out of guarantee, the plan will start once we
    have processed your application. If your chosen payment date is within the
    next 14 days, your first and second payments will be taken at the same time
    next month.
  </p>
  <p *ngIf="!isHeating">
    If your appliance is in guarantee, cover will start once we have processed
    your application. If your appliance is out of guarantee, cover will start
    after a 30 day wait. <br/><br/>
    The policies provide continuous cover, so payments will be taken monthly for
    as long as you like. If your chosen payment date is within the next 14 days,
    your first and second payments will be taken at the same time next month.
  </p>
</ng-template>
