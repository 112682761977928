import { createAction, props } from '@ngrx/store';

export const saveQueryParamsAWC = createAction(
  '[Common] save awc from query params',
  props<{
    awc: string;
  }>()
);

export const saveQueryParamsStore = createAction(
  '[Common] save store parameter from query params',
  props<{
    store: string;
  }>()
);

export const queryParamsInit = createAction('[QueryParams] Init');
