import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT, REQUEST_SOURCE } from '@common/util-foundation';
import { Environment, LoadFeatureConfigResponse } from '@common/util-models';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureConfigApiService {
  constructor(
    @Inject(REQUEST_SOURCE) private requestSource: string,
    @Inject(ENVIRONMENT) private environment: Environment,
    private httpClient: HttpClient
  ) {}

  getFeatureConfig(): Observable<LoadFeatureConfigResponse> {
    return this.httpClient
      .get<LoadFeatureConfigResponse>(
        `${this.environment.appRoot}local-data/features/${this.requestSource}.json`
      )
      .pipe(
        catchError((error: Error) => {
          console.warn(error.message);
          return throwError(error);
        })
      );
  }
}
