import { createAction, props } from '@ngrx/store';
import { LoadFeatureConfigResponse } from '@common/util-models';

export const loadFeatureConfig = createAction('[FeatureConfig] Load Config');

export const loadFeatureConfigSuccess = createAction(
  '[FeatureConfig] Load Config Success',
  props<{
    response: LoadFeatureConfigResponse;
  }>()
);

export const loadFeatureConfigFailure = createAction(
  '[FeatureConfig] Load Config Failure',
  props<{ error: Error }>()
);
