import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  CmsFormField,
  DirectDebitFormField,
} from '@domgen/dgx-fe-business-models';
import {
  CheckboxToggleDef,
  ControlType,
  CustomValidatorService,
  FieldDef,
  SelectDef,
  SortCodeDef,
  TextInputDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import * as CmsHelpers from '../../cms-form-helper/cms-form-helpers';

@Injectable({
  providedIn: 'root',
})
export class DirectDebitFormConfigService {
  defaultPaymentDay = '1';
  constructor(private customValidatorService: CustomValidatorService) {}

  public getFormbuilderConfig(cmsFormData: CmsFormField[]): FieldDef[] {
    return [
      this.getInputDefByFieldName(
        cmsFormData,
        DirectDebitFormField.AccountName
      ),
      this.getInputDefByFieldName(
        cmsFormData,
        DirectDebitFormField.AccountNumber
      ),
      this.getInputDefByFieldName(cmsFormData, DirectDebitFormField.SortCode),
      this.getInputDefByFieldName(
        cmsFormData,
        DirectDebitFormField.MonthlyPaymentDay
      ),
      this.getInputDefByFieldName(
        cmsFormData,
        DirectDebitFormField.AutoRenewalToggle
      ),
      this.getInputDefByFieldName(
        cmsFormData,
        DirectDebitFormField.AccountHolderConfirmation
      ),
    ].filter((item) => Boolean(item)) as FieldDef[];
  }

  private getInputDefByFieldName(
    cmsFormData: CmsFormField[],
    formField: DirectDebitFormField
  ): FieldDef | undefined {
    let fieldDef: FieldDef;

    const cmsFormField = CmsHelpers.getFormField<CmsFormField>(
      cmsFormData,
      formField
    );
    if (!cmsFormField) {
      return undefined;
    }

    const defaultFieldDef = CmsHelpers.getDefaultFieldDef(
      cmsFormField,
      formField
    );

    switch (formField) {
      case DirectDebitFormField.AccountName:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.INPUT,
          validators: [Validators.required],
          sanitise: 'block',
        } as TextInputDef;
        break;
      case DirectDebitFormField.AccountNumber:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.INPUT,
          maxlength: 8,
          validators: [
            Validators.required,
            this.customValidatorService.directDebitAccountNumberValidator,
          ],
          sanitise: 'block',
        } as TextInputDef;
        break;
      case DirectDebitFormField.SortCode:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.SORTCODE,
          validators: [
            Validators.required,
            Validators.minLength(6),
            this.customValidatorService.directDebitSortCodeValidator,
          ],
        } as SortCodeDef;
        break;
      case DirectDebitFormField.MonthlyPaymentDay:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.SELECT,
          validators: [Validators.required],
          options: this.dayOfMonth(),
          initialValue: this.defaultPaymentDay,
          fitContent: true,
        } as SelectDef;
        break;
      case DirectDebitFormField.AutoRenewalToggle:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.CHECKBOX,
          initialValue: false,
          validators: [],
        } as CheckboxToggleDef;
        break;
      case DirectDebitFormField.AccountHolderConfirmation:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.CHECKBOX,
          initialValue: false,
          validators: [Validators.requiredTrue],
        } as CheckboxToggleDef;
        break;
      default:
        fieldDef = {
          ...defaultFieldDef,
        } as FieldDef;
        break;
    }

    return fieldDef;
  }

  private dayOfMonth(maxDay: number = 28): string[] {
    const days = [];
    for (let i = 1; i <= maxDay; i++) {
      days.push(`${i}`);
    }

    return days;
  }
}
