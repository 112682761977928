import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AnalyticsData,
  CmsFormField,
  WrappedValidate,
} from '@domgen/dgx-fe-business-models';
import { MarketingPreferencesFormStateService } from '@domgen/dgx-fe-business-components';
import { MarketingPreferencesFormConfigService } from '@domgen/dgx-fe-business-components';

@Component({
  selector: 'wl-marketing-preferences-form',
  templateUrl: './marketing-preferences-form.component.html',
  styleUrls: ['./marketing-preferences-form.component.scss'],
  providers: [
    MarketingPreferencesFormStateService,
    MarketingPreferencesFormConfigService,
  ],
})
export class MarketingPreferencesFormComponent {
  @Input() set cmsFormData(value: CmsFormField[] | null) {
    if (value) {
      this.stateService.setCmsFormData(value);
    }
  }

  @Input() set validate(validate: WrappedValidate | null) {
    if (validate?.validate) {
      this.stateService.validate();
    }
  }

  @Input() privacyPolicyUrl = '';

  @Output() form = this.stateService.formGroup$;

  @Output() valueChanges = this.stateService.valueChanges$;

  /**
   * Analytics data for the form
   */
  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  readonly vm$ = this.stateService.vm$;

  constructor(
    private readonly stateService: MarketingPreferencesFormStateService
  ) {}

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }
}
