import { FormGroup } from '@angular/forms';
import { OperatorFunction, pipe } from 'rxjs';
import { filter, map, skip, startWith, switchMap } from 'rxjs/operators';
import { distinctUntilChangedDeep } from '../distinct-until-changed-deep/distinct-until-changed-deep';

export function formValue<T>(): OperatorFunction<
  FormGroup | null | undefined,
  T
> {
  return pipe(
    filter((form: FormGroup | null | undefined) => !!form),
    map((form: FormGroup | null | undefined) => form as FormGroup),
    switchMap((form: FormGroup) =>
      form.valueChanges.pipe(
        startWith(form.getRawValue()),
        map(() => form.getRawValue()),
        distinctUntilChangedDeep()
      )
    )
  );
}

export function formValueChanges<T>(): OperatorFunction<
  FormGroup | null | undefined,
  T
> {
  return pipe(formValue<T>(), skip(1));
}
