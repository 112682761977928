import { Component, Input } from '@angular/core';

@Component({
  selector: 'dgx-uib-direct-debit-guarantee-dynamic',
  templateUrl: './direct-debit-guarantee-dynamic.component.html',
  styleUrls: ['./direct-debit-guarantee-dynamic.component.scss'],
})
export class DirectDebitGuaranteeDynamicComponent {
  @Input() title = 'The Direct Debit Guarantee';
}
