import { AbstractControl } from '@angular/forms';
import { of, OperatorFunction, pipe } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  skip,
  startWith,
  switchMap,
} from 'rxjs/operators';

export function controlDisabled(): OperatorFunction<
  AbstractControl | null | undefined,
  boolean
> {
  return pipe(
    switchMap(
      (control: AbstractControl | null | undefined) =>
        control?.statusChanges.pipe(
          map(() => control.disabled),
          startWith(control.disabled)
        ) ?? of(false)
    ),
    distinctUntilChanged()
  );
}

export function controlDisabledChanges(): OperatorFunction<
  AbstractControl | null | undefined,
  boolean
> {
  return pipe(controlDisabled(), skip(1));
}
