<ui-panel>
  <ui-panel-header>
    <ui-panel-title>The Direct Debit Guarantee</ui-panel-title>
    <img
      src="assets/images/icon-payment-direct-debit.svg"
      class="direct-debit-logo"
    />
  </ui-panel-header>
  <ui-panel-body>
    <ul>
      <li>
        This guarantee is offered by all banks and building societies that
        accept instructions to pay Direct Debits
      </li>
      <li>
        If there are any changes to the amount or frequency of your Direct
        Debit, <strong> {{ orgName }} </strong> will notify you
        {{ notificationDays }} working days in advance of your account being
        debited or otherwise agreed. If you request
        <strong> {{ orgName }} </strong> to collect a payment, confirmation of
        the amount and date will be given to you at the time of the request.
      </li>
      <li>
        If an error is made in the payment of your Direct Debit, by
        <strong> {{ orgName }} </strong> or your bank or building society,
        you’re entitled to a full and immediate refund of the amount paid from
        your bank or building society. If you receive a refund you are not
        entitled to, you must pay it back when
        <strong> {{ orgName }} </strong> asks you to.
      </li>
      <li>
        You can cancel a Direct Debit at any time by simply contacting your bank
        or building society. Written confirmation may be required. Please also
        notify us.
      </li>
    </ul>
  </ui-panel-body>
</ui-panel>
