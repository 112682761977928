import { Inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  filterNullUndefined,
  GaTaggingService,
  GA_TAGS,
  pushToGa,
} from '@common/util-foundation';
import { mapTo } from 'rxjs/operators';
import { GoogleTagConfig } from '@common/util-models';

@Injectable()
export class ErrorPageGaTaggingService extends ComponentStore<never> {
  // **** Tag as soon as you land and log Common data to GA and end the stream **** //
  readonly commonAndLandingGaTags = this.effect((trigger$) =>
    trigger$.pipe(
      mapTo(this.gaTags.onLoadErrorPageData),
      filterNullUndefined(),
      pushToGa(this.gaTaggingService)
    )
  )();

  constructor(
    @Inject(GA_TAGS) private gaTags: GoogleTagConfig,
    private gaTaggingService: GaTaggingService
  ) {
    super();
  }
}
