import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPageData } from '@common/util-models';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public readonly defaultErrorPageData: ErrorPageData = {
    header: 'Sorry, something’s not right.',
    additionalSupport: '',
    support:
      'It looks like there’s been a technical issue but we’re working to fix it',
    contactCustomerService:
      'Try calling our friendly team to receive your quote on:',
  };

  private readonly defaultErrorPageData$: BehaviorSubject<ErrorPageData> = new BehaviorSubject(
    this.defaultErrorPageData
  );

  private readonly currentErrorPageData$: BehaviorSubject<
    Partial<ErrorPageData>
  > = new BehaviorSubject<Partial<ErrorPageData>>({});

  public errorPageData$ = combineLatest([
    this.defaultErrorPageData$,
    this.currentErrorPageData$,
  ]).pipe(
    map(([defaultErrorPageData, currentErrorPageData]) => ({
      ...defaultErrorPageData,
      ...currentErrorPageData,
    }))
  );

  constructor(private router: Router) {}

  public handleError(errorPageData?: Partial<ErrorPageData>): void {
    this.currentErrorPageData$.next(errorPageData ?? {});
    this.router.navigate(['/error']);
  }

  public setDefaultErrorPageData(defaultErrorPageData: ErrorPageData): void {
    this.defaultErrorPageData$.next(defaultErrorPageData);
  }
}
