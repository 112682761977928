import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'dgx-uib-cover-starting-date-info',
  templateUrl: './cover-starting-date-info.component.html',
  styleUrls: ['./cover-starting-date-info.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverStartingDateInfoComponent {
  @Input() infoText!: string | undefined;
  @Input() infoTitle!: string | undefined;
}
