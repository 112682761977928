import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interpolate',
})
export class InterpolatePipe implements PipeTransform {
  private readonly interpolationRegExp: RegExp = new RegExp(
    /{{\s*([\w]*)\s*}}/g
  );
  private readonly interpolationKeyRegExp: RegExp = new RegExp(
    /(\s*[{}]+)\s*/g
  );

  transform(
    value: string,
    data: { [key: string]: string | number | boolean | undefined }
  ): string {
    const interpolationMatches: RegExpMatchArray | null = value.match(
      this.interpolationRegExp
    );

    if (!interpolationMatches) {
      return value;
    }

    return interpolationMatches.reduce(
      (interpolatedValue: string, interpolationMatch: string) => {
        const interpolationKey: string = interpolationMatch.replace(
          this.interpolationKeyRegExp,
          ''
        );
        const interpolationValue = data[interpolationKey];

        if (interpolationValue !== undefined) {
          return interpolatedValue.replace(
            interpolationMatch,
            interpolationValue?.toString()
          );
        }

        return interpolatedValue;
      },
      value
    );
  }
}
