import { mapCmsFormData } from '@common/data-access-shared';
import { CmsDataUrl, CmsPage } from '@common/util-models';

const pageName = 'dgx-forms';

export const cmsDataUrl: CmsDataUrl = {
  [CmsPage.Reinstate]: {
    id: CmsPage.Reinstate,
    url: `${pageName}-reinstateForm.json`,
    mapper: mapCmsFormData,
  },
};
