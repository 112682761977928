import { User } from "@domgen/dgx-fe-auth/lib/common/models/user";
import { getDigitalIdFromUser } from "./auth.types";

export interface Dictionary<T> {
  [key: string]: T;
}

export type GaTags = Dictionary<string | unknown>;

export interface GoogleTagManager {
  push(payload: GaTags): void;
}

export const getCustomerDetails = (user: User | undefined) => {
  const digitalId = getDigitalIdFromUser(user);
  return {
    customerType: digitalId ? 'L' : 'G',
    CustomerId: digitalId,
    DigitalID: digitalId
  }
};
