/* istanbul ignore file */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccordionComponentsModule } from '@domgen/dgx-fe-components-core';
import { FooterLegalComponent } from './components/footer-legal/footer-legal.component';
import { FooterNavigationComponent } from './components/footer-navigation/footer-navigation.component';
import { FooterSocialComponent } from './components/footer-social/footer-social.component';
import { FooterComponent } from './components/footer/footer.component';

const declarations = [
  FooterComponent,
  FooterLegalComponent,
  FooterNavigationComponent,
  FooterSocialComponent,
];

@NgModule({
  declarations: declarations,
  exports: declarations,
  imports: [CommonModule, RouterModule, AccordionComponentsModule],
})
export class FooterModule {}
