import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UrlQueryParamsParser {
  private remaining!: string;
  private queryParamRegex = /^[^=?&#]+/;
  private queryParamValueRegex = /^[^?&#]+/;

  parseQueryParams(search: string): Params {
    this.remaining = search;
    const params: Params = {};
    if (this.consume('?')) {
      do {
        this.parseQueryParam(params);
      } while (this.consume('&'));
    }
    return params;
  }

  private consume(str: string): boolean {
    if (this.startsWith(str)) {
      this.remaining = this.remaining.substring(str.length);
      return true;
    }
    return false;
  }

  private startsWith(str: string): boolean {
    return this.remaining.startsWith(str);
  }

  private parseQueryParam(params: Params): void {
    const key = this.matchQueryParams(this.remaining);
    if (!key) {
      return;
    }

    this.consume(key);
    let value = '';

    if (this.consume('=')) {
      const valueMatch = this.matchQueryParamValue(this.remaining);
      if (valueMatch) {
        value = valueMatch;
        this.consume(value);
      }
    }

    const decodedKey = this.decodeQuery(key);
    const decodedVal = this.decodeQuery(value);

    params[decodedKey] = decodedVal;
  }

  private matchQueryParams(str: string): string {
    const match = str.match(this.queryParamRegex);
    return match ? match[0] : '';
  }

  private matchQueryParamValue(str: string): string {
    const match = str.match(this.queryParamValueRegex);
    return match ? match[0] : '';
  }

  private decodeQuery(s: string): string {
    return decodeURIComponent(s.replace(/\+/g, '%20'));
  }
}
