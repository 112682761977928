import { Component, Input } from '@angular/core';

@Component({
  selector: 'sales-helpful-links',
  templateUrl: './helpful-links.component.html',
  styleUrls: ['./helpful-links.component.scss'],
})
export class HelpfulLinksComponent {
  @Input() home: string | undefined;
  @Input() faq: string | undefined;
  @Input() contact: string | undefined;
}
