import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeatureConfigEffects } from './+state/feature-config.effects';
import { FeatureConfigFacade } from './+state/feature-config.facade';
import * as fromQuotes from './+state/feature-config.reducers';
import { FeatureConfigApiService } from './services/feature-config-api/feature-config-api.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromQuotes.FEATURE_CONFIG_FEATURE_KEY,
      fromQuotes.reducer
    ),
    EffectsModule.forFeature([FeatureConfigEffects]),
  ],
  providers: [FeatureConfigApiService, FeatureConfigFacade],
})
export class CommonDataAccessFeatureConfigModule {}
