import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ErrorService } from '@common/util-foundation';
import { ErrorTaggingService } from './error-tagging.service';

@Component({
  selector: 'sales-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ErrorTaggingService],
})
export class ErrorComponent {
  @Input() contactHelpNumber: string | undefined;
  @Input() home: string | undefined;
  @Input() faq: string | undefined;
  @Input() contact: string | undefined;
  public readonly errorPageData$ = this.errorService.errorPageData$;

  constructor(
    private errorService: ErrorService,
    public errorTaggingService: ErrorTaggingService
  ) {}
}
