import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FindAddressResponse,
  RetrieveAddressResponse,
} from '../interfaces/address.interface';
import { FormbuilderConfigService } from './formbuilder-config.service';

@Injectable()
export class AddressLookupService {
  constructor(
    private http: HttpClient,
    private formbuilderConfigService: FormbuilderConfigService
  ) {}

  findAddress(input: string, id: string = '') {
    const url = `${this.config.postcodeServiceFind}?key=${this.config.postcodeServiceKey}&Text=${input}&Container=${id}&Origin=&IsMiddleware=false&Countries=GB&Limit=&Language=`;
    return this.http.get<FindAddressResponse>(url);
  }

  retrieveAddress(id: string) {
    const url = `${this.config.postcodeServiceRetrieve}?key=${this.config.postcodeServiceKey}&Id=${id}`;
    return this.http.get<RetrieveAddressResponse>(url);
  }

  get config() {
    return this.formbuilderConfigService.config;
  }
}
