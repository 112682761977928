import {
  ReinstateFormSubmit,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
} from '@common/util-models';
import { createReducer, on, Action } from '@ngrx/store';
import * as ReinstateActions from './reinstate.actions';

export const REINSTATE_FEATURE_KEY = 'reinstate';

export interface ReinstateState {
  remoteState: RemoteData<null>;
  formData: ReinstateFormSubmit | null;
  validationFailed: boolean | null;
}

export interface ReinstatePartialState {
  readonly [REINSTATE_FEATURE_KEY]: ReinstateState;
}

export const initialState: ReinstateState = {
  remoteState: remoteDataNotFetched(),
  formData: null,
  validationFailed: null,
};

const reinstateReducer = createReducer(
  initialState,
  on(ReinstateActions.reinstateDirectDebit, (state, { formData }) => ({
    ...state,
    remoteState: remoteDataLoading(),
    validationFailed: null,
    formData,
  })),
  on(ReinstateActions.reinstateDirectDebitSuccess, (state) => ({
    ...state,
    remoteState: remoteDataOK(null),
  })),
  on(ReinstateActions.reinstateDirectDebitFailure, (state, { error }) => ({
    ...state,
    remoteState: remoteDataError(error),
  })),
  on(ReinstateActions.reinstateValidationFailure, (state) => ({
    ...state,
    validationFailed: true,
  }))
);

export function reducer(state: ReinstateState | undefined, action: Action) {
  return reinstateReducer(state, action);
}
