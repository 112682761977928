<form [formGroup]="formGroup" *ngIf="isInsurance">
  <wl-terms-and-conditions-for-insurance
    *ngIf="field"
    [formControlName]="field.controlName"
    [field]="field"
    [validate]="validate"
  >
    <wl-terms-and-conditions-text
      [termsUrl]="termsAndConditionsUrl"
      [policyUrl]="privacyPolicyUrl"
      [isInsurance]="isInsurance"
      [productInfoUrl]="ipidUrl"
    ></wl-terms-and-conditions-text>
  </wl-terms-and-conditions-for-insurance>
</form>
<wl-terms-and-conditions-text
  *ngIf="!isInsurance"
  [termsUrl]="termsAndConditionsUrl"
  [policyUrl]="privacyPolicyUrl"
  [isInsurance]="isInsurance"
>
</wl-terms-and-conditions-text>
