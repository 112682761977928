import { ControlType, FieldDef } from '@domgen/dgx-fe-dynamic-form-builder';
import {
  CmsAsset,
  CmsFormField,
  QuoteDocument,
} from '@domgen/dgx-fe-business-models';

export function getValidationMessages(cmsFormFieldData: CmsFormField) {
  let validationMessageData: Array<{ type: string; message: string }> = [];

  for (let index = 0; index < Number.POSITIVE_INFINITY; index++) {
    if (cmsFormFieldData?.validationMessages[index]) {
      const validationMessage = cmsFormFieldData.validationMessages[index];
      validationMessageData = [
        ...validationMessageData,
        { type: validationMessage.type, message: validationMessage.message },
      ];
    } else {
      break;
    }
  }

  return validationMessageData;
}

export function getFormField<T extends CmsFormField = CmsFormField>(
  cmsData: CmsFormField[],
  formField: string
): T {
  return <T>cmsData.find((item) => item.controlName === formField);
}

/**
 Method to get default field definition based on Magnolia data
 */
export function getDefaultFieldDef(
  cmsFormField: CmsFormField,
  controlName: string
): FieldDef {
  return {
    controlType: ControlType.INPUT,
    controlName: controlName,
    validators: [],
    label: { text: cmsFormField.label },
    validationMessages: [...getValidationMessages(cmsFormField)],
    placeholder: cmsFormField.placeholder,
    hint: cmsFormField.hint,
    tooltip: cmsFormField.tooltip,
  } as FieldDef;
}

export function getDocument(
  cmsAsset: CmsAsset,
  damRawUrl?: string
): QuoteDocument {
  if (typeof cmsAsset === 'string') {
    console.error(
      'Asset type is string - returning empty QuoteDocument. Are you inside Magnolia Editor?'
    );
    return { name: '', link: '' };
  }
  return {
    name: cmsAsset.metadata.title,
    link: getCmsAssetLink(cmsAsset, damRawUrl),
  } as QuoteDocument;
}

export function getCmsAssetLink(
  cmsAsset: CmsAsset,
  damRawUrl?: string
): string {
  const separator =
    cmsAsset['@link'] && cmsAsset['@link'].startsWith('/') ? '' : '/';
  return `${damRawUrl}${separator}${cmsAsset['@link']}`;
}
