import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

export interface NavigationItem {
  id: string;
  title: string;
  links: Array<NavigationLink>;
}

@Component({
  selector: 'dgx-ui-wl-footer-navigation',
  templateUrl: './footer-navigation.component.html',
  styleUrls: ['./footer-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterNavigationComponent {
  public isNavigationItemsFull = false;
  public navigationItems: NavigationItem[] = [
    {
      id: 'protect',
      title: 'Products we protect',
      links: [],
    },
    {
      id: 'more',
      title: 'More from us',
      links: [],
    },
    {
      id: 'useful',
      title: 'Useful links',
      links: [],
    },
    {
      id: 'legal',
      title: 'Legal',
      links: [],
    },
  ];
  @Input() set protectLinks(navigationLinks: NavigationLink[] | undefined) {
    this.navigationItems = this.mapLinks(
      'protect',
      navigationLinks,
      this.navigationItems
    );
    this.isNavigationItemsFull = this.isFull(this.navigationItems);
  }
  @Input() set moreLinks(navigationLinks: NavigationLink[] | undefined) {
    this.navigationItems = this.mapLinks(
      'more',
      navigationLinks,
      this.navigationItems
    );
    this.isNavigationItemsFull = this.isFull(this.navigationItems);
  }
  @Input() set usefulLinks(navigationLinks: NavigationLink[] | undefined) {
    this.navigationItems = this.mapLinks(
      'useful',
      navigationLinks,
      this.navigationItems
    );
    this.isNavigationItemsFull = this.isFull(this.navigationItems);
  }
  @Input() set legalLinks(navigationLinks: NavigationLink[] | undefined) {
    this.navigationItems = this.mapLinks(
      'legal',
      navigationLinks,
      this.navigationItems
    );
    this.isNavigationItemsFull = this.isFull(this.navigationItems);
  }

  @Output() clicked = new EventEmitter<{
    event: MouseEvent;
    link: string;
  }>();
  @Output() toggle = new EventEmitter();

  public handleLinkClick(event: MouseEvent, link: string) {
    this.clicked.emit({ event, link });
  }

  private mapLinks(
    key: string,
    links: NavigationLink[] | undefined,
    array: NavigationItem[]
  ) {
    return links
      ? [...array.map((item) => (item.id === key ? { ...item, links } : item))]
      : array;
  }

  private isFull(navigationItem: NavigationItem[]) {
    return navigationItem.every((item) => item.links.length);
  }

  onToggle(item: NavigationItem, isOpen: boolean) {
    this.toggle.emit({
      item,
      isOpen,
    });
  }
}
