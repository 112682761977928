import { Inject, Injectable } from '@angular/core';
import {
  GaCheckoutPages,
  GaDimensions,
  GaDimensionsValues,
  GoogleTagConfig,
} from '@common/util-models';
import { GA_TAGS } from '../../tokens';

@Injectable({
  providedIn: 'root',
})
export class GaHelperService {
  constructor(@Inject(GA_TAGS) private gaConfig: GoogleTagConfig) {}

  public getDimensions(values: GaDimensionsValues) {
    const dimensions = Object.keys(GaDimensions) as GaDimensions[];
    return dimensions.reduce<Record<string, any>>((agg, dimensionName) => {
      const dimension = this.gaConfig.dimensions[dimensionName];
      if (dimension === null || typeof values[dimensionName] === 'undefined') {
        return agg;
      }
      return {
        ...agg,
        [dimension]: values[dimensionName],
      };
    }, {});
  }

  public getCheckoutEcommerceProps(
    page: GaCheckoutPages,
    data: Record<string, unknown> &
      Partial<{ actionField: Record<string, unknown> }> = {}
  ) {
    const step = this.gaConfig.checkoutSteps[page];
    if (step === null) {
      return {};
    }
    return {
      ecommerce: {
        checkout: {
          ...data,
          actionField: {
            ...(data.actionField || {}),
            step: step.toString(10),
          },
        },
      },
    };
  }
}
