import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CorrelationIdState,
  CORRELATION_ID_KEY,
} from './correlation-id.reducer';

export const getCorrelationIdState = createFeatureSelector<CorrelationIdState>(
  CORRELATION_ID_KEY
);

export const getCorrelationId = createSelector(
  getCorrelationIdState,
  (correlationIdState: CorrelationIdState) => correlationIdState.correlationId
);
