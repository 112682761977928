import {
  distinctUntilChangedDeep,
  filterNullUndefined,
} from '@common/util-foundation';
import {
  Basket,
  BasketItem,
  BasketItemApplianceDetails,
  BasketItemQuoteDetails,
  convertPaymentOptionsToObject,
  Cover,
  CoverType,
  ExcessPrice,
  ItemType,
  PaymentType,
  Quote,
  QuotesConfig,
  SelectedBasketItem,
  SelectedBasketItemApplianceQuote,
} from '@common/util-models';
import {
  CmsFormField,
  DirectDebitFormField,
} from '@domgen/dgx-fe-business-models';
import { MonoTypeOperatorFunction, Observable, pipe } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { QuotesState } from './quotes.reducer';

export const DISCOUNT_MONTHS = 4;

export const transformQuoteDetailToCover = ({
  paymentOptions,
  ...cover
}: BasketItemQuoteDetails): Cover => ({
  ...cover,
  paymentOptions: convertPaymentOptionsToObject(paymentOptions),
});

export function selectedItemApplianceQuotes(
  state: QuotesState
): SelectedBasketItemApplianceQuote[] {
  return state.selectedItems.reduce(
    (quotes: SelectedBasketItemApplianceQuote[], selectedItem) => {
      const selectedItemQuote = selectedItemApplianceQuote(state, selectedItem);
      return selectedItemQuote ? [...quotes, selectedItemQuote] : quotes;
    },
    []
  );
}

export function selectedItemApplianceQuote(
  state: QuotesState,
  selectedItem: SelectedBasketItem
): SelectedBasketItemApplianceQuote | undefined {
  const item: BasketItem | undefined = state.quoteApiResult?.basket.items.find(
    (item) => item.itemId === selectedItem.itemId
  );
  const quote: BasketItemQuoteDetails | undefined = item?.data.quotes.find(
    (quote) => quote.quoteId === selectedItem.quoteId
  );

  if (item && quote) {
    return {
      itemId: item.itemId,
      itemType: item.itemType,
      applianceDetails: item.data.applianceDetails,
      quote,
    };
  }
  return undefined;
}

export function generateCurrentItemIdFromBasket(
  basket: Basket
): string | undefined {
  if (basket?.items?.length) {
    return basket.items[0].itemId;
  }
  return undefined;
}

export function generateCurrentItemIdFromSelectedItems(
  selectedItems: SelectedBasketItem[]
): string | undefined {
  if (selectedItems?.length) {
    return selectedItems[0].itemId;
  }
  return undefined;
}

export function generateAvailableExcessPrices(
  quotes: BasketItemQuoteDetails[] | undefined,
  selectedCoverType: CoverType
): ExcessPrice[] {
  if (!quotes) {
    return [];
  }
  //get selected cover entity from quote
  const covers = quotes.filter((q) => selectedCoverType === q.coverType);
  if (!covers.length) {
    return [];
  }
  //we are working on the assumption that each cover will have the same paymentOption for each excessAmount
  return covers
    .map(
      (cover): ExcessPrice => ({
        excessAmount: cover.excessAmount,
        paymentOptions: convertPaymentOptionsToObject(cover.paymentOptions),
      })
    )
    .sort((a, b) => b.excessAmount - a.excessAmount);
}

export function excessToDisplay(
  quotes: BasketItemQuoteDetails[] | undefined,
  selectedCoverType: CoverType,
  excessAmount: number
) {
  return generateAvailableExcessPrices(quotes, selectedCoverType).length > 1
    ? excessAmount
    : null;
}

export function quotesForItem(state: QuotesState, itemId: string) {
  return state.quoteApiResult?.basket.items.find(
    (item) => item.itemId === itemId
  )?.data.quotes;
}

export function getCoverDescription(
  coverType: CoverType,
  config: QuotesConfig | undefined,
  isUpgrade: boolean,
  initialCover?: CoverType
): string {
  if (!isUpgrade) {
    return config?.coverTypeDescription?.[coverType] ?? '';
  }

  if (isUpgrade && initialCover) {
    return (
      config?.coverTypeUpgrades?.[initialCover]?.find(
        (c) => c.coverType === coverType
      )?.description ?? ''
    );
  }

  return '';
}

export function generateQuoteFromBasketApplianceDetailsAndCover(
  applianceDetails?: BasketItemApplianceDetails,
  cover?: Cover,
  item?: { itemId: string; itemType: ItemType }
): Quote | undefined {
  if (!item || !cover || !applianceDetails) {
    return undefined;
  }

  return {
    applianceCode: applianceDetails.applianceCode,
    applianceCategory: applianceDetails.applianceCategory,
    applianceName: applianceDetails.applianceName,
    brandCode: applianceDetails.brandCode,
    brandName: applianceDetails.brandName,
    purchaseDate: new Date(
      applianceDetails.purchaseYear,
      applianceDetails.purchaseMonth - 1
    ),
    applianceIcon: applianceDetails.applianceIcon,
    cover,

    itemId: item.itemId,
    itemType: item.itemType,
  };
}
export function generateCoverFromBasketItemQuote(
  selectedCoverType: CoverType,
  quoteDetail?: BasketItemQuoteDetails,
  config?: QuotesConfig
): Cover | undefined {
  if (!quoteDetail) {
    return;
  }
  const name =
    config?.coverTypeNames && config?.coverTypeNames[selectedCoverType];
  return { name, ...transformQuoteDetailToCover(quoteDetail) };
}

export const totalToPayMultiQuote = (quoteList: Quote[]) => {
  return quoteList.reduce((acc, quote) => {
    if (!quote?.cover?.paymentOptions?.directDebit?.subsequentPayment) {
      return acc;
    }
    return (acc += quote.cover.paymentOptions.directDebit.subsequentPayment);
  }, 0);
};

export const getQuoteIdsToGa = (multiItemQuotes: Quote[]): string => {
  return multiItemQuotes
    .reduce((acc, quote, idx) => {
      return quote ? `${acc} | Quote ${idx + 1}: ${quote.itemId}` : acc;
    }, '')
    .slice(3);
};

export const getOrderDetailsMultiQuote = (
  multiItemQuotes: Quote[],
  paymentType?: PaymentType | undefined
): string => {
  if (multiItemQuotes.length === 1) {
    return `${multiItemQuotes[0].brandName} ${
      multiItemQuotes[0].applianceName
    } | ${
      paymentType && paymentType === PaymentType.Card
        ? 'Credit/Debit Card'
        : 'Direct Debit'
    } | ${
      multiItemQuotes[0].cover.paymentOptions.directDebit?.subsequentPayment
    }`;
  }
  return multiItemQuotes
    .reduce((acc, quote, idx) => {
      return quote
        ? `${acc} | Appliance ${idx + 1}: ${quote.brandName} ${
            quote.applianceName
          } | Direct Debit | ${
            quote.cover.paymentOptions.directDebit?.subsequentPayment
          }`
        : acc;
    }, '')
    .slice(3);
};

export function filterAutoRenewalOptOut(
  displayAutoRenewalOptOut$: Observable<boolean>
): MonoTypeOperatorFunction<CmsFormField[]> {
  return pipe(
    distinctUntilChangedDeep(),
    filterNullUndefined(),
    withLatestFrom(displayAutoRenewalOptOut$),
    map(([cmsFormField, displayAutoRenewalOptOut]) => {
      if (displayAutoRenewalOptOut) {
        return cmsFormField;
      }
      return cmsFormField.filter(
        (cmsFormData) =>
          cmsFormData.controlName !== DirectDebitFormField.AutoRenewalToggle
      );
    })
  );
}
