import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QuoteDocument } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'sales-need-to-know',
  templateUrl: './need-to-know.component.html',
  styleUrls: ['./need-to-know.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NeedToKnowComponent {
  @Input() termsAndConditionsDoc: QuoteDocument | undefined = undefined;
  @Input() ipidDoc: QuoteDocument | undefined = undefined;
}
