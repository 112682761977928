import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'dgx-ui-wl-ao-footer',
  templateUrl: './ao-footer.component.html',
  styleUrls: ['./ao-footer.component.scss'],
})
// TODO: Remove DEPRECATED ao-footer component and use one from parent folder.
export class AoFooterComponent {
  @Input() protectLinks: NavigationLink[] = [];

  @Input() usefulLinks: NavigationLink[] = [];

  @Input() legalLinks: NavigationLink[] = [];

  @Output() clicked = new EventEmitter<{
    event: MouseEvent;
    link: string;
  }>();

  currentYear = new Date().getFullYear();

  public handleLinkClick(event: MouseEvent, link: string) {
    this.clicked.emit({ event, link });
  }
}
