import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from './window.token';

export interface TrustPilotManager {
  loadFromElement(args: unknown): void;
}

export const TRUST_PILOT_MANAGER = new InjectionToken<TrustPilotManager>(
  'Trustpilot',
  {
    providedIn: 'root',
    factory: (): TrustPilotManager => {
      return (
        (inject(WINDOW) as { Trustpilot?: TrustPilotManager })?.Trustpilot || {
          loadFromElement: () =>
            console.warn('window.Trustpilot is not defined.'),
        }
      );
    },
  }
);
