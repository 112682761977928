import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AUTH_FEATURE_KEY,
  AuthSsoState,
  AuthPartialState,
} from './auth.reducer';
import { selectIsAuthenticated, selectUser } from '@domgen/dgx-fe-auth';

// Lookup the 'Auth' feature state managed by NgRx
export const getAuthState = createFeatureSelector<
  AuthPartialState,
  AuthSsoState
>(AUTH_FEATURE_KEY);

export const getIsLoggedIn = createSelector(
  getAuthState,
  selectIsAuthenticated,
  (state: AuthSsoState, isAuthLegacy) => state.isLoggedIn || isAuthLegacy
);

export const getCustomerName = createSelector(
  getAuthState,
  (state: AuthSsoState) => state.customerName
);

export const getSessionExpiresOn = createSelector(
  getAuthState,
  (state: AuthSsoState) => state.sessionExpiresOn
);

export const getUser = createSelector(
  getAuthState,
  selectUser,
  (state: AuthSsoState, legacyUser) => state.user || legacyUser
);

export const getAllAuth = createSelector(
  getAuthState,
  (state: AuthSsoState) => state
);
