<div [formGroup]="formGroup">
  <dgx-dfb-checkbox
    [formControlName]="field.controlName"
    [validate]="validate"
    [field]="field"
  ></dgx-dfb-checkbox>
</div>
<div>
  If you can’t confirm these statements, we are unable to accept payment by
  Direct Debit.
</div>
