import { Component, Input } from '@angular/core';
import { CmsDynamicComponentContent } from '@domgen/dgx-fe-content-management';

@Component({
  selector: 'common-cms-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss'],
})
export class HeadlineComponent {
  @Input() content!: CmsDynamicComponentContent;
}
