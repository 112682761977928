<div class="form-group">
  <div
    class="accept"
    [ngClass]="{
      'accept--invalid': !!errorMessage
    }"
  >
    <div class="documents">
      For your own benefit and protection, please read the
      <a
        target="_blank"
        class="link"
        href="https://sit-cms.domesticandgeneral.com/dam/jcr:8849434e-8c46-4bae-a709-4fd031cf7215/WHI_TCWIM_1118_21_WEB.pdf"
        >Policy Terms and Conditions</a
      >
      and
      <a
        target="_blank"
        class="link"
        href="https://www.domesticandgeneral.com/privacy-cookies-policy"
        >Privacy Policy</a
      >
      carefully before purchase.
      <span>
        If purchasing an insurance policy, you’ll need to switch this toggle to
        confirm that you have read the
        <a
          href="https://sit-cms.domesticandgeneral.com/dam/jcr:15478cf7-8b5b-4573-8c8d-c989012d2329/DG_IIPM_0418_06%20(1).pdf"
          target="_blank"
          class="link"
          >Insurance Product Information Document</a
        >
        and that this product meets your demands and needs.
      </span>
    </div>

    <div class="toggle">
      <label
        class="switch"
        [ngClass]="{
          'toggle--readonly': isReadonly,
          'toggle--checked': isChecked,
          'toggle--disabled': field?.disabled
        }"
      >
        <input
          type="checkbox"
          [checked]="isChecked"
          [disabled]="isReadonly || field?.disabled"
          (click)="onClick()"
        />
        <span class="slider round" [class.active]="isChecked"></span>
      </label>

      <div
        *ngIf="field?.label?.text"
        [class]="field?.label?.classes || 'toggle__text'"
      >
        {{ field?.label?.text }}
      </div>
    </div>
  </div>

  <dgx-dfb-error [errorMessage]="errorMessage"></dgx-dfb-error>
</div>
