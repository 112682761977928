import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanDocumentsComponent } from './plan-documents.component';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';

@NgModule({
  imports: [CommonModule, SharedUiDynamicFormBuilderModule],
  declarations: [PlanDocumentsComponent],
  exports: [PlanDocumentsComponent],
})
export class PlanDocumentsModule {}
