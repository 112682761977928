import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  CmsFormField,
  MarketingPreferences,
  MarketingPreferencesFormField,
} from '@domgen/dgx-fe-business-models';
import {
  ControlType,
  FieldDef,
  YesNoDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import * as CmsHelpers from '../cms-form-helper/cms-form-helpers';

@Injectable()
export class MarketingPreferencesFormConfigService {
  public getFormbuilderConfig(
    cmsFormData: CmsFormField[],
    prefilledForm?: MarketingPreferences | null
  ): FieldDef[] {
    return [
      this.getFieldDefByName(
        cmsFormData,
        MarketingPreferencesFormField.Email,
        prefilledForm?.email
      ),
      this.getFieldDefByName(
        cmsFormData,
        MarketingPreferencesFormField.Phone,
        prefilledForm?.phone
      ),
      this.getFieldDefByName(
        cmsFormData,
        MarketingPreferencesFormField.Post,
        prefilledForm?.post
      ),
    ] as FieldDef[];
  }

  private getFieldDefByName(
    cmsFormData: CmsFormField[],
    formField: MarketingPreferencesFormField,
    initialValue?: boolean | undefined
  ): FieldDef {
    let fieldDef: FieldDef;
    const cmsFormField = CmsHelpers.getFormField<CmsFormField>(
      cmsFormData,
      formField
    );
    const defaultFieldDef = CmsHelpers.getDefaultFieldDef(
      cmsFormField,
      formField
    );

    switch (formField) {
      case MarketingPreferencesFormField.Post:
      case MarketingPreferencesFormField.Email:
      case MarketingPreferencesFormField.Phone:
        fieldDef = {
          ...defaultFieldDef,
          controlType: ControlType.YESNO,
          validators: [Validators.required],
          options: cmsFormField.options
            ? cmsFormField.options
            : [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ],
          initialValue:
            typeof initialValue === 'boolean'
              ? initialValue
                ? 'Yes'
                : 'No'
              : '',
        } as YesNoDef;
        break;
      default:
        fieldDef = {
          ...defaultFieldDef,
        } as FieldDef;
        break;
    }

    return fieldDef;
  }
}
