import { Inject, Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { BuildConfigService, WINDOW } from '@common/util-foundation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { first, tap } from 'rxjs/operators';
import * as ResetStateActions from './reset-state.actions';

@Injectable()
export class RestartJourneyEffects {
  restart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ResetStateActions.restartCheckoutGuest,
          ResetStateActions.restartCheckoutLoggedInUser,
          ResetStateActions.restartCheckoutBackButton
        ),
        tap(() => {
          this.window.location.replace(this.applianceDetailsPageURL());
        })
      ),
    { dispatch: false }
  );

  sessionInactivityTimeout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResetStateActions.sessionInactivityTimeout),
        first(),
        tap(() => {
          this.window.location.replace(this.applianceDetailsPageURL());
        })
      ),
    { dispatch: false }
  );

  private applianceDetailsPageURL() {
    return /^(https?)?:\/\//.test(
      this.buildConfigService.config.applianceDetailsPage
    )
      ? this.buildConfigService.config.applianceDetailsPage
      : this.pl.getBaseHrefFromDOM().replace(/\/?$/, '/') +
          this.buildConfigService.config.applianceDetailsPage.replace(
            /^\//,
            ''
          );
  }

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly actions$: Actions,
    private readonly buildConfigService: BuildConfigService,
    private pl: PlatformLocation
  ) {}
}
