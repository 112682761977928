<div
  class="quote-summary-item"
  [ngClass]="{ 'quote-summary-item--multi': isMultiItem }"
>
  <div *ngIf="isMultiItem" class="quote-summary-item__header">
    <p class="quote-summary-item__title">
      {{ quote?.brandName }} {{ quote?.applianceName }}
    </p>
    <button
      *ngIf="multiItemDeleteEnabled"
      (click)="handleMultiItemDelete()"
      [disabled]="disabled"
      attr.aria-label="remove {{ quote?.brandName }} {{ quote?.applianceName }}"
      class="quote-summary-item__delete"
      type="button"
    >
      <span class="icon icon-close"></span>
    </button>
  </div>

  <!-- Selected Excess -->
  <div
    *ngIf="
      multipleExcessAvailable &&
      quote?.cover?.excessAmount !== null &&
      quote?.cover?.excessAmount !== undefined
    "
    class="quote-summary-item__group"
    data-test-hook="excess-level"
  >
    <p
      class="quote-summary-item__breakdown quote-summary-item__breakdown--bordered"
    >
      <span class="quote-summary-item__breakdown-title">
        {{ applianceIsWhiteGoods ? 'Excess per claim' : 'Call-out charge' }}
      </span>
      £{{ quote?.cover?.excessAmount | number: '1.2-2' }}
    </p>
  </div>

  <!-- Card Payment Breakdown -->
  <!-- Only shows for multi item -->
  <div
    *ngIf="selectedPaymentTypeIsCard && isMultiItem"
    class="quote-summary-item__group"
    data-test-hook="card-breakdown"
  >
    <p class="quote-summary-item__breakdown">
      <span class="quote-summary-item__breakdown-title">Single payment</span>
      £{{ cardPaymentOption?.fee || 0 | number: '1.2-2' }}
    </p>
  </div>

  <!-- Direct Debit / Term plan / Different Payments -->
  <div
    *ngIf="!selectedPaymentTypeIsCard && isFixedTerm && !isEqualPayments"
    class="quote-summary-item__group"
    data-test-hook="direct-debit-fixed-term-differing-breakdown"
  >
    <p class="quote-summary-item__breakdown">
      <span class="quote-summary-item__breakdown-title">
        First monthly payment
      </span>
      £{{ DDPaymentOption?.firstPayment || 0 | number: '1.2-2' }}
    </p>
    <p class="quote-summary-item__breakdown">
      <span class="quote-summary-item__breakdown-title">
        {{ DDPaymentOption?.numSubsequentPayments }} monthly payments
      </span>
      £{{ DDPaymentOption?.subsequentPayment || 0 | number: '1.2-2' }}
    </p>
    <p *ngIf="isMultiItem" class="quote-summary-item__breakdown">
      <span class="quote-summary-item__breakdown-title">
        Cost over
        {{ (DDPaymentOption?.numSubsequentPayments || 0) + 1 }} months
      </span>
      £{{ DDPaymentOption?.fee || 0 | number: '1.2-2' }}
    </p>
  </div>

  <!-- Direct Debit / Fixed Term plan / Equal Payments Breakdown (Only shows for single item)-->
  <!-- Only shows for multi item -->
  <div
    *ngIf="
      !selectedPaymentTypeIsCard &&
      isFixedTerm &&
      isEqualPayments &&
      isMultiItem
    "
    class="quote-summary-item__group"
    data-test-hook="direct-debit-fixed-term-same-breakdown"
  >
    <p class="quote-summary-item__breakdown">
      <span class="quote-summary-item__breakdown-title"> Monthly payment </span>
      £{{ DDPaymentOption?.subsequentPayment || 0 | number: '1.2-2' }}
    </p>
    <p class="quote-summary-item__breakdown">
      <span class="quote-summary-item__breakdown-title">
        Cost over
        {{ (DDPaymentOption?.numSubsequentPayments || 0) + 1 }} months
      </span>
      £{{ DDPaymentOption?.fee || 0 | number: '1.2-2' }}
    </p>
  </div>

  <!-- Direct Debit / Rolling term plan-->
  <!-- Only shows for multi item -->
  <div
    *ngIf="!selectedPaymentTypeIsCard && !isFixedTerm && isMultiItem"
    class="quote-summary-item__group"
    data-test-hook="direct-debit-rolling-term-breakdown"
  >
    <p class="quote-summary-item__breakdown">
      <span class="quote-summary-item__breakdown-title">Monthly payment</span>
      £{{ DDPaymentOption?.subsequentPayment || 0 | number: '1.2-2' }}
    </p>
  </div>
</div>
