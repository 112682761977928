import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BuildConfigService, ErrorService } from '@common/util-foundation';
import { ErrorPageData } from '@common/util-models';
import { map } from 'rxjs/operators';

@Component({
  selector: 'wl-error-404.component',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Error404Component {
  default404Error: ErrorPageData = {
    header: "Sorry, we can't find the page you're looking for.",
    additionalSupport: '',
    support:
      'Try going to our home page or contacting our friendly team for support.',
    contactCustomerService: '',
  };

  default404ErrorPageData$ = this.errorService.errorPageData$.pipe(
    map((errorPageCopy) => ({ ...this.default404Error, ...errorPageCopy }))
  );

  constructor(
    private errorService: ErrorService,
    private buildConfigService: BuildConfigService
  ) {}

  get contactUs(): string {
    return this.buildConfigService.config.error404.contactUs;
  }

  get home(): string {
    return this.buildConfigService.config.error404.home;
  }
}
