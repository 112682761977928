import { Action, createReducer, on } from '@ngrx/store';
import * as QueryParamsActions from './query-params.action';

export const QUERY_PARAMS_KEY = 'queryParams';

export type QueryParamsPartialState = {
  [QUERY_PARAMS_KEY]: QueryParamsState;
};

export interface QueryParamsState {
  awc: string | undefined;
  store: string | undefined;
}

const initialState: QueryParamsState = {
  awc: undefined,
  store: undefined,
};

const reducer = createReducer(
  initialState,

  on(QueryParamsActions.saveQueryParamsAWC, (state, { awc }) => ({
    ...state,
    awc,
  })),
  on(QueryParamsActions.saveQueryParamsStore, (state, { store }) => ({
    ...state,
    store,
  }))
);

export function queryParamsReducer(
  state: QueryParamsState | undefined,
  action: Action
): QueryParamsState {
  return reducer(state, action);
}
