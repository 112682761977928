import * as fs from 'fs';
import * as path from 'path';
import * as yup from 'yup';
import { AnySchema, StringSchema } from 'yup';
import { SchemaLike } from 'yup/lib/types';

const dgApiSchema = yup
  .string()
  .when('$prod', {
    is: true,
    then: (schema: StringSchema) =>
      schema.test({
        test: (v) => !v?.match(/domgentest.cloud/),
        message: '${path}: Should not be a test API',
      }),
  })
  .when(
    'csp',
    (
      csp: { apiUrlPattern: string; secondaryUrlPattern?: string },
      schema: SchemaLike
    ) => {
      if (!csp) {
        return (schema as StringSchema).matches(/^https?:\/\//);
      }
      const patterns = [
        ...(csp.apiUrlPattern ? [csp.apiUrlPattern] : []),
        ...(csp.secondaryUrlPattern ? [csp.secondaryUrlPattern] : []),
      ];
      const regex = patterns
        .map((pattern) => pattern.replace('*', '[A-Za-z0-9_\\.-]+'))
        .join('|');
      return (schema as StringSchema).matches(new RegExp(`^(${regex})`), {
        message: `\${path}: Should match CSP (${JSON.stringify(patterns)})`,
      });
    }
  );
const worldpayHelperUrlSchema = yup
  .string()
  .matches(/^https?:\/\//)
  .when('$prod', {
    is: true,
    then: (schema: StringSchema) =>
      schema.test({
        test: (v) => !v?.match(/domgentest.cloud/),
        message: '${path}: Should not be a test API',
      }),
  });

const uriSchema = yup.string().matches(/^https?:\/\//);
const cmsUriSchema = yup.string().when('$options.mockCms', {
  is: false,
  then: (schema) =>
    (schema as StringSchema).when(
      'csp',
      (csp: { cmsUrl: string }, schema: SchemaLike) => {
        if (!csp) {
          return (schema as StringSchema).matches(/^https?:\/\//);
        }
        return (schema as StringSchema).matches(
          new RegExp(`^(${csp.cmsUrl})`),
          {
            message: `\${path}: Should match CSP (${csp.cmsUrl})`,
          }
        );
      }
    ),
});
const cspSchema = yup.object({
  apiUrlPattern: uriSchema.required(),
  secondaryUrlPattern: uriSchema.optional(),
  cmsUrl: uriSchema.when('$options.mockCms', {
    is: false,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
  googleFloodlightId: yup.string().optional(),
  optimizelyId: yup.string().optional(),
  worldpayFrameUrl: yup
    .string()
    .when('$options.cardPayments', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    })
    .when('$prod', {
      is: true,
      then: (schema) => schema.equals(['https://payments.worldpay.com']),
      otherwise: (schema) =>
        schema.equals(['https://payments-test.worldpay.com']),
    }),
});

export const getConfigSchema = (
  options: ConfigOptions,
  managedCsp: boolean,
  env: string
) => {
  const props: Record<string, AnySchema> = {
    catalogueApi: dgApiSchema.required(),
    basketApi: dgApiSchema.required(),
    personApi: dgApiSchema.required(),
    common: dgApiSchema.required(),
    imeiApi: dgApiSchema[options.imeiApi ? 'required' : 'optional'](),
    //my account apis
    refreshAPI: dgApiSchema[options.authEnabled ? 'required' : 'optional'](),
    identityApiUrl: dgApiSchema[
      options.authEnabled ? 'required' : 'optional'
    ](),
    identityApiUrlVersion: yup
      .string()
      [options.authEnabled ? 'required' : 'optional']()
      .matches(/^v\d+$/),

    //todo: this is redundant/never used
    sales: dgApiSchema.optional(),
    //todo: what is this for? my account?
    domain: uriSchema.optional(),
    worldpayIframeHelperURL: worldpayHelperUrlSchema[
      options.cardPayments ? 'required' : 'optional'
    ](),
    recaptchaSiteKey: yup.string().required(),
    cookieProDomainScript: yup.string().required(),
    saleReporting: uriSchema.optional(),
    awinTrackingTestMode: yup.boolean().optional(),
    cmsRestUrlBase: cmsUriSchema.required(),
    cmsTemplateDefinitionBase: cmsUriSchema.required(),
    cmsContentAppBase: cmsUriSchema.required(),
    cmsDamRawUrl: cmsUriSchema.required(),
    mentionMeApi: uriSchema.optional(),
  };
  if (managedCsp && env !== 'local') {
    props.csp = cspSchema.required();
  }
  return yup.object(props).required().strict().noUnknown(true);
};

export interface ConfigOptions {
  cardPayments: boolean;
  authEnabled: boolean;
  mockCms: boolean;
  imeiApi?: boolean;
}

const configFiles = [
  'deployment/config.ci.json',
  'deployment/config.dev.json',
  'deployment/config.preprod.json',
  'deployment/config.prod.json',
  'deployment/config.sit.json',
  'deployment/config.test.json',
  'deployment/config.uat.json',
  'src/config.json',
];
const calculateEnvironmentForConfigFile = (configFilePath: string) => {
  const match = configFilePath.match(/config(?:\.([^.]+))\.json$/);
  return (match && match[1]) || 'local';
};

export function validateConfigFiles(
  projectRoot: string,
  options: ConfigOptions,
  deploySpec: any
) {
  const managedCsp = !!deploySpec?.cloudfront?.enabled;
  const configFilePaths = configFiles.map((file) => [
    file,
    path.resolve(path.join(projectRoot, file)),
  ]);

  describe.each(configFilePaths)('%s', (name, configFilePath) => {
    const exists = fs.existsSync(configFilePath);
    const env = calculateEnvironmentForConfigFile(configFilePath);
    const configSchema = getConfigSchema(options, managedCsp, env);
    it('should exist', () => {
      expect(exists).toBe(true);
    });
    if (!exists) {
      return;
    }

    const configValues = JSON.parse(
      fs.readFileSync(configFilePath, {
        encoding: 'utf-8',
      })
    );

    it('Should have valid schema', () => {
      try {
        const results = configSchema.validateSync(configValues, {
          abortEarly: false,
          strict: true,
          context: {
            env,
            prod: env === 'prod' || env === 'preprod',
            options,
          },
        });
        expect(results).toBeTruthy();
      } catch (err) {
        if (!err.errors) {
          throw err;
        }
        expect(err.errors).toEqual([]);
      }
    });
  });
}
