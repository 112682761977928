export interface WorldPayCardPaymentOptions {
  /**
   * The redirect URL that worldpay provided in response to a valid XML order. The URL is for the Hosted Payment Pages.
     For example: https://payments.worldpay.com/ngpp/integration/wpg/corporate?OrderKey=YOUR_ORDER_KEY&Ticket=YOUR_TICKET_ID
   * @type {string}
   * @memberof WorldPayCardPaymentOptions
   */
  url: string;

  /**
   * Describes the styling of the page.
   * @type {StyleCustomisation}
   * @memberof WorldPayCardPaymentOptions
   */
  styleCustomisation?: StyleCustomisation;

  /**
   * The URL of the helper library that you are hosting on your website
     For example: https://www.example.com/helper.html   *
   * @type {string}
   * @memberof WorldPayCardPaymentOptions
   */
  iframeHelperURL: string;
}

export enum WorldPayOrderStatus {
  Success = 'success',
  Cancelled = 'cancelled_by_shopper',
  AlreadyProcessed = 'already_processed',
  Pending = 'pending',
  Failure = 'failure',
  Error = 'error',
  Exception = 'exception',
  SessionExpired = 'session_expired',
}

export interface WorldPayResponse {
  order: {
    orderKey?: string;
    status: WorldPayOrderStatus;
  };
  error?: string;
  gateway: {
    [key: string]: unknown;
  };
}

export interface WorldPayCardPaymentApi {
  destroy(): void;
  setup(): void;
}
