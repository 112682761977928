<footer class="spacing-min-lg pb-4">
  <dgx-ui-wl-footer-navigation
    class="mb-2 md-mb-4 py-4"
    (clicked)="handleLinkClick($event)"
    [protectLinks]="protectLinks"
    [moreLinks]="moreLinks"
    [usefulLinks]="usefulLinks"
    [legalLinks]="legalLinks"
  ></dgx-ui-wl-footer-navigation>

  <div class="container">
    <div class="xs-12">
      <dgx-ui-wl-footer-social
        class="mb-2"
        [socialLinks]="socialLinks"
      ></dgx-ui-wl-footer-social>

      <dgx-ui-wl-footer-legal [copyrightOwner]="copyrightOwner">
        <ng-content></ng-content>
      </dgx-ui-wl-footer-legal>
    </div>
  </div>
</footer>
