<div class="checkout__users flex align--center">
  <ng-container
    *ngIf="
      personalDetails && personalDetails?.email !== '';
      then isUserLoggedIn;
      else isUserGuest
    "
  ></ng-container>
  <ng-template #isUserLoggedIn>
    <div class="align--center">
      <h1>{{ cmsData?.registeredCheckout?.bannerTitle }}</h1>
      <p class="description">{{ cmsData?.registeredCheckout?.bannerText }}</p>
    </div>
  </ng-template>
  <ng-template #isUserGuest>
    <div class="align--center">
      <h1>{{ cmsData?.guestCheckout?.bannerTitle }}</h1>
      <p class="description">{{ cmsData?.guestCheckout?.bannerText }}</p>
    </div>
  </ng-template>
</div>
