import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { YourDetails } from '@common/util-models';
import { CheckoutCmsData } from '@common/util-models';

@Component({
  selector: 'sales-checkout-safe-fast-simple',
  templateUrl: './checkout-safe-fast-simple.component.html',
  styleUrls: ['./checkout-safe-fast-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutSafeFastSimpleComponent {
  @Input() personalDetails!: YourDetails | null;
  @Input() cmsData!: CheckoutCmsData | null;
}
