import { Appliance } from '@common/util-models';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { getApplianceDetailsState } from '../appliance-details.selectors';
import { ApplianceDetailsState } from '../appliance-details.reducer';
import { appliancesAdapter, AppliancesState } from './appliances.reducer';

const {
  selectAll: selectAppliances,
  selectEntities: selectApplianceEntities,
} = appliancesAdapter.getSelectors();

export const getAppliancesState = createSelector(
  getApplianceDetailsState,
  (state: ApplianceDetailsState) => state?.appliances
);

export const getAppliancesLoaded = createSelector(
  getAppliancesState,
  (state: AppliancesState) => state.remoteState
);

export const getAppliances = createSelector(
  getAppliancesState,
  (state: AppliancesState): Appliance[] => selectAppliances(state)
);

export const getSelectedAppliance = createSelector(
  getAppliancesState,
  (state: AppliancesState): Appliance | undefined => state?.selectedAppliance
);

export const getApplianceEntities = createSelector(
  getAppliancesState,
  (state: AppliancesState): Dictionary<Appliance> =>
    selectApplianceEntities(state)
);
