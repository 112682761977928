import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'dgx-dfb-accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptComponent extends CheckboxComponent {}
