import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RadioListItemValue } from '@domgen/dgx-fe-components-core';
import { CoverPaymentOptions, PaymentType } from '@common/util-models';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'wl-payment-option-form',
  templateUrl: './payment-option-form.component.html',
  styleUrls: ['./payment-option-form.component.scss'],
})
export class PaymentOptionFormComponent implements OnChanges {
  @Input() paymentOptions: CoverPaymentOptions | null = null;
  @Input() initialSelection: PaymentType | undefined;
  @Output() form = new EventEmitter<FormGroup>();
  @Output() selectedPaymentType = new EventEmitter<PaymentType>();
  @Output() analytics = this.selectedPaymentType.pipe(
    map(
      (paymentType): AnalyticsData => ({
        controlName: 'payment-method',
        value: paymentType,
        error: '',
      })
    )
  );
  public formGroup: FormGroup;
  private currentValue: PaymentType | undefined;
  /**
   * Analytics data for the form
   */

  selectedPaymentMethod: RadioListItemValue = '';

  constructor() {
    this.formGroup = new FormGroup({
      paymentType: new FormControl(this.getInitialValue(), Validators.required),
    });
    this.formGroup.valueChanges
      .pipe(
        map((change) => change?.paymentType),
        distinctUntilChanged()
      )
      .subscribe((paymentType) => {
        this.selectedPaymentType.emit(paymentType);
        this.form.emit(this.formGroup);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    /**
     *  - if paymentOptions change
     *      and there is a selection and the selection is not available
     *      or there is no selection
     *        then we need to change the selectedValue to (initialValue|preferred)
     *  - if initialSelection changes
     *      then we need to change the selectedValue to (initialValue)
     */
    if (changes.paymentOptions && this.paymentOptions) {
      const currentSelection =
        this.currentValue &&
        Object.values(this.paymentOptions).find(
          (e) => e.paymentType === this.currentValue
        );
      if (!currentSelection) {
        this.resetSelection();
      }
    }
    if (changes.initialSelection && this.paymentOptions) {
      this.resetSelection();
    }
  }

  private resetSelection() {
    this.formGroup.setValue({ paymentType: this.getInitialValue() });
  }

  private getInitialValue() {
    if (this.initialSelection) {
      return this.initialSelection;
    }
    const preferred = this.paymentOptions
      ? Object.values(this.paymentOptions).find((e) => e.preferredPayment)
      : null;
    return preferred?.paymentType;
  }
}
