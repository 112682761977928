<div class="faq" [class.expanded]="isOpen" [class.isLast]="isLast">
  <div class="faq__question" (click)="isOpen = !isOpen">
    <div>
      <strong>{{ question }}</strong>
    </div>
    <div class="faq__icon">
      <em class="icon icon--md icon-arrow-{{ isOpen ? 'up' : 'down' }}"></em>
    </div>
  </div>
  <div
    class="faq__answer"
    [@toggleExpanded]="isOpen ? 'expanded' : 'collapsed'"
    [innerHTML]="answer"
  ></div>
</div>
