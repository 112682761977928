import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromCheckoutBasket from './+state/checkout-basket.reducer';
import { CheckoutBasketFacade } from './+state/checkout-basket.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCheckoutBasket.CHECKOUT_BASKET_FEATURE_KEY,
      fromCheckoutBasket.reducer
    ),
  ],
  providers: [
    CheckoutBasketFacade,
  ],
})
export class CommonDataAccessCheckoutBasketModule {}
