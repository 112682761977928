/* istanbul ignore file */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedAoComponentsModule } from './ao';
import { FooterModule } from './footer';
import { HeaderModule } from './header';
import { SharedSkyComponentsModule } from './sky';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    SharedAoComponentsModule,
    SharedSkyComponentsModule,
  ],
})
export class SharedUiWhitelabelComponentsModule {}
