import { AbstractControl } from '@angular/forms';
import {
  isObservable,
  MonoTypeOperatorFunction,
  Observable,
  of,
  pipe,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  disableFormControls,
  enableFormControls,
  getAllDisabledFormControls,
  getAllFormControls,
} from '../../utils';

export function enableControl<T extends AbstractControl | null | undefined>(
  control: T | Observable<T>
): MonoTypeOperatorFunction<boolean> {
  const control$: Observable<T> = isObservable(control) ? control : of(control);
  let existingDisabledControls: AbstractControl[] = [];

  return pipe(
    distinctUntilChanged(),
    switchMap((enabled: boolean) =>
      control$.pipe(
        filter((control: T) => !!control && control.enabled !== enabled),
        tap((control: T) => {
          const controls = getAllFormControls(control);

          if (enabled) {
            enableFormControls(...controls);
            disableFormControls(...existingDisabledControls);
          } else {
            existingDisabledControls = getAllDisabledFormControls(control);
            disableFormControls(...controls);
          }
        }),
        map(() => enabled)
      )
    )
  );
}
