import { createAction, props } from '@ngrx/store';
import { CheckoutBasket } from '@common/util-models';

export const setBasket = createAction(
  '[CheckoutBasket] Set Checkout Basket',
  props<{ basket: CheckoutBasket }>()
);

export const checkoutAlreadyLoggedIn = createAction(
  '[CheckoutBasket] Proceed to Checkout - already logged in'
);

export const checkoutNotLoggedInYet = createAction(
  '[CheckoutBasket] Proceed to Checkout - not logged in yet'
);

export const proceedToCheckout = createAction(
  '[CheckoutBasket] Proceed to Checkout'
);
