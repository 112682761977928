import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { toggleExpandedAnimation } from './animations';

@Component({
  selector: 'sales-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleExpandedAnimation],
})
export class FaqComponent {
  isOpen = false;

  @Input()
  question!: string;

  @Input()
  answer!: string;

  @Input()
  isLast!: boolean;
}
