<div class="tooltip">
  <button
    [attr.aria-label]="tooltipName"
    [dgxDfbTooltip]="tooltipTemplate"
    [tooltipName]="tooltipName"
    class="tooltip__trigger"
    type="button"
  >
    <i class="icon icon-more-info-fill tooltip__icon">
      <span class="path1 tooltip__icon-content"></span>
      <span class="path2 tooltip__icon-content"></span>
      <span class="path3 tooltip__icon-content"></span>
    </i>
  </button>
  <ng-template #tooltipTemplate>
    <div
      [@slideInOut]
      [innerHTML]="tooltip"
      class="tooltip__content"
      role="tooltip"
    ></div>
  </ng-template>
</div>
