import { CheckoutPageCmsData } from '@common/util-models';

export const CHECKOUT = {
  results: [
    {
      '@name': 'new-user',
      '@path': '/sales-checkout/new-user',
      '@id': '0cacb653-2e5f-4cec-8589-4882b311a8f4',
      '@nodeType': 'dgx-checkout-banner',
      '@nodes': [],
      jcrName: 'new-user',
      bannerTitle: 'Checkout',
      bannerText: 'Safe, fast and simple',
      title: 'New to Domestic & General?',
      subtitle: 'Guest checkout',
      description:
        'Create an account after checkout for faster access next time',
      buttonText: 'Checkout as guest',
      hint: '',
    },
    {
      '@name': 'already-registered',
      '@path': '/sales-checkout/already-registered',
      '@id': '0b554ca3-28d7-4473-ac8a-7516d423395f',
      '@nodeType': 'dgx-checkout-banner',
      '@nodes': [],
      jcrName: 'already-registered',
      bannerTitle: 'Existing customer',
      bannerText: 'Easier and faster to register and pay when logged in',
      title: 'Already registered?',
      subtitle: 'Existing customer checkout',
      description: 'Easier and faster to register and pay when logged in',
    },
  ],
} as CheckoutPageCmsData;
