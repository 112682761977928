import { WrappedValidate } from '@domgen/dgx-fe-business-models';

export function getWrappedValidate(validate: boolean): WrappedValidate {
  return { validate };
}

export interface WrappedInvalidPaymentDetails {
  invalidPaymentDetails: boolean;
}

export function getWrappedInvalidPaymentDetails(
  invalidPaymentDetails: boolean
): WrappedInvalidPaymentDetails {
  return { invalidPaymentDetails };
}
