export const CHECKOUT_PERSONAL_DETAILS_FORM = {
  results: [
    {
      formName: 'checkoutPersonalDetailsForm',
      label: 'Title',
      controlName: 'title',
      placeholder: 'Select',
      options: ['Mr', 'Mrs', 'Ms', 'Dr'],
      jcrName: 'sales_checkoutPersonalDetailsForm',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Enter title',
        },
      },
    },
    {
      '@name': 'sales_checkoutPersonalDetailsForm_title',
      '@path': '/sales_checkoutPersonalDetailsForm_title',
      '@id': '680986ef-2499-49fd-a803-f7e20919d7c5',
      '@nodeType': 'form',
      formName: 'checkoutPersonalDetailsForm',
      label: 'First name',
      controlName: 'firstName',
      placeholder: 'Name',
      jcrName: 'sales_checkoutPersonalDetailsForm_brand',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path': '/sales_checkoutPersonalDetailsForm_brand/validationMessages',
        '@id': '210e89b5-8072-4756-aed3-216dba309b80',
        '@nodeType': 'mgnl:contentNode',
        '0': {
          '@name': '0',
          type: 'required',
          message: 'Enter first name',
        },
        '1': {
          '@name': '0',
          type: 'minlength',
          message: 'First name must be more than 1 character',
        },
        '2': {
          '@name': '0',
          type: 'maxlength',
          message: 'Max length should only be 15 characters',
        },
        '3': {
          '@name': '0',
          type: 'invalidName',
          message: 'Invalid format for first name',
        },
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'sales_checkoutPersonalDetailsForm_underGuarantee',
      '@path': '/sales_checkoutPersonalDetailsForm_underGuarantee',
      '@id': '03ff75e9-260f-43fe-a85e-52dd2464dcd0',
      '@nodeType': 'form',
      formName: 'checkoutPersonalDetailsForm',
      label: 'Last name',
      controlName: 'lastName',
      placeholder: 'Last name',
      hint: '',
      jcrName: 'sales_checkoutPersonalDetailsForm_underGuarantee',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path':
          '/sales_checkoutPersonalDetailsForm_underGuarantee/validationMessages',
        '@id': '5ba7018f-d941-4b94-8ea0-2e18f1140881',
        '@nodeType': 'mgnl:contentNode',
        '0': {
          '@name': '0',
          type: 'required',
          message: 'Enter last name',
        },
        '1': {
          '@name': '0',
          type: 'minlength',
          message: 'First name must be more than 1 character',
        },
        '2': {
          '@name': '0',
          type: 'maxlength',
          message: 'Max length should only be 20 characters',
        },
        '3': {
          '@name': '0',
          type: 'invalidName',
          message: 'Invalid format for last name',
        },
      },
      '@nodes': ['validationMessages'],
    },
    {
      '@name': 'sales_checkoutPersonalDetailsForm_goodWorkingOrder',
      '@path': '/sales_checkoutPersonalDetailsForm_goodWorkingOrder',
      '@id': 'b9990871-db6c-4dd7-8f05-c1a06983cc74',
      '@nodeType': 'form',
      formName: 'checkoutPersonalDetailsForm',
      label: 'Email',
      controlName: 'email',
      tooltip:
        'We use your email to send confirmation email. Please ensure you provide valid email address.',
      hint: '',
      jcrName: 'sales_checkoutPersonalDetailsForm_goodWorkingOrder',
      application: 'sales',
      placeholder: 'Your email',
      validationMessages: {
        '@name': 'validationMessages',
        '@path':
          '/sales_checkoutPersonalDetailsForm_goodWorkingOrder/validationMessages',
        '@id': '9b95801d-0bd0-495e-a128-f9f021691d7f',
        '0': {
          '@name': '0',
          type: 'required',
          message: 'Enter email address',
        },
        '1': {
          '@name': '1',
          type: 'email',
          message: 'Please provide valid email address',
        },
      },
    },
    {
      formName: 'checkoutPersonalDetailsForm',
      label: 'Enter mobile number',
      hint: "We'll get in touch if we need to arrange repairs or replacements.",
      controlName: 'mobileNumber',
      jcrName: 'sales_checkoutPersonalDetailsForm_purchaseDate',
      application: 'sales',
      placeholder: 'Your mobile number',
      tooltip:
        'We want to reassure you that we use your information both responsibly and securely to provide you with the best possible service. You can provide your mobile number.',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Enter mobile number',
        },
        '1': {
          type: 'invalidMobileNumber',
          message: 'Mobile number is not valid',
        },
        '2': {
          type: 'minlength',
          message: 'Min length should be 7',
        },
        '3': {
          type: 'maxlength',
          message: 'Max length should be 11',
        },
      },
    },
    {
      '@name': 'sales_checkoutPersonalDetailsForm_address',
      '@path': '/sales_checkoutPersonalDetailsForm_address',
      '@id': '680986ef-2499-49fd-a803-f7e20919d7c5',
      '@nodeType': 'form',
      formName: 'checkoutPersonalDetailsForm',
      label: 'Search for your address',
      controlName: 'address',
      placeholder: 'Start typing your address or postcode',
      addressLine1Label: 'Home address line 1',
      addressLine1Placeholder: 'Address line 1',
      addressLine2Label: 'Home address line 2',
      addressLine2Placeholder: 'Address line 2',
      cityLabel: 'Town/City',
      cityPlaceholder: 'Town/City',
      countyLabel: 'County',
      countyPlaceholder: 'County',
      postcodeLabel: 'Postcode',
      postcodePlaceholder: 'Postcode',
      jcrName: 'sales_checkoutPersonalDetailsForm_address',
      application: 'sales',
      validationMessages: {
        '@name': 'validationMessages',
        '@path': '/sales_applianceForm_brand/validationMessages',
        '@id': '210e89b5-8072-4756-aed3-216dba309b80',
        '@nodeType': 'mgnl:contentNode',
        '0': {
          '@name': '0',
          '@path':
            '/sales_checkoutPersonalDetailsForm_address/validationMessages/0',
          '@id': '9e59dc46-f609-4f6e-9328-a73ac5393ebb',
          '@nodeType': 'mgnl:contentNode',
          type: 'required',
          message: 'Please provide your home address or postcode',
          '@nodes': [],
        },
        '1': {
          '@name': '1',
          '@path':
            '/sales_checkoutPersonalDetailsForm_address/validationMessages/1',
          '@id': '9e59dc46-f609-4f6e-9328-a73ac5393ebb',
          '@nodeType': 'mgnl:contentNode',
          type: 'invalidForm',
          message: 'Please provide your home address or postcode',
          '@nodes': [],
        },
        '2': {
          '@name': '2',
          '@path':
            '/sales_checkoutPersonalDetailsForm_address/validationMessages/2',
          '@id': '9e59dc46-f609-4f6e-9328-a73ac5393ebb',
          '@nodeType': 'mgnl:contentNode',
          type: 'invalidResidentialAddress',
          message:
            'This appears to be a commercial address. Please provide a residential address to continue.',
          '@nodes': [],
        },
        '@nodes': ['0', '1', '2'],
      },
      '@nodes': ['validationMessages'],
    },

    {
      formName: 'checkoutPersonalDetailsForm',
      label:
        'Would you also like to receive a copy of your policy in the post?',
      placeholder: 'None',
      tooltip: '',
      controlName: 'paperless',
      hint: '',
      message:
        'Going paperless means there’s less post to deal with, file or recycle.',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      jcrName: 'sales_directDebitForm_paperless',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Please select no or yes',
          '@nodes': [],
        },
      },
    },

    {
      formName: 'checkoutPersonalDetailsForm',
      label: '',
      tooltip: '',
      controlName: 'termsAndConditions',
      hint: '',
      jcrName: 'sales_directDebitForm_termsAndConditions',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Please confirm that you have read and agree',
        },
      },
    },

    {
      formName: 'checkoutPersonalDetailsForm',
      label: 'Email',
      placeholder: '',
      tooltip: '',
      controlName: 'email',
      hint: '',
      message: '',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      jcrName: 'sales_marketing_pref_email',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Please indicate if you would like to be contacted by email',
        },
      },
    },

    {
      formName: 'checkoutPersonalDetailsForm',
      label: 'Phone',
      placeholder: '',
      tooltip: '',
      controlName: 'phone',
      hint: '',
      message: '',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      jcrName: 'sales_marketing_pref_phone',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Please indicate if you would like to be contacted by phone',
        },
      },
    },

    {
      formName: 'checkoutPersonalDetailsForm',
      label: 'Post',
      placeholder: '',
      tooltip: '',
      controlName: 'post',
      hint: '',
      message: '',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      jcrName: 'sales_marketing_pref_post',
      application: 'sales',
      validationMessages: {
        '0': {
          type: 'required',
          message: 'Please indicate if you would like to be contacted by post',
        },
      },
    },
  ],
};
