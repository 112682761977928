import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { DateWithOrdinalPipe } from '@common/util-foundation';
import { DirectDebit } from '@common/util-models';
import { detailData } from '@domgen/dgx-fe-components-core';

@Component({
  selector: 'wl-payment-details',
  templateUrl: './payment-details.component.html',
  providers: [DateWithOrdinalPipe, DecimalPipe],
})
export class PaymentDetailsComponent implements OnChanges {
  @Input() paymentDetails: DirectDebit | null | undefined;
  @Input() monthlyCost: number | null | undefined = 0;
  @Input() isInsurance: boolean | null = false;
  @Input() totalPaymentTimeInMonth = 12;

  constructor(
    private _decimalPipe: DecimalPipe,
    private datePipe: DateWithOrdinalPipe
  ) {}
  detail: detailData[] = [];

  ngOnChanges() {
    if (this.paymentDetails) {
      this.mapToDetailArray(this.paymentDetails);
    }
  }

  mapToDetailArray(personalDetail: DirectDebit) {
    this.detail = [
      {
        key: 'Name of account holder',
        value: personalDetail.accountName
          ? personalDetail.accountName.trim()
          : '',
      },
      {
        key: 'Bank/Building Society account number',
        value: personalDetail.accountNumber
          ? personalDetail.accountNumber.trim()
          : '',
      },
      {
        key: 'Branch sort code',
        value: personalDetail.sortCode
          ? this.formatSortCode(personalDetail.sortCode)
          : '',
      },
      {
        key: 'Amount to be debited',
        value: `£${this._decimalPipe.transform(
          this.monthlyCost || 0,
          '1.2-2'
        )}`,
      },
    ];

    if (personalDetail?.monthlyPaymentDay) {
      const day = this.getDaySuffix(personalDetail.monthlyPaymentDay);
      const company = this.isInsurance
        ? ' Domestic & General Insurance PLC'
        : 'Domestic & General Services Limited';
      const monthlyDay = {
        key: 'Monthly payment date',
        value: `The ${day} of every month for a total of ${this.totalPaymentTimeInMonth} months to ${company}.<br/><br/>If your chosen payment date is within the next 14 days, your first and second payments will be taken at the same time next month.`,
      };
      this.detail.push(monthlyDay);
    }
  }

  getDaySuffix(day: number) {
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), day);
    return this.datePipe.transform(date, 'do');
  }

  formatSortCode(code: string) {
    const sortCode =
      code.substring(0, 2) +
      '-' +
      code.substring(2, 4) +
      '-' +
      code.substring(4, 6);
    return sortCode;
  }
}
