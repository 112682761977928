import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AnalyticsData,
  CmsFormField,
  PlanDocumentsFormField,
  WrappedValidate,
} from '@domgen/dgx-fe-business-models';
import { Observable } from 'rxjs';
import { PlanDocumentsFormConfigService } from './plan-documents-form-config.service';
import {
  PlanDocumentsFormStateService,
  ViewModel,
} from './plan-documents-form-state.service';

@Component({
  selector: 'dgx-uib-plan-documents',
  templateUrl: './plan-documents.component.html',
  styleUrls: ['./plan-documents.component.scss'],
  providers: [PlanDocumentsFormStateService, PlanDocumentsFormConfigService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDocumentsComponent implements OnInit {
  @Input() initialValue?: Partial<Record<PlanDocumentsFormField, boolean>>;
  @Input() isHeating!: boolean | null;
  @Input() infoText!: string | null;

  @Input() set cmsFormData(value: CmsFormField[] | null) {
    if (value) {
      this.stateService.setCmsFormData(value);
    }
  }

  @Input() set stateFormData(value: boolean | null) {
    if (typeof value === 'boolean') {
      this.stateService.setPrefilledFormData(value);
    }
  }

  @Input() set validate(validate: WrappedValidate | null) {
    if (validate?.validate) {
      this.stateService.validate();
    }
  }

  @Output() form: Observable<FormGroup> = this.stateService.formGroup$;

  @Output() valueChanges: Observable<
    Record<PlanDocumentsFormField, boolean>
  > = this.stateService.valueChanges$;

  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  readonly vm$: Observable<ViewModel> = this.stateService.vm$;

  constructor(private readonly stateService: PlanDocumentsFormStateService) {}

  ngOnInit(): void {
    if (this.initialValue) {
      this.stateService.patchFormValue(this.initialValue);
    }
  }

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }
}
