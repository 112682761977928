import { CmsFormField } from '@domgen/dgx-fe-business-models';

export enum LoggedInUserFormField {
  Name = 'name',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Address = 'address',
  AccountName = 'accountName',
  AccountNumber = 'accountNumber',
  SortCode = 'sortCode',
  MonthlyPaymentDay = 'monthlyPaymentDay',
  Paperless = 'paperless',
  TermsAndConditions = 'termsAndConditions',
  Submit = 'submit',
}

export type LoggedInUserFormFieldTypes =
  | CmsFormField<LoggedInUserFormField.Name>
  | CmsFormField<LoggedInUserFormField.Email>
  | CmsFormField<LoggedInUserFormField.PhoneNumber>
  | CmsFormField<LoggedInUserFormField.Address>
  | CmsFormField<LoggedInUserFormField.AccountName>
  | CmsFormField<LoggedInUserFormField.AccountNumber>
  | CmsFormField<LoggedInUserFormField.SortCode>
  | CmsFormField<LoggedInUserFormField.MonthlyPaymentDay>
  | CmsFormField<LoggedInUserFormField.Paperless>
  | CmsFormField<LoggedInUserFormField.TermsAndConditions>
  | CmsFormField<LoggedInUserFormField.Submit>;

export type LoggedInUserFormFields = Array<LoggedInUserFormFieldTypes>;
