<div class="payments-icons">
  <span
    *ngIf="showDirectDebitIcon"
    class="logo icon-direct-debit"
    title="Direct Debit"
  ></span>
  <ng-container *ngIf="showCardIcons">
    <span class="logo icon-card-issuer-visa" title="Visa Card"></span>
    <span
      class="logo icon-card-issuer-visa-debit"
      title="Visa Debit Card"
    ></span>
    <span class="logo icon-card-issuer-master-card" title="Master Card"></span>
    <span class="logo icon-card-issuer-maestro" title="Maestro Card"></span>
  </ng-container>
</div>
