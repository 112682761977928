<div class="need-to-know">
  <h3 class="sub-heading">Things you need to know</h3>
  <p>
    This insurance policy is designed to meet the demands and needs of those who
    wish to insure their electronic appliances and/or household goods against
    breakdown and accidental damage. Full details on how to submit a complaint
    can be found in the
    <a
      class="link"
      target="_blank"
      [href]="termsAndConditionsDoc?.link"
      title="{{ termsAndConditionsDoc?.name }}"
      rel="noreferrer"
      download
      >{{ termsAndConditionsDoc?.name?.trim() }}</a
    >.
  </p>
</div>
