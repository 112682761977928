import { inject, InjectionToken } from '@angular/core';
import { Dictionary, GoogleTagManager } from '@common/util-models';
import { WINDOW } from './window.token';
import { Observable } from 'rxjs';

export const GOOGLE_TAG_MANAGER = new InjectionToken<GoogleTagManager>(
  'GoogleTagManager',
  {
    providedIn: 'root',
    factory: (): GoogleTagManager => {
      return (
        (inject(WINDOW) as { dataLayer?: GoogleTagManager })?.dataLayer || {
          push: () => console.warn('window.dataLayer is not defined.'),
        }
      );
    },
  }
);

export const GA_TAGS = new InjectionToken<Dictionary<string>>(
  'Google Tags Token'
);

export const GOOGLE_TAG_GLOBAL_PROPERTIES = new InjectionToken<
  Dictionary<string> | Observable<Dictionary<string>>
>('Google Tags Global Properties');
