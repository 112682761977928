<div class="form-box">
  <div class="form-box__wrapper">
    <h3>Would you like to stay in touch?</h3>
    <p class="info">
      We'd love to send you updates with the latest news and offers from time to
      time, always in line with our
      <a [href]="privacyPolicyLink" target="_blank" class="link">
        Privacy Policy </a
      >. If you're happy for us to do this, select the ways you’d like to stay
      in touch.
    </p>

    <ng-container *ngIf="vm$ | async as vm">
      <div class="form-panel">
        <dgx-dfb-form
          [fieldset]="vm.fieldDef"
          [form]="vm.formGroup"
          [validate]="vm.validate"
          (analytics)="handleAnalytics($event)"
        >
        </dgx-dfb-form>
      </div>
    </ng-container>
  </div>
</div>
