import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { RedeyeTags, RedeyeTagsFiltered } from '@common/util-models';
import { of, OperatorFunction, pipe, Subject } from 'rxjs';
import { catchError, map, mapTo, mergeMap, takeUntil } from 'rxjs/operators';
import { filterNullUndefined } from '../../operators/filter-null-undefined/filter-null-undefined';
import { BuildConfigService } from '../build-config/build.config.service';

@Injectable({
  providedIn: 'root',
})
export class RedeyeTaggingService implements OnDestroy {
  private tagsSubject = new Subject<RedeyeTags>();
  private tags$ = this.tagsSubject.asObservable();

  private readonly destroySubject = new Subject<void>();
  readonly destroy$ = this.destroySubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService
  ) {
    this.pushToRedeye();
  }

  tag(tags: RedeyeTags[]) {
    tags.forEach((tag) => {
      this.tagsSubject.next(tag);
    });
  }

  private pushToRedeye() {
    this.tags$
      .pipe(
        this.filterNullUndefinedTags(),
        mergeMap((tags) => this.sendToRedeyeRequest(tags)), // Calls can be done in parallel.
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (tag) => {
          console.log('redeye::', tag);
        },
      });
  }

  private filterNullUndefinedTags(): OperatorFunction<
    RedeyeTags,
    RedeyeTagsFiltered
  > {
    return pipe(
      map((tags) =>
        Object.keys(tags).reduce(
          (prev: null | RedeyeTagsFiltered, current: string) =>
            tags[current] !== null && tags[current] !== undefined
              ? ({
                  ...(prev || {}),
                  [current]: tags[current],
                } as RedeyeTagsFiltered)
              : prev,
          null
        )
      ),
      filterNullUndefined()
    );
  }

  private sendToRedeyeRequest(tags: RedeyeTagsFiltered) {
    return this.httpClient
      .get(`${this.buildConfigService.config.saleReporting}`, {
        params: tags,
        responseType: 'text',
        withCredentials: true,
      })
      .pipe(
        mapTo(tags),
        catchError(() => of(tags))
      );
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }
}
