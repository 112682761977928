import { createReducer, on, Action } from '@ngrx/store';

import * as CheckoutBasketActions from './checkout-basket.actions';
import {
  CheckoutBasket,
  initialBasket,
} from '@common/util-models';

export const CHECKOUT_BASKET_FEATURE_KEY = 'checkoutBasket';

export interface CheckoutBasketPartialState {
  readonly [CHECKOUT_BASKET_FEATURE_KEY]: CheckoutBasket;
}

export const initialState: CheckoutBasket = initialBasket();

const checkoutBasketReducer = createReducer(
  initialState,

  on(CheckoutBasketActions.setBasket, (state, { basket }) => {
    return {
      ...state,
      ...basket,
    };
  })
);

export function reducer(state: CheckoutBasket | undefined, action: Action) {
  return checkoutBasketReducer(state, action);
}
