import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'wl-payment-icons',
  templateUrl: './payment-icons.component.html',
  styleUrls: ['./payment-icons.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentIconsComponent {
  @Input() showDirectDebitIcon?: boolean;
  @Input() showCardIcons?: boolean;
}
