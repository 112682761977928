import { Component } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';

@Component({
  selector: 'wl-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  constructor(public buildConfig: BuildConfigService) {}
  handleBackToHome() {
    if (this.buildConfig?.config?.backToHomeButton?.href) {
      window.location.replace(this.buildConfig.config.backToHomeButton.href);
    }
  }
}
