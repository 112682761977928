import {
  ChangeDetectionStrategy,
  EventEmitter,
  Component,
  Input,
  Output,
} from '@angular/core';
import { CheckoutCmsData } from '@common/util-models';

@Component({
  selector: 'sales-checkout-as-guest',
  templateUrl: './checkout-as-guest.component.html',
  styleUrls: ['./checkout-as-guest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutAsGuestComponent {
  @Input() cmsData!: CheckoutCmsData | null;

  @Output() checkout = new EventEmitter();

  handleClick() {
    this.checkout.emit();
  }
}
