import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

import {
  LoggedInUserFormField,
  LoggedInUserFormFields,
  PaymentDetails,
} from '@common/util-models';
import { ControlType, SelectDef } from '@domgen/dgx-fe-dynamic-form-builder';
import * as CmsHelpers from '@domgen/dgx-fe-business-components';
import { CmsFormField } from '@domgen/dgx-fe-business-models';

export const DEFAULT_PAYMENT_DAY = 1;
@Injectable()
export class SavedDirectDebitDetailsFormConfigService {
  getReadonlyFormFormbuilderConfig(
    cmsFormData: LoggedInUserFormFields,
    paymentDetails: PaymentDetails
  ) {
    return [this.getPaymentDayDef(cmsFormData, paymentDetails)];
  }

  private getPaymentDayDef(
    cmsFormData: LoggedInUserFormFields,
    paymentDetails: PaymentDetails
  ) {
    const cmsFormField = CmsHelpers.getFormField<CmsFormField>(
      cmsFormData,
      LoggedInUserFormField.MonthlyPaymentDay
    );

    const defaultFieldDef = CmsHelpers.getDefaultFieldDef(
      cmsFormField,
      LoggedInUserFormField.MonthlyPaymentDay
    );

    return {
      ...defaultFieldDef,
      controlType: ControlType.SELECT,
      validators: [Validators.required],
      options: this.dayOfMonth(),
      initialValue: String(
        paymentDetails.paymentDay || String(DEFAULT_PAYMENT_DAY)
      ),
      fitContent: true,
    } as SelectDef;
  }

  private dayOfMonth(maxDay: number = 28): string[] {
    const days = [];
    for (let i = 1; i <= maxDay; i++) {
      days.push(`${i}`);
    }

    return days;
  }
}
