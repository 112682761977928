<ui-banner>
  <div>
    <span>{{ desc }}&nbsp;</span>
    <span
      ><a
        href="#"
        (click)="handleLinkClick($event)"
        target="_blank"
        class="link"
        >{{ linkText }}</a
      ></span
    >.
  </div>
</ui-banner>
