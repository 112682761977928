export function deleteFromObj<T, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Partial<T> {
  return Object.keys(obj).reduce((result: Partial<T>, _key: string) => {
    const key: K = _key as K;
    if (keys.includes(key)) {
      return result;
    }
    return {
      ...result,
      [key]: obj[key],
    };
  }, {});
}

export function lowercaseObjKeys<T, K extends keyof T>(
  obj: T
): { [key: string]: unknown } {
  return Object.keys(obj).reduce(
    (result: { [key: string]: unknown }, _key: string) => {
      const key: K = _key as K;
      return {
        ...result,
        [key.toString().toLowerCase()]: obj[key],
      };
    },
    {}
  );
}
