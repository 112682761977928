import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { SubmitDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormStatus } from '../../_shared/interfaces/angular-forms-typed.interface';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'dgx-dfb-submit-button',
  templateUrl: './submit-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitButtonComponent implements OnInit {
  @Input() field!: SubmitDef;
  disabled$ = new BehaviorSubject(false);

  constructor(protected formGroupDir: FormGroupDirective) {}

  ngOnInit() {
    if (this.field?.disabled) {
      const formStatus = this.formGroupDir?.form?.status;
      this.disabled$.next(formStatus !== 'VALID');

      // stay disabled until form valid
      this.formGroupDir.form.statusChanges
        .pipe(
          untilDestroyed(this),
          tap((status: FormStatus) => {
            if (status === 'VALID' && this.disabled$.value) {
              this.disabled$.next(false);
            } else if (status !== 'VALID' && !this.disabled$.value) {
              this.disabled$.next(true);
            }
          })
        )
        .subscribe();
    }
  }

  disabledValidate() {
    if (this.disabled$.value) {
      this.formGroupDir.form.markAllAsTouched();
    }
  }
}
