import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CmsFormField,
  MarketingPreferences,
  WrappedValidate,
} from '@domgen/dgx-fe-business-models';
import { MarketingPreferencesFormStateService } from './marketing-preferences-form-state.service';
import { MarketingPreferencesFormConfigService } from './marketing-preferences-form-config.service';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
@Component({
  selector: 'dgx-uib-marketing-preferences',
  templateUrl: './marketing-preferences.component.html',
  styleUrls: ['./marketing-preferences.component.scss'],
  providers: [
    MarketingPreferencesFormStateService,
    MarketingPreferencesFormConfigService,
  ],
})
export class MarketingPreferencesComponent {
  @Input() set cmsFormData(value: CmsFormField[] | null) {
    if (value) {
      this.stateService.setCmsFormData(value);
    }
  }

  @Input() set stateFormData(value: MarketingPreferences | null) {
    if (value) {
      this.stateService.setPrefilledFormData(value);
    }
  }

  @Input() set validate(validate: WrappedValidate | null) {
    if (validate?.validate) {
      this.stateService.validate();
    }
  }

  @Input() public privacyPolicyLink =
    'https://www.domesticandgeneral.com/privacy-cookies-policy';

  @Output() form = this.stateService.formGroup$;

  @Output() valueChanges = this.stateService.valueChanges$;

  /**
   * Analytics data for the form
   */
  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  readonly vm$ = this.stateService.vm$;

  constructor(
    private readonly stateService: MarketingPreferencesFormStateService
  ) {}

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }
}
