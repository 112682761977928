import { createAction, props } from '@ngrx/store';
import {
  ReinstateDirectDebitRequest,
  ReinstateFormSubmit,
} from '@common/util-models';

export const reinstateDirectDebit = createAction(
  '[ReinstateComponent] Reinstate direct debit',
  props<{
    reinstateRequest: ReinstateDirectDebitRequest;
    formData: ReinstateFormSubmit;
  }>()
);

export const reinstateDirectDebitSuccess = createAction(
  '[ReinstateEffects] Reinstate direct debit success'
);

export const reinstateDirectDebitFailure = createAction(
  '[ReinstateEffects] Reinstate direct debit failure',
  props<{
    error: Error;
  }>()
);

export const reinstateValidationFailure = createAction(
  '[ReinstateEffects] Reinstate details are invalid'
);
