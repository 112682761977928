import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
@Component({
  selector: 'sales-order-confirmation-thankyou-text',
  templateUrl: './order-confirmation-thankyou-text.component.html',
  styleUrls: ['./order-confirmation-thankyou-text.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderConfirmationThankyouTextComponent {}
