import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { SharedUtilQuantumMetricsModule } from '@domgen/dgx-fe-util-foundation';
import { FooterModule } from '@domgen/dgx-ui-whitelabel-components';
import { AppliancePlanDetailComponent } from './appliance-plan-detail/appliance-plan-detail.component';
import { CheckoutBannerComponent } from './checkout-banner/checkout-banner.component';
import { CountOnUsComponent } from './count-on-us/count-on-us.component';
import { CoverSelectionComponent } from './cover-selection/cover-selection.component';
import { CoverStartingDateComponent } from './cover-starting-date/cover-starting-date.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { DirectDebitAccountHolderCheckboxComponent } from './direct-debit-account-holder-checkbox/direct-debit-account-holder-checkbox.component';
import { DirectDebitGuranteeComponent } from './direct-debit-gurantee/direct-debit-gurantee.component';
import { DirectDebitInstructionConfirmationTextComponent } from './direct-debit-instruction-confirmation-text/direct-debit-instruction-confirmation-text.component';
import { DirectDebitConfirmationCheckboxComponent } from './direct-debit-payment-detail-confirmation-checkbox/direct-debit-confirmation-checkbox.component';
import { Error404Component } from './error-404/error-404.component';
import { ErrorPageGaTaggingDirective } from './error-page/error-page-ga-tagging/error-page-ga-tagging.directive';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ExcessSelectionComponent } from './excess-selection/excess-selection.component';
import { FooterComponent } from './footer/footer.component';
import { GoToMyAccountComponent } from './go-to-my-account/go-to-my-account.component';
import { HeroBannerComponent } from './hero-banner/hero-banner.component';
import { MarketingPreferencesFormComponent } from './marketing-preferences-form/marketing-preferences-form.component';
import { NeedToKnowComponent } from './need-to-know/need-to-know.component';
import { OrderConfirmationThankyouTextComponent } from './order-confirmation-thankyou-text/order-confirmation-thankyou-text.component';
import { PaymentCardComponent } from './payment-card/payment-card.component';
import { PaymentDetailConfirmationComponent } from './payment-detail-confirmation/payment-detail-confirmation.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PaymentIconsComponent } from './payment-icons/payment-icons.component';
import { PaymentMethodSelectionComponent } from './payment-method-selection/payment-method-selection.component';
import { PaymentOptionFormComponent } from './payment-option-form/payment-option-form.component';
import { PlanDurationComponent } from './plan-duration/plan-duration.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { QuoteSummaryItemComponent } from './quote-summary-item/quote-summary-item.component';
import { QuoteSummaryTotalComponent } from './quote-summary-total/quote-summary-total.component';
import { QuoteSummaryComponent } from './quote-summary/quote-summary.component';
import { QuoteTitleComponent } from './quote-title/quote-title.component';
import { TermsAndConditionsForInsuranceComponent } from './terms-and-conditions-for-insurance/terms-and-conditions-for-insurance.component';
import { TermsAndConditionsFormComponent } from './terms-and-conditions-form/terms-and-conditions.component';
import { TermsAndConditionsTextComponent } from './terms-and-conditions-text/terms-and-conditions-text.component';
import { TopBannerComponent } from './top-banner/top-banner.component';
import { UpgradeBannerComponent } from './upgrade-banner/upgrade-banner.component';
import { WhyGetProtectedComponent } from './why-get-protected/why-get-protected.component';
import { YourDetailsComponent } from './your-details/your-details.component';
@NgModule({
  imports: [
    CommonModule,
    FooterModule,
    ReactiveFormsModule,
    RouterModule,
    SharedUiComponentsCoreModule,
    SharedUiDynamicFormBuilderModule,
    SharedUtilQuantumMetricsModule,
  ],
  declarations: [
    AppliancePlanDetailComponent,
    CheckoutBannerComponent,
    CountOnUsComponent,
    CoverSelectionComponent,
    CoverStartingDateComponent,
    CustomerDetailsComponent,
    DirectDebitConfirmationCheckboxComponent,
    DirectDebitGuranteeComponent,
    DirectDebitInstructionConfirmationTextComponent,
    Error404Component,
    ErrorPageComponent,
    ErrorPageGaTaggingDirective,
    ExcessSelectionComponent,
    FooterComponent,
    GoToMyAccountComponent,
    HeroBannerComponent,
    MarketingPreferencesFormComponent,
    NeedToKnowComponent,
    OrderConfirmationThankyouTextComponent,
    PaymentCardComponent,
    PaymentDetailConfirmationComponent,
    PaymentDetailsComponent,
    PaymentIconsComponent,
    PaymentMethodSelectionComponent,
    PaymentOptionFormComponent,
    PlanDurationComponent,
    ProductCardComponent,
    QuoteSummaryComponent,
    QuoteSummaryItemComponent,
    QuoteSummaryTotalComponent,
    QuoteTitleComponent,
    TermsAndConditionsForInsuranceComponent,
    TermsAndConditionsFormComponent,
    TermsAndConditionsTextComponent,
    TopBannerComponent,
    UpgradeBannerComponent,
    WhyGetProtectedComponent,
    YourDetailsComponent,
    DirectDebitAccountHolderCheckboxComponent,
  ],
  exports: [
    AppliancePlanDetailComponent,
    CheckoutBannerComponent,
    CountOnUsComponent,
    CoverSelectionComponent,
    CoverStartingDateComponent,
    CustomerDetailsComponent,
    DirectDebitConfirmationCheckboxComponent,
    DirectDebitGuranteeComponent,
    DirectDebitInstructionConfirmationTextComponent,
    Error404Component,
    ErrorPageComponent,
    ErrorPageGaTaggingDirective,
    ExcessSelectionComponent,
    FooterComponent,
    GoToMyAccountComponent,
    HeroBannerComponent,
    MarketingPreferencesFormComponent,
    NeedToKnowComponent,
    OrderConfirmationThankyouTextComponent,
    PaymentCardComponent,
    PaymentDetailConfirmationComponent,
    PaymentDetailsComponent,
    PaymentIconsComponent,
    PaymentMethodSelectionComponent,
    PaymentOptionFormComponent,
    PlanDurationComponent,
    ProductCardComponent,
    QuoteSummaryComponent,
    QuoteTitleComponent,
    TermsAndConditionsForInsuranceComponent,
    TermsAndConditionsFormComponent,
    TermsAndConditionsTextComponent,
    TopBannerComponent,
    UpgradeBannerComponent,
    WhyGetProtectedComponent,
    YourDetailsComponent,
    DirectDebitAccountHolderCheckboxComponent,
  ],
  providers: [DatePipe],
})
export class CommonUiWhitelabelComponentsModule {}
