import { ApplianceFormValueWithItemId } from '@common/util-models';

export function applianceIsHeating<
  T extends { applianceCategory?: string; category?: string }
>(appliance: T | undefined): boolean {
  const applianceCategory: string =
    appliance?.applianceCategory || appliance?.category || '';

  return applianceCategory.toLowerCase().includes('boiler');
}

export function applianceIsWhiteGoods<
  T extends { applianceCategory?: string; category?: string }
>(appliance: T | undefined): boolean {
  return !!appliance && !applianceIsHeating(appliance);
}

export const getFullDetailsForm = (
  form: ApplianceFormValueWithItemId | undefined
) =>
  !form
    ? ''
    : `appliancewo: ${form.goodWorkingOrder}|appliancemg: ${form.underGuarantee}|appliancedp: ${form?.monthYearDate?.month}/${form?.monthYearDate?.year}|applianceco: ${form.purchasePrice}`;
