import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PersonalDetailsPartialState } from '@common/data-access-personal-details';
import {
  QuotesPageSelectors,
  QuotesPartialState,
} from '@common/data-access-quotes';
import { UserProfilePartialState } from '@common/data-access-user-profile';
import { RemoteData, TaggingMetadata } from '@common/util-models';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CardPaymentPartialState } from './card-payment.reducer';
import * as CardPaymentSelectors from './card-payment.selectors';
import { retryPayingByDirectDebit } from '@common/data-access-shared';
import { selectUser } from '@domgen/dgx-fe-auth';
import { filterNullUndefined } from '@common/util-foundation';

@Injectable()
export class CardPaymentFacade {
  cardPaymentProviderMetadata$ = this.store$.pipe(
    select(CardPaymentSelectors.getCardPaymentProviderMetadata),
    filter((cardPaymentProviderMetadata) => !!cardPaymentProviderMetadata)
  ) as Observable<string>;

  cardPaymentOrderNumber$: Observable<string> = this.store$.pipe(
    select(CardPaymentSelectors.getCardPaymentOrderNumber),
    filterNullUndefined()
  );

  cardPaymentSetupRemoteState$ = this.store$.pipe(
    select(CardPaymentSelectors.getCardPaymentSetupRemoteState)
  ) as Observable<RemoteData>;

  taggingMetadata$ = this.store$.pipe(
    select(QuotesPageSelectors.getTaggingMetadata),
    filter((taggingMetadata) => !!taggingMetadata)
  ) as Observable<TaggingMetadata>;

  user$ = this.store$.pipe(select(selectUser));

  constructor(
    private store$: Store<
      PersonalDetailsPartialState &
        QuotesPartialState &
        UserProfilePartialState &
        CardPaymentPartialState
    >
  ) {}

  retryDirectDebitPayment() {
    this.store$.dispatch(retryPayingByDirectDebit());
  }
}
