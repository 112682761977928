<div class="footer-navigation">
  <div class="container">
    <div class="xs-12 md-d-none">
      <ng-container *ngFor="let item of navigationItems">
        <ui-accordion
          *ngIf="item.links.length"
          (toggle)="onToggle(item, $event)"
          variant="menu"
          [title]="item.title"
        >
          <ul>
            <li *ngFor="let link of item.links">
              <ng-container
                [ngTemplateOutlet]="footerNavigationLink"
                [ngTemplateOutletContext]="{
                  navigationLink: link
                }"
              ></ng-container>
            </li>
          </ul>
        </ui-accordion>
      </ng-container>
    </div>

    <div
      class="d-none md-d-flex"
      [ngClass]="
        isNavigationItemsFull ? 'justify--space-between' : 'justify--center'
      "
    >
      <ng-container
        *ngFor="let item of navigationItems; let first = first; let last = last"
      >
        <div
          *ngIf="item.links.length"
          [ngClass]="{ 'mr-1': first, 'ml-1': last, 'mx-1': !first && !last }"
          [attr.data-test-hook]="item.id"
        >
          <h4 class="footer-navigation__title mb-1">{{ item.title }}</h4>
          <ul>
            <li *ngFor="let link of item.links">
              <ng-container
                [ngTemplateOutlet]="footerNavigationLink"
                [ngTemplateOutletContext]="{
                  navigationLink: link
                }"
              ></ng-container>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #footerNavigationLink let-navigationLink="navigationLink">
  <a
    *ngIf="navigationLink.routerPath; else externalFooterNavigationLink"
    (click)="handleLinkClick($event, navigationLink.label)"
    [routerLink]="[navigationLink.routerPath]"
    class="footer-navigation__link typog-menu-link"
  >
    {{ navigationLink.label }}
  </a>

  <ng-template #externalFooterNavigationLink>
    <a
      (click)="handleLinkClick($event, navigationLink.label)"
      [attr.rel]="navigationLink.newWindow ? 'noopener noreferrer' : undefined"
      [attr.target]="navigationLink.newWindow ? '_blank' : undefined"
      [href]="navigationLink.href"
      class="footer-navigation__link typog-menu-link"
    >
      {{ navigationLink.label }}
    </a>
  </ng-template>
</ng-template>
