import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ResetStateActions from './reset-state.actions';

@Injectable({
  providedIn: 'root',
})
export class ResetStateFacade {
  constructor(private store$: Store) {}

  sessionInactivityTimeout() {
    this.store$.dispatch(ResetStateActions.sessionInactivityTimeout());
  }
}
