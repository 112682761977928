import { OfferDetails, RenewDetails } from './offer-renewal-quotes.types';

export enum ContractType {
  Insurance = 'INS',
  MSP = 'MSP',
}

export enum CoverType {
  Gold = 'G-STD',
  SilverPlus = 'SP-STD',
  Silver = 'S-STD',
  Platinum = 'P-STD',
  Standard = 'STD',
  MultiPlan = 'MP',
}

export enum CoverTypeName {
  'STD' = 'Standard',
  'S-STD' = 'Silver',
  'SP-STD' = 'Silver Plus',
  'G-STD' = 'Gold',
  'P-STD' = 'Platinum',
  'MP' = 'MultiPlan',
}

export enum WhichTitle {
  selectedCoverName = 'selectedCoverName',
  brandAppliance = 'brandAppliance',
  brandApplianceCover = 'brandApplianceCover',
}

export enum PaymentType {
  DirectDebit = 'D',
  Card = 'C',
}

export enum PaymentTerm {
  FixedTerm = 'FT',
  MPM = 'MPM',
  MPA = 'MPA',
  IndefinitePayMonthly = 'IPM',
}

export enum ItemType {
  Quote = 'QUOTE',
  Renewal = 'RENEW',
  Offer = 'OFFER',
  QuoteSky = 'QUOTESKYP',
}

export interface BasketItemApplianceDetails {
  applianceCode: string;
  applianceName: string;
  applianceIcon: string;
  applianceImage: string;
  applianceCategory: string;
  brandCode: string;
  brandName: string;
  modelNumber?: string;
  serialNumber?: string;
  purchasePrice: number;
  purchaseMonth: number;
  purchaseYear: number;
  warranty: number;
  inWarranty: boolean;
}

export interface BasketItemPaymentOption {
  paymentType: PaymentType;
  preferredPayment: boolean;
  paymentProfile: string;
  /**
   * The total cost of the plan.
   */
  fee: number;
  numPayments: number;
  numFreePayments?: number;
  numSubsequentPayments?: number;
  paymentFrequency: number;
  firstPayment: number;
  subsequentPayment: number;
  directDebitDiscount?: number;
}

export interface BasketItemQuoteDetails {
  quoteId: string;
  coverType: CoverType;
  contractTypeCode: ContractType;
  productType: string;
  breakdownStartDate: string;
  waitDays: number;
  periodOfCover: number;
  paymentOptions: Array<BasketItemPaymentOption>;
  companyCode: string;
  schemeCode: string;
  paymentTerm: string;
  isBundle: boolean;
  excessAmount: number;
}

export interface BasketItemPaymentDetails {
  preferredPayment: boolean;
  paymentType?: string;
  paymentProfile: string;
  numPayments: number;
  numSubsequentPayments?: number;
  paymentFrequency: number;
  firstPayment: number;
  subsequentPayment: number;
  excessAmount: number;
}

export interface BasketItemData {
  quotes: Array<BasketItemQuoteDetails>;
  applianceDetails: BasketItemApplianceDetails;
  offerDetails?: OfferDetails;
  renewDetails?: RenewDetails;
}

export interface BasketItem {
  requestSource: string;
  requestAction?: string;
  basketId: string;
  digitalId?: string;
  itemType: ItemType;
  itemId: string;
  status: string;
  created: string;
  updated: string;
  expiry: string;
  data: BasketItemData;
}
