import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'wl-quote-title',
  templateUrl: './quote-title.component.html',
  styleUrls: ['./quote-title.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteTitleComponent {
  @Input() isInsurance: boolean | null = false;
  @Input() hideSubtitle: boolean | null = false;
}
