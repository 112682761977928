import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class NavigationTriggerProvider implements OnDestroy {
  subscriptions = new Subscription();

  /**
   * Capture the type of Navigation 'imperative', 'popstate'.
   */
  navigationTrigger$ = this.router.events
    .pipe(
      filter((event: Event) => event instanceof NavigationStart),
      map((event) => ((event as unknown) as NavigationStart).navigationTrigger)
    )
    .pipe(
      shareReplay({
        refCount: true,
        bufferSize: 1,
      })
    );

  constructor(private router: Router) {
    this.subscriptions.add(this.navigationTrigger$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
