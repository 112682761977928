import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectDebitUiModule } from './direct-debit/direct-debit-ui.module';
import { MarketingPreferencesModule } from './marketing-preferences/marketing-preferences.module';
import { PlanDocumentsModule } from './plan-documents/plan-documents.module';
import { CoverExclusionsModule } from './covers-exclusions/covers-exclusions.module';

@NgModule({
  imports: [
    CommonModule,
    DirectDebitUiModule,
    MarketingPreferencesModule,
    PlanDocumentsModule,
    CoverExclusionsModule
  ],
})
export class SharedUiBusinessComponentsModule {}
