// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./world-pay-card-payment.d.ts" />

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subject } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {
  WorldPayCardPaymentApi,
  WorldPayCardPaymentOptions,
  WorldPayResponse,
} from '../../_shared/interfaces/world-pay-card-payment.types';

export interface WorldPayCardPaymentState {
  worldPayLibrary: WPCL.Library | undefined;
  worldPayResponse: WorldPayResponse | undefined;
  worldPayIFrameTarget: string;
  worldPaySetupOptions: SetupOptions | undefined;
}

export interface ViewModel {
  worldPayIFrameTarget: string;
  worldPayLibrary: WPCL.Library;
}

export const initialState: WorldPayCardPaymentState = {
  worldPayLibrary: undefined,
  worldPayResponse: undefined,
  worldPayIFrameTarget: 'paymentForm',
  worldPaySetupOptions: undefined,
};

@Injectable()
export class WorldPayCardPaymentStateService extends ComponentStore<WorldPayCardPaymentState> {
  // ****** Signals (Trigger a state change or a side effect, but do not end in the state)
  private paymentOptionsSubject = new Subject<WorldPayCardPaymentOptions>();
  private worldPayResponseSubject = new Subject<WorldPayResponse>();

  // ************ Selectors (Normally used as vm$ streams ) **********
  private readonly worldPayIFrameTarget$ = this.select(
    (state: WorldPayCardPaymentState) => state.worldPayIFrameTarget
  );

  private readonly setupOptions$ = this.select(
    (state: WorldPayCardPaymentState) => state.worldPaySetupOptions
  ).pipe(filter((setupOptions) => !!setupOptions)) as Observable<SetupOptions>;

  // ************ Public Selectors (Output() events) **********
  readonly paymentResponse$ = this.select(
    (state: WorldPayCardPaymentState) => state.worldPayResponse
  ).pipe(filter((worldPayResponse) => !!worldPayResponse));

  readonly api$ = this.select(
    (state: WorldPayCardPaymentState) => state.worldPayLibrary
  ).pipe(
    filter((worldPayLibrary) => !!worldPayLibrary),
    withLatestFrom(this.setupOptions$),
    map(
      ([worldPayLibrary, setupOptions]) =>
        ({
          setup: () => {
            worldPayLibrary?.setup(setupOptions);
          },
          destroy: () => {
            worldPayLibrary?.destroy();
          },
        } as WorldPayCardPaymentApi)
    )
  );

  // *************** vm$ ************** //
  readonly vm$ = this.select(
    this.worldPayIFrameTarget$,
    this.paymentResponse$,
    (worldPayIFrameTarget, paymentResponse) => ({
      worldPayIFrameTarget,
      paymentResponse,
    })
  );

  // *********** Updater Streams *********** //
  private paymentOptionsHandler$ = this.paymentOptionsSubject.pipe(
    withLatestFrom(this.worldPayIFrameTarget$),
    map(([paymentOptions, iframeTarget]) =>
      this.getWorldPaySetupOptions(paymentOptions, iframeTarget)
    ),
    map((worldPaySetupOptions) => {
      const worldPayLibrary = new WPCL.Library();
      worldPayLibrary.setup(worldPaySetupOptions);
      return { worldPayLibrary, worldPaySetupOptions };
    })
  );

  // *********** Updaters *********** //
  readonly updateLibrary = this.updater(
    (
      state: WorldPayCardPaymentState,
      {
        worldPayLibrary,
        worldPaySetupOptions,
      }: {
        worldPayLibrary: WPCL.Library;
        worldPaySetupOptions: SetupOptions;
      }
    ) => {
      return {
        ...state,
        worldPayLibrary,
        worldPaySetupOptions,
      };
    }
  )(this.paymentOptionsHandler$);

  readonly updatePaymentResponse = this.updater(
    (state: WorldPayCardPaymentState, worldPayResponse: WorldPayResponse) => {
      return {
        ...state,
        worldPayResponse,
      };
    }
  )(this.worldPayResponseSubject);

  // *********** Side Effects (Should not update state) *********** //
  readonly updateModelSideEffect = this.effect(
    (trigger$: Observable<WorldPayResponse>) => {
      return trigger$.pipe(
        tap((paymentResponse: WorldPayResponse) =>
          this.updateModel(paymentResponse)
        )
      );
    }
  )(this.worldPayResponseSubject);

  private onTouched: () => void = () => {
    return undefined;
  };

  private onChanged: (_val: unknown) => void = () => {
    return undefined;
  };

  constructor() {
    super(initialState);
  }

  saveOnChangeReference(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  saveOnTouchedReference(fn: () => void) {
    this.onTouched = fn;
  }

  updatePaymentOptions(paymentOptions: WorldPayCardPaymentOptions) {
    this.paymentOptionsSubject.next(paymentOptions);
  }

  worldPayResultCallback(response: WorldPayResponse) {
    this.worldPayResponseSubject.next(response);
  }

  private updateModel(worldPayResponse: WorldPayResponse | null) {
    if (this.onChanged && this.onTouched) {
      this.onChanged(worldPayResponse);
      this.onTouched();
    }
  }

  private getWorldPaySetupOptions(
    paymentOptions: WorldPayCardPaymentOptions,
    iframeTarget: string
  ): SetupOptions {
    return {
      accessibility: true,
      iframeHelperURL: paymentOptions.iframeHelperURL,
      inject: 'immediate',
      target: iframeTarget,
      url: paymentOptions.url,
      customisation: paymentOptions.styleCustomisation,
      debug: false,
      type: 'iframe',
      resultCallback: (response: unknown) =>
        this.worldPayResultCallback(response as WorldPayResponse),
    };
  }
}
