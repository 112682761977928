import { createAction, props } from '@ngrx/store';
import { CardPaymentRedirectResponse } from '@common/util-models';

export const setupCardPayment = createAction(
  '[CardPaymentSetupResolver] Setup new card payment order'
);

export const cardPaymentResolverEnter = createAction(
  '[CardPaymentSetupResolver] Enter'
);

export const setupCardPaymentSuccess = createAction(
  '[CardPaymentEffects] New card payment setup successfully',
  props<{ cardPaymentSetupInformation: CardPaymentRedirectResponse }>()
);

export const setupCardPaymentFailure = createAction(
  '[CardPaymentEffects] Card payment setup failed',
  props<{ error: Error }>()
);
