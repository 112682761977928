import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { CmsFormField } from '@domgen/dgx-fe-business-models';
import {
  CheckboxToggleDef,
  ControlType,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { TermsAndConditionsFormField } from '@common/util-models';
import * as CmsHelpers from '@domgen/dgx-fe-business-components';

@Injectable()
export class TermsAndConditionsConfigService {
  findTermsAndConsCmsField(cmsData: CmsFormField[]): CheckboxToggleDef {
    const cmsFieldData = CmsHelpers.getFormField(
      cmsData,
      TermsAndConditionsFormField.TermsAndConditions
    );

    return {
      controlType: ControlType.ACCEPT,
      controlName: TermsAndConditionsFormField.TermsAndConditions,
      validators: [Validators.required],
      validationMessages: [...CmsHelpers.getValidationMessages(cmsFieldData)],
    } as CheckboxToggleDef;
  }
}
