<dgx-dfb-field-layout
  [id]="id"
  [label]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
>
  <div class="month-year-wrapper">
    <div
      *ngIf="!(monthYearDateUpdater$ | async)?.defaultMonth"
      [ngClass]="['select', 'is-' + monthValidityStatus]"
    >
      <div class="select-wrapper">
        <select
          class="form-control"
          [class.select--empty]="!value.month"
          #monthSelect
          [name]="field?.controlName + '-month'"
          (change)="saveMonth(monthSelect.value, $event)"
          [attr.aria-label]="field?.monthLabel?.text"
          [disabled]="disabled"
        >
          <option disabled [selected]="!value.month">Select month</option>
          <option
            *ngFor="let month of field?.monthOptions"
            [value]="month"
            [selected]="value?.month === month"
          >
            {{ monthNames[month - 1] }}
          </option>
        </select>
        <i class="icon icon-arrow-down"></i>

        <ui-form-validation-icon
          [validityStatus]="monthValidityStatus"
        ></ui-form-validation-icon>
      </div>
    </div>

    <div class="select" [ngClass]="['select', 'is-' + yearValidityStatus]">
      <div class="select-wrapper">
        <select
          class="form-control"
          [class.select--empty]="!value.year"
          #yearSelect
          [name]="field?.controlName + '-year'"
          (change)="saveYear(yearSelect.value, $event)"
          [attr.aria-label]="field?.yearLabel?.text"
          [disabled]="disabled"
        >
          <option disabled [selected]="!value.year">Select year</option>
          <option
            *ngFor="let year of yearOptions"
            [value]="year"
            [selected]="value?.year === year"
          >
            {{ year }}
          </option>
        </select>
        <i class="icon icon-arrow-down"></i>

        <ui-form-validation-icon
          [validityStatus]="yearValidityStatus"
        ></ui-form-validation-icon>
      </div>
    </div>
  </div>
</dgx-dfb-field-layout>
