<div class="payment-detail-summary-section">
  <div class="payment-detail-summary-section-header">
    Instruction to your bank or building society
  </div>
  <div class="payment-detail-summary-section-desc">
    Please pay
    <strong>{{ orgName }}</strong>
    direct debits from the account detailed in the instruction subject to
    safeguards assured by the Direct Debit Guarantee. I understand this
    instruction may remain with
    <strong>{{ orgName }}</strong>
    and, if so, details will be passed electronically to my bank/building
    society.
  </div>
</div>

<div class="payment-detail-summary-section">
  <div class="payment-detail-summary-section-header">
    Confirmation of Direct Debit instruction
  </div>
  <div class="payment-detail-summary-section-desc">
    <div>
      Your protection is provided by
      <strong>{{
        isInsurance
          ? 'Domestic & General Insurance PLC'
          : 'Domestic & General Services Limited'
      }}</strong
      >, which will show on your bank statements as D&G.
    </div>
    <div>
      Your Direct Debit instruction will be confirmed to you by email within 10
      working days prior to the first collection — as long as that takes place
      within the first month.
    </div>
  </div>
</div>
