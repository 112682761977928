import { FeatureConfigFacade } from '@common/data-access-feature-config';
import { ReinstateClientConfig } from '@common/util-models';
import { map } from 'rxjs/operators';

export const featureConfigGoogleTagGlobalPropsFactory = (
  featureConfigFacade: FeatureConfigFacade
) => {
  return featureConfigFacade.clientConfig$<ReinstateClientConfig>().pipe(
    map(({ gaClientIdentifier }) => ({
      clientName: gaClientIdentifier,
    }))
  );
};
