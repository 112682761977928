import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'wl-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  readonly copyrightOwner?: string = this.buildConfigService.config.footer
    .copyrightOwner;
  readonly usefulLinks: NavigationLink[] = this.buildConfigService.config.footer.links.filter(
    ({ section }) => section === 'useful'
  );
  readonly legalLinks: NavigationLink[] = this.buildConfigService.config.footer.links.filter(
    ({ section }) => section === 'legal'
  );
  readonly socialLinks?: NavigationLink[] = this.buildConfigService.config
    .footer.socialLinks;

  constructor(private buildConfigService: BuildConfigService) {}
}
