import { AbstractControl } from '@angular/forms';
import { MonoTypeOperatorFunction, Observable, of, pipe } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  disableFormControls,
  enableFormControls,
  getAllDisabledFormControls,
  getAllFormControls,
} from '../../utils/form/form.utils';

export function disableControl<T extends AbstractControl | null | undefined>(
  control: T | Observable<T>
): MonoTypeOperatorFunction<boolean> {
  const control$ = control instanceof Observable ? control : of(control);
  let existingDisabledControls: AbstractControl[] = [];

  return pipe(
    distinctUntilChanged(),
    switchMap((disabled: boolean) =>
      control$.pipe(
        filter((control: T) => !!control && control.disabled !== disabled),
        tap((control: T) => {
          const controls = getAllFormControls(control);

          if (disabled) {
            existingDisabledControls = getAllDisabledFormControls(control);
            disableFormControls(...controls);
          } else {
            enableFormControls(...controls);
            disableFormControls(...existingDisabledControls);
          }
        }),
        map(() => disabled)
      )
    )
  );
}
