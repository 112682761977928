/* istanbul ignore file */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderPoweredByComponent } from './header-powered-by/header-powered-by.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { HeaderNavLinkComponent } from './header-nav-link/header-nav-link.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header.component';

const declarations = [
  HeaderPoweredByComponent,
  MyAccountComponent,
  HeaderNavLinkComponent,
  HeaderComponent,
];

@NgModule({
  declarations: declarations,
  exports: declarations,
  imports: [CommonModule, RouterModule],
})
export class HeaderModule {}
