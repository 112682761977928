import { AbstractControl } from '@angular/forms';
import { of, OperatorFunction, pipe } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  skip,
  startWith,
  switchMap,
} from 'rxjs/operators';

export function controlHasError(
  errorCode: string
): OperatorFunction<AbstractControl | null | undefined, boolean> {
  return pipe(
    switchMap(
      (control: AbstractControl | null | undefined) =>
        control?.statusChanges.pipe(
          map(() => control.hasError(errorCode)),
          startWith(control.hasError(errorCode))
        ) ?? of(false)
    ),
    distinctUntilChanged()
  );
}

export function controlHasErrorChanges(
  errorCode: string
): OperatorFunction<AbstractControl | null | undefined, boolean> {
  return pipe(controlHasError(errorCode), skip(1));
}
