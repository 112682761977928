import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';
import { createReducer, on, Action } from '@ngrx/store';

import * as AuthActions from './auth.actions';

export const AUTH_FEATURE_KEY = 'authsso';

export interface AuthSsoState {
  isLoggedIn: boolean;
  customerName: string | undefined;
  sessionExpiresOn: number | undefined;
  user: User | undefined;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthSsoState;
}

export const initialState: AuthSsoState = {
  isLoggedIn: false,
  customerName: undefined,
  sessionExpiresOn: undefined,
  user: undefined,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.setAuthStateFromCookieLoggedIn, (state, { jwt }) => ({
    ...state,
    isLoggedIn: true,
    customerName: jwt.name,
    sessionExpiresOn: jwt.exp,
    user: <User>jwt,
  })),
  on(AuthActions.setAuthStateFromCookieLoggedOut, (state) => ({
    ...state,
    isLoggedIn: false,
    customerName: undefined,
    sessionExpiresOn: undefined,
    user: undefined,
  }))
);

export function reducer(state: AuthSsoState | undefined, action: Action) {
  return authReducer(state, action);
}
