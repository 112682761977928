import { createAction, props } from '@ngrx/store';
import { DirectDebit, DirectDebitDetails, Person } from '@common/util-models';
import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';

export const loginSuccess = createAction(
  '[CheckoutLoggedInContainerComponent] User successfully logged in'
);

export const checkoutAsGuest = createAction(
  '[CheckoutLoggedInContainerComponent] User checkout as Guest'
);

export const loadPersonalAndDirectDebitDetails = createAction(
  '[PersonalDetailsResolver] Load Personal and Direct Debit Details'
);

export const loadPersonalAndDirectDebitDetailsSuccess = createAction(
  '[UserProfileEffects] Load Personal and Direct Debit Details Success',
  props<{
    person: Person;
    directDebitDetails: DirectDebitDetails;
    selectUser: User;
  }>()
);

export const loadPersonalAndDirectDebitDetailsFailure = createAction(
  '[UserProfileEffects] Load Personal and Direct Debit Details Failure',
  props<{ error: Error }>()
);

export const updateDirectDebit = createAction(
  '[CheckoutLoggedInUserComponent] Update Direct Debit',
  props<{ newDirectDebitDetails: DirectDebit | null }>()
);

export const changePaymentDay = createAction(
  '[CheckoutLoggedInUserComponent] Change Direct Debit Payment Day',
  props<{ paymentDay: number }>()
);

export const tagPersonalDetailsProfile = createAction(
  '[CheckoutPersonalDetailsComponent] Tag Personal Details while Logging',
  props<{ person: Person }>()
);

export const updatePlanDocumentPreference = createAction(
  '[CheckoutLoggedInUserComponent] Update Plan Document Preferences',
  props<{ paperless: boolean }>()
);
