import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { CoverType } from '@common/util-models';
import { QuotesFacade } from '../facades/quotes.facade';

@Injectable({
  providedIn: 'root',
})
export class QuoteRouteParamsResolver implements Resolve<boolean> {
  constructor(private quotesFacade: QuotesFacade) {}
  resolve(route: ActivatedRouteSnapshot): boolean {
    const cover: CoverType = route.queryParams?.cover ?? route.data?.cover;
    const excess = Number(route.queryParams?.excess ?? route.data?.excess);

    if (cover && excess) {
      this.quotesFacade.autoSelectFromRouteParams(cover, excess);
    }
    return !!cover && !!excess;
  }
}
