import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ListOverlayEvent } from '../../_shared/components/list-overlay/list-overlay.component';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { ListBoxDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

let nextId = 1;
@UntilDestroy()
@Component({
  selector: 'dgx-dfb-list-box',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.scss'],
  providers: [TooltipService],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListBoxComponent
  extends FormElementBaseComponent
  implements OnInit, ControlValueAccessor {
  @Input() field!: ListBoxDef;
  @Output() selectedIndex = new EventEmitter<number>();
  @Output() clearSelection = new EventEmitter<void>();
  activeOptionIndex = -1;

  value = '';

  id = `list-box-${nextId++}`;
  options: string[] = [];

  get hasValue() {
    return this.value.length > 0;
  }

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
    this.field.optionsStream$
      .pipe(untilDestroyed(this))
      .subscribe((options) => (this.options = options));
  }

  onEmitEvent(value: ListOverlayEvent) {
    if (value.type === 'click') {
      this.onClick(value);
    }
  }

  clearControl() {
    this.value = '';
    this.writeValue(this.value, true);
    this.clearSelection.emit();
    this.activeOptionIndex = -1;
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: string, emitAnalytics: boolean = false) {
    super.writeValue(val, emitAnalytics);
  }

  private onClick(value: ListOverlayEvent) {
    this.activeOptionIndex = value.payload;
    const optionValue = this.options[value.payload];
    this.value = optionValue;
    this.writeValue(this.value, true);
    this.selectedIndex.emit(value.payload);
  }
}
