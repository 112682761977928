import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dgx-ui-wl-sky-box-details',
  templateUrl: './sky-box-details.component.html',
  styleUrls: ['./sky-box-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkyBoxDetailsComponent {
  // TODO: change to proper type when data comes from CMS
  @Input() device: any;
}
