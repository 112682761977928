import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { ComponentStore } from '@ngrx/component-store';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  MonoTypeOperatorFunction,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  endWith,
  filter,
  map,
  skip,
  startWith,
  switchAll,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  AddressFieldNames,
  SelectionMode,
} from '../../_shared/interfaces/address-lookup.interface';
import {
  Address,
  addressFieldRequired,
  AddressItem,
  AddressItemDetail,
  AddressItemType,
  AddressType,
} from '../../_shared/interfaces/address.interface';
import {
  AddressDef,
  CheckboxToggleDef,
  ControlType,
  TypeAheadDef,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { AddressLookupService } from '../../_shared/services/address-lookup.service';

const EMPTY = '';
export const DomesticUseOnlyCheckboxDef: CheckboxToggleDef = {
  controlType: ControlType.CHECKBOX,
  controlName: 'isDomesticUseOnly',
  initialValue: false,
  validators: [Validators.requiredTrue],
  validationMessages: [
    { type: 'required', message: 'Please answer this question to proceed' },
  ],
  label: {
    text: 'I confirm that this appliance is for domestic use only',
  },
  classes: 'domestic-use',
} as CheckboxToggleDef;

export enum AddressLoadingState {
  NotFetched = 'notFetched',
  Loading = 'loading',
  Error = 'error',
  OK = 'ok',
}

export interface FindAddressRemoteData {
  options?: AddressItem[];
  loading?: AddressLoadingState;
  error?: string;
  typeAheadValueOverride?: string;
}

export interface AddressRemoteData {
  address?: Address | null;
  loading?: AddressLoadingState;
  error?: string;
}
export interface ViewModel {
  fieldDef: AddressDef;
  typeAheadDef: TypeAheadDef;
  domesticUseOnlyDef: CheckboxToggleDef;
  loading: AddressLoadingState;
  typeAheadValueOverride: string;
  addressForm: FormGroup;
  selectedAddress: Address | null;
  errorMessage?: string;
  addressFormControlName: string;
  validate: boolean;
  selectionMode: SelectionMode;
  typeAheadFormControl: FormControl;
  isCommercialAddressSelected: boolean;
}

export const addressFormControlName = 'address';

export interface AddressLookupState {
  optionsStream$: BehaviorSubject<AddressItem[]>;
  selectedAddress: Address | null;
  addressForm: FormGroup;
  loading: AddressLoadingState;
  apiError: string;
  typeAheadDef?: TypeAheadDef;
  domesticUseOnlyDef?: CheckboxToggleDef;
  fieldDef?: AddressDef;
  typeAheadValueOverride?: string;
  errorMessage?: string;
  formControl?: AbstractControl;
  addressFormControlName: string;
  validate: boolean;
  selectionMode: SelectionMode;
  typeAheadFormControl: AbstractControl;
  isCommercialAddressSelected: boolean;
}

export const initialState: AddressLookupState = {
  loading: AddressLoadingState.NotFetched,
  optionsStream$: new BehaviorSubject<AddressItem[]>([]),
  typeAheadDef: undefined,
  domesticUseOnlyDef: DomesticUseOnlyCheckboxDef,
  addressForm: new FormGroup({
    [addressFormControlName]: new FormControl(undefined),
    [DomesticUseOnlyCheckboxDef.controlName]: new FormControl(false, [
      Validators.requiredTrue,
    ]),
  }),
  typeAheadFormControl: new FormControl(undefined),
  selectedAddress: null,
  apiError: EMPTY,
  fieldDef: undefined,
  typeAheadValueOverride: undefined,
  errorMessage: undefined,
  formControl: undefined,
  addressFormControlName: addressFormControlName,
  validate: false,
  selectionMode: SelectionMode.Lookup,
  isCommercialAddressSelected: false,
};

@Injectable()
export class AddressLookupStateService extends ComponentStore<AddressLookupState> {
  debounceTime = 500;
  minimumSearchLimit = 1;
  controlValid = 'VALID';
  space = ' ';
  addressLookupError = { addressLookup: true };
  addressLookupRequiredDomesticUseOnlyError = { requiredDomesticUseOnly: true };
  private enteredInputSubject = new Subject<string>();
  private validateFormControlSubject = new Subject<unknown>();
  private domesticUseOnlyCheckedSubject = new Subject<boolean>();
  domesticUseOnlyChecked$ = this.domesticUseOnlyCheckedSubject.asObservable();

  // ************ Public Selectors (Output() events) **********
  private readonly selectedAddress$ = this.select(
    (state: AddressLookupState) => state.selectedAddress
  );

  private readonly fieldDef$ = this.select(
    (state: AddressLookupState) => state.fieldDef
  ).pipe(filter((fieldDef) => !!fieldDef)) as Observable<AddressDef>;

  private readonly formControl$ = this.select(
    (state: AddressLookupState) => state.formControl
  );

  private readonly errorMessage$ = this.select(
    (state: AddressLookupState) => state.errorMessage
  );

  private readonly selectionMode$ = this.select(
    (state: AddressLookupState) => state.selectionMode
  );

  private readonly selectionModeAnalytics$ = this.selectionMode$.pipe(
    map((selectionMode) => {
      return {
        controlName: AddressFieldNames.LookupMode,
        value: selectionMode,
        error: undefined,
      } as AnalyticsData;
    })
  );

  private readonly selectedAddressAnalytics$ = this.selectedAddress$.pipe(
    withLatestFrom(this.fieldDef$, this.formControl$),
    skip(1),
    map(([selectedAddress, fieldDef, formControl]) => {
      return {
        controlName: fieldDef.controlName,
        value: selectedAddress,
        error: this.getErrorMessage(formControl, fieldDef),
      } as AnalyticsData;
    })
  );

  readonly analytics$ = merge(
    this.selectionModeAnalytics$,
    this.selectedAddressAnalytics$
  );

  // ************ Selectors **********
  private readonly typeAheadDef$ = this.select(
    (state: AddressLookupState) => state.typeAheadDef
  ).pipe(filter((typeAheadDef) => !!typeAheadDef)) as Observable<TypeAheadDef>;

  private readonly domesticUseOnlyDef$ = this.select(
    (state: AddressLookupState) => state.domesticUseOnlyDef
  ).pipe(
    filter((domesticUseOnlyDef) => !!domesticUseOnlyDef)
  ) as Observable<CheckboxToggleDef>;

  private readonly typeAheadFormControl$ = this.select(
    (state: AddressLookupState) => state.typeAheadFormControl
  ) as Observable<FormControl>;

  private readonly loading$ = this.select(
    (state: AddressLookupState) => state.loading
  );

  private readonly typeAheadValueOverride$ = this.select(
    (state: AddressLookupState) => state.typeAheadValueOverride
  ) as Observable<string>;

  private readonly addressForm$ = this.select(
    (state: AddressLookupState) => state.addressForm
  );

  private readonly addressFormControlName$ = this.select(
    (state: AddressLookupState) => state.addressFormControlName
  );

  private readonly validate$ = this.select(
    (state: AddressLookupState) => state.validate
  );

  private readonly addressItems$ = this.select(
    (state: AddressLookupState) => state.optionsStream$
  ).pipe(switchAll());

  private isCommercialAddressSelected$ = this.select(
    (state: AddressLookupState) => {
      return state.isCommercialAddressSelected;
    }
  );
  readonly vm$ = this.select(
    combineLatest([
      this.fieldDef$,
      this.typeAheadDef$,
      this.domesticUseOnlyDef$,
      this.loading$,
      this.typeAheadValueOverride$,
      this.addressForm$,
      this.selectedAddress$,
      this.errorMessage$,
      this.addressFormControlName$,
      this.validate$,
      this.selectionMode$,
      this.typeAheadFormControl$,
      this.isCommercialAddressSelected$,
    ]),
    ([
      fieldDef,
      typeAheadDef,
      domesticUseOnlyDef,
      loading,
      typeAheadValueOverride,
      addressForm,
      selectedAddress,
      errorMessage,
      manualAddressFormControlName,
      validate,
      selectionMode,
      typeAheadFormControl,
      isCommercialAddressSelected,
    ]) =>
      ({
        fieldDef,
        typeAheadDef,
        domesticUseOnlyDef,
        loading,
        typeAheadValueOverride,
        addressForm,
        selectedAddress,
        errorMessage,
        addressFormControlName: manualAddressFormControlName,
        validate,
        selectionMode,
        typeAheadFormControl,
        isCommercialAddressSelected,
      } as ViewModel)
  );

  // ******** Updater Streams *********
  private readonly shouldFindAddress$ = this.enteredInputSubject.pipe(
    withLatestFrom(this.addressItems$, this.fieldDef$),
    map(([enteredInput, addressItems]) => ({
      addressItem: this.findMatchingAddressItemFromTypeaheadOption(
        addressItems,
        enteredInput
      ),
      enteredInput,
    })),
    filter(
      (enteredInputAndAddressItem) =>
        enteredInputAndAddressItem.addressItem === undefined ||
        enteredInputAndAddressItem.addressItem.Type !== AddressItemType.Address
    ),
    map((enteredInputAndAddressItem) => ({
      text:
        enteredInputAndAddressItem.addressItem?.Text ||
        enteredInputAndAddressItem.enteredInput,
      id: enteredInputAndAddressItem.addressItem?.Id || EMPTY,
    }))
  );

  private readonly shouldRetrieveAddress$ = this.enteredInputSubject.pipe(
    withLatestFrom(this.addressItems$, this.fieldDef$),
    this.filterByStartSearchFromCharacter(this.minimumSearchLimit),
    map(([enteredInput, addressItems]) =>
      this.findMatchingAddressItemFromTypeaheadOption(
        addressItems,
        enteredInput
      )
    ),
    filter(
      (enteredAddressItem) =>
        enteredAddressItem !== undefined &&
        enteredAddressItem.Type === AddressItemType.Address
    )
  ) as Observable<AddressItem>;

  private readonly findAddress$: Observable<FindAddressRemoteData> = this.shouldFindAddress$.pipe(
    withLatestFrom(this.fieldDef$),
    switchMap(([findAddressData, fieldDef]) =>
      findAddressData.id
        ? of(null).pipe(
            switchMap(() => this.findAddressObservable(findAddressData))
          )
        : of(null).pipe(
            filter(() =>
              this.filterByStartSearchFromCharacterImpl(
                findAddressData.text,
                fieldDef,
                this.minimumSearchLimit
              )
            ),
            delay(fieldDef.debounceTime || this.debounceTime),
            switchMap(() => this.findAddressObservable(findAddressData))
          )
    )
  );

  private readonly retrieveAddress$ = this.shouldRetrieveAddress$.pipe(
    switchMap((address) => this.retrieveAddressObservable(address))
  );

  private clearSelectionForBlankInput$ = this.enteredInputSubject.pipe(
    filter((enteredInput) => !enteredInput || !enteredInput.length)
  );

  private validateFormControl$ = this.validateFormControlSubject.pipe(
    withLatestFrom(this.formControl$, this.fieldDef$),
    map(([, formControl, fieldDef]) =>
      this.getErrorMessage(formControl, fieldDef)
    )
  );

  private addressFormValueChanges$ = this.addressForm$.pipe(
    map((formGroup) => formGroup.get(addressFormControlName) as FormControl),
    switchMap((addressFormControl) => addressFormControl.valueChanges)
  ) as Observable<Address>;

  private formValueChanges$ = this.addressForm$.pipe(
    map((formGroup) => formGroup.valueChanges)
  );

  private manualAddressEntryStatusChange$ = this.addressForm$.pipe(
    map((formGroup) => formGroup.get(addressFormControlName) as FormControl),
    switchMap((addressFormGroup) => addressFormGroup.statusChanges)
  ) as Observable<string>;

  private findAddressModeSelected$ = this.selectionMode$.pipe(
    filter((mode) => mode === SelectionMode.Lookup)
  );

  // ******** Updaters *********
  readonly setFieldDef = this.updater(
    (state: AddressLookupState, fieldDef: AddressDef) => {
      return {
        ...state,
        fieldDef,
      };
    }
  );

  readonly setSelectionMode = this.updater(
    (state: AddressLookupState, selectionMode: SelectionMode) => {
      return {
        ...state,
        selectionMode,
      };
    }
  );

  readonly setSelectedAddress = this.updater(
    (state, selectedAddress: Address) => ({
      ...state,
      selectedAddress,
    })
  );

  readonly setFormControl = this.updater(
    (state, formControl: AbstractControl) => ({
      ...state,
      formControl,
    })
  );

  // ***** Stream based Updaters *******
  private readonly updateStateForFieldDef = this.updater(
    (state, fieldDef: AddressDef) => {
      return {
        ...state,
        selectedAddress: fieldDef.initialValue || null,
        typeAheadDef: this.getTypeaheadDef(state, fieldDef),
      };
    }
  )(this.fieldDef$);

  private readonly clearSelection = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state, _enteredInput: string) => {
      state.optionsStream$.next([]);
      this.updateModel(null);
      return {
        ...state,
        selectedAddress: null,
        typeAheadValueOverride: EMPTY,
        isCommercialAddressSelected: false,
      };
    }
  )(this.clearSelectionForBlankInput$);

  private readonly clearTypeAheadValue = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state, _modeOrBlankInput: SelectionMode | string) => {
      state.optionsStream$.next([]);
      state.typeAheadFormControl.reset();
      state.addressForm.controls[
        DomesticUseOnlyCheckboxDef.controlName
      ].reset();
      if (state.validate) {
        state.typeAheadFormControl.markAllAsTouched();
      }
      return {
        ...state,
        typeAheadValueOverride: EMPTY,
        isCommercialAddressSelected: false,
        errorMessage: state.validate ? addressFieldRequired.message : '',
      };
    }
  )(this.findAddressModeSelected$);

  private readonly updateSearchOptions = this.updater(
    (state, remoteData: FindAddressRemoteData) => {
      const loading = remoteData.loading ?? state.loading;
      const error = remoteData.error ?? state.apiError;
      const typeAheadValueOverride =
        remoteData.typeAheadValueOverride ?? state.typeAheadValueOverride;

      state.optionsStream$.next(
        remoteData.options ?? state.optionsStream$.value
      );

      return {
        ...state,
        loading,
        apiError: error,
        typeAheadValueOverride,
      };
    }
  )(this.findAddress$);

  private readonly updateForAddressSelection = this.updater(
    (state, remoteData: AddressRemoteData) => {
      let errorMessage: string | undefined = undefined;
      const loading = remoteData.loading ?? state.loading;
      const error = remoteData.error ?? state.apiError;
      const selectedAddress =
        remoteData.address === null
          ? null
          : remoteData.address ?? state.selectedAddress;

      // Needed to prevent unnecessary flicker of an error message while the remote state is transitioning to OK from loading.
      // So dont validate whilst call is in progress
      if (remoteData.loading !== AddressLoadingState.Loading) {
        this.updateModel(selectedAddress);

        // If there is an error caused due to a commercial address selection proxy it to typeahead
        if (state.formControl?.errors) {
          state.typeAheadFormControl.setErrors(this.addressLookupError);
        }

        if (
          !state.addressForm.controls[DomesticUseOnlyCheckboxDef.controlName]
            .value &&
          selectedAddress?.type === AddressType.Commercial
        ) {
          state.formControl?.setErrors(
            this.addressLookupRequiredDomesticUseOnlyError
          );
        }

        state.addressForm.markAllAsTouched();
        errorMessage = this.getErrorMessage(state.formControl, state.fieldDef);
      }

      state.optionsStream$.next([]);
      return {
        ...state,
        loading,
        apiError: error,
        selectedAddress,
        isCommercialAddressSelected:
          selectedAddress?.type === AddressType.Commercial,
        errorMessage,
      };
    }
  )(this.retrieveAddress$);

  private readonly updateErrorMessage = this.updater(
    (state, errorMessage: string | undefined) => ({
      ...state,
      validate: true,
      errorMessage,
    })
  )(this.validateFormControl$);

  private readonly promoteManualEntryValueChanges = this.updater(
    (state, address: Address) => {
      this.updateModel(address);
      return {
        ...state,
        selectedAddress: address,
      };
    }
  )(this.addressFormValueChanges$);

  private readonly updateFormValidation = this.updater(
    (state, checked: boolean) => {
      if (
        state.addressForm.get(addressFormControlName)?.valid &&
        state.addressForm.get(addressFormControlName)?.value.type ===
          AddressType.Commercial &&
        checked
      ) {
        state.formControl?.setErrors(null);
      } else {
        state.formControl?.setErrors(
          this.addressLookupRequiredDomesticUseOnlyError
        );
      }

      return {
        ...state,
      };
    }
  )(this.domesticUseOnlyChecked$);

  // ***** Side Effects (Does not lead to a new state Emission) ******
  readonly setFormControlValueForAddressSelection = this.effect(
    (trigger$: Observable<Address | null>) => {
      return trigger$.pipe(
        withLatestFrom(this.addressForm$),
        tap(([address, addressForm]) => {
          addressForm.get(addressFormControlName)?.setValue(address);
        })
      );
    }
  )(this.selectedAddress$);

  readonly promoteManualEntryValidationStatus = this.effect(
    (trigger$: Observable<string>) => {
      return trigger$.pipe(
        withLatestFrom(this.formControl$),
        tap(([status, formControl]) => {
          const newErrors = this.addOrRemoveInvalidFormError(
            status,
            formControl?.errors || {}
          );
          formControl?.setErrors(newErrors);
        })
      );
    }
  )(this.manualAddressEntryStatusChange$);

  private onTouched: () => void = () => {
    return undefined;
  };

  private onChanged: (_val: unknown) => void = () => {
    return undefined;
  };

  constructor(private addressLookupService: AddressLookupService) {
    super(undefined);
    this.setState({
      ...initialState,
      addressForm: new FormGroup({
        [addressFormControlName]: new FormControl(undefined),
        [DomesticUseOnlyCheckboxDef.controlName]: new FormControl(false, [
          Validators.requiredTrue,
        ]),
      }),
      typeAheadFormControl: this.getTypeAheadFormControl(),
    });
  }

  saveOnChangeReference(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  saveOnTouchedReference(fn: () => void) {
    this.onTouched = fn;
  }

  enteredInput(value: string) {
    this.enteredInputSubject.next(value);
  }

  validate() {
    this.validateFormControlSubject.next();
  }

  domesticUseOnlyChecked(value: boolean) {
    this.domesticUseOnlyCheckedSubject.next(value);
  }

  private updateModel(input: Address | null) {
    if (this.onChanged && this.onTouched) {
      this.onChanged(input);
      this.onTouched();
    }
  }

  private addOrRemoveInvalidFormError(
    status: string,
    errors: ValidationErrors
  ) {
    const newErrors = { ...errors };
    if (status === this.controlValid) {
      delete newErrors.invalidForm;
      if (Object.keys(newErrors).length === 0) {
        return null;
      }
    } else {
      newErrors.invalidForm = true;
    }

    return newErrors;
  }

  private getErrorMessage(
    control: AbstractControl | undefined,
    field: AddressDef | undefined
  ) {
    const firstErrorType = control?.errors
      ? Object.keys(control.errors)[0]
      : null;
    return field?.validationMessages?.find((msg) => msg.type === firstErrorType)
      ?.message;
  }

  private mapAddressItemToTypeaheadOption(addressItem: AddressItem) {
    return `${addressItem.Text}, ${addressItem.Description}`;
  }

  private mapAddressItemDetailToAddressSelection(
    addressItemDetail: AddressItemDetail
  ): Address {
    return {
      city: addressItemDetail.City,
      line1: addressItemDetail.Line1,
      line2: addressItemDetail.Line2,
      county: addressItemDetail.Province,
      postcode: addressItemDetail.PostalCode,
      type: addressItemDetail.Type,
    };
  }

  private findMatchingAddressItemFromTypeaheadOption(
    addressItems: AddressItem[],
    option: string
  ) {
    return addressItems.find(
      (addressItem) =>
        this.mapAddressItemToTypeaheadOption(addressItem) === option
    );
  }

  private getTypeaheadDef(
    state: AddressLookupState,
    fieldDef: AddressDef | undefined
  ): TypeAheadDef {
    return {
      controlName: 'addressSearch',
      controlType: ControlType.TYPEAHEAD,
      optionsStream$: state.optionsStream$.pipe(
        distinctUntilChanged(),
        map((addressItems) =>
          addressItems.map((addressItem) =>
            this.mapAddressItemToTypeaheadOption(addressItem)
          )
        )
      ),
      validators: [],
      validationMessages: [],
      asyncValidators: [],
      label: fieldDef?.label,
      placeholder: fieldDef?.placeholder,
      debounceTime: 0, // Dont debounce filtering within the typeahead options
      initialValue: EMPTY,
      showAllOptions: true,
      disableOnSelect: true,
      sanitise: 'block',
    };
  }

  private filterByStartSearchFromCharacter(
    minimumSearchLimit: number
  ): MonoTypeOperatorFunction<[string, AddressItem[], AddressDef]> {
    return (source$) =>
      source$.pipe(
        filter(([enteredInput, , fieldDef]) =>
          this.filterByStartSearchFromCharacterImpl(
            enteredInput,
            fieldDef,
            minimumSearchLimit
          )
        )
      );
  }

  private filterByStartSearchFromCharacterImpl(
    enteredInput: string,
    fieldDef: AddressDef,
    minimumSearchLimit: number
  ) {
    return (
      enteredInput.length >= (fieldDef.startSearchFromCharacter || 0) &&
      enteredInput.length > minimumSearchLimit
    );
  }

  private getTypeAheadFormControl() {
    const typeAheadFormControl = new FormControl(undefined, [
      (c: AbstractControl) => {
        if (c.value === this.space) {
          return null;
        }
        return ((c.value as unknown) as string)?.indexOf(',') >= 0
          ? null
          : this.addressLookupError;
      },
    ]);

    return typeAheadFormControl;
  }

  private findAddressObservable(searchText: {
    text: string;
    id: string;
  }): Observable<FindAddressRemoteData> {
    return this.addressLookupService
      .findAddress(searchText.text, searchText.id)
      .pipe(
        map((response) => ({
          options: response.Items,
        })),
        startWith({
          options: [],
          loading: AddressLoadingState.Loading,
          error: EMPTY,
        }),
        endWith({
          loading: AddressLoadingState.OK,
          typeAheadValueOverride: searchText.id ? this.space : searchText.text,
          error: EMPTY,
        }),
        catchError((error: string) =>
          of({
            options: [],
            loading: AddressLoadingState.Error,
            typeAheadValueOverride: searchText.text,
            error: error,
          })
        )
      );
  }

  private retrieveAddressObservable(
    address: AddressItem
  ): Observable<AddressRemoteData> {
    return this.addressLookupService.retrieveAddress(address.Id).pipe(
      map((response) => ({
        address: this.mapAddressItemDetailToAddressSelection(response.Items[0]),
      })),
      startWith({
        address: null,
        loading: AddressLoadingState.Loading,
        error: EMPTY,
      }),
      endWith({ loading: AddressLoadingState.OK }),
      catchError((error: string) =>
        of({
          address: null,
          loading: AddressLoadingState.Error,
          error: error,
        })
      )
    );
  }
}
