import { Injectable } from '@angular/core';
import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';

import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import { AuthPartialState } from './auth.reducer';
import * as AuthSelectors from './auth.selectors';

@Injectable({ providedIn: 'any' })
export class AuthFacade {
  isLoggedIn$ = this.store.pipe(select(AuthSelectors.getIsLoggedIn));
  customerName$ = this.store.pipe(select(AuthSelectors.getCustomerName));
  sessionExpiresOn$ = this.store.pipe(
    select(AuthSelectors.getSessionExpiresOn),
    map((expiredDate) =>
      expiredDate ? this.unixTimeStampToDate(expiredDate) : undefined
    )
  );
  user$ = this.store.pipe(select(AuthSelectors.getUser));

  constructor(private store: Store<AuthPartialState>) {}

  setAuthStateFromCookieLoggedIn(jwt: User) {
    this.store.dispatch(AuthActions.setAuthStateFromCookieLoggedIn({ jwt }));
  }

  setAuthStateFromCookieLoggedOut() {
    this.store.dispatch(AuthActions.setAuthStateFromCookieLoggedOut());
  }

  private unixTimeStampToDate(unixTimeStamp: number): Date {
    return new Date(unixTimeStamp * 1000);
  }
}
