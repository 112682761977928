import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BasketItemPaymentOption, Quote } from '@common/util-models';

@Component({
  selector: 'wl-quote-summary-item',
  templateUrl: './quote-summary-item.component.html',
  styleUrls: ['./quote-summary-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteSummaryItemComponent implements OnChanges {
  @Input() quote?: Quote;
  @Input() applianceIsWhiteGoods?: boolean;
  @Input() disabled?: boolean;
  @Input() isMultiItem?: boolean;
  @Input() isFixedTerm?: boolean;
  @Input() last!: boolean;
  @Input() multiItemDeleteEnabled?: boolean;
  @Input() multipleExcessAvailable?: boolean;
  @Input() selectedPaymentTypeIsCard?: boolean;
  @Output()
  multiItemDelete: EventEmitter<Quote & { last: boolean }> = new EventEmitter();

  cardPaymentOption?: BasketItemPaymentOption;
  DDPaymentOption?: BasketItemPaymentOption;
  isEqualPayments?: boolean;
  periodOfCover?: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quote) {
      this.buildQuoteItemSummary();
    }
  }

  handleMultiItemDelete(): void {
    if (this.quote) {
      this.multiItemDelete.emit({ ...this.quote, last: this.last });
    }
  }

  private buildQuoteItemSummary(): void {
    this.cardPaymentOption = this.quote?.cover.paymentOptions.card;
    this.DDPaymentOption = this.quote?.cover.paymentOptions.directDebit;
    this.periodOfCover = this.quote?.cover.periodOfCover || 12;
    this.isEqualPayments =
      this.DDPaymentOption?.firstPayment ===
      this.DDPaymentOption?.subsequentPayment;
  }
}
