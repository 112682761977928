<ng-container *ngIf="productOptionArray?.length">
  <div class="payment-card" *ngFor="let paymentMethod of productOptionArray">
    <wl-payment-card
      [selected]="selected === paymentMethod.id"
      [payment]="paymentMethod"
      [selectable]="true"
      (selectionChange)="onSelectionChange($event)"
    >
    </wl-payment-card>
  </div>
</ng-container>
