<dgx-dfb-field-layout
  [id]="id"
  [label]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
  data-hook="dgx-sales-input"
>
  <div class="input-wrapper">
    <input
      #input
      [id]="id"
      [name]="field?.controlName"
      class="form-control"
      [type]="type"
      [dgxFdnQmSanitise]="field?.sanitise"
      [disabled]="disabled"
      [placeholder]="field?.placeholder"
      [value]="value$ | async"
      (input)="onChange($any($event))"
      (change)="onChange($any($event))"
      (keydown)="onKeyDown($event)"
      (blur)="onBlur($any($event))"
      autocomplete="off"
      [dgxDfbNumericMask]="isNumberType"
      [precision]="precision"
      [attr.maxlength]="maxLength"
    />
    <ui-form-validation-icon
      [validityStatus]="validity"
    ></ui-form-validation-icon>
  </div>
</dgx-dfb-field-layout>
