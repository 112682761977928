import {
  Basket,
  CoverType,
  CreateQuoteResponse,
  SelectedBasketItem,
} from '@common/util-models';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[QuotesEffects] Init');

export const createQuote = createAction(
  '[QuotesEffects] Create Quote: Request'
);

export const createQuoteSuccess = createAction(
  '[QuotesEffects] Create Quotes: Success',
  props<{
    quoteApiResult: CreateQuoteResponse;
    autoSubmitFlow?: boolean;
  }>()
);

export const createQuoteFailure = createAction(
  '[QuotesEffects] Create Quotes: Failure',
  props<{ error: Error }>()
);

export const selectExcessValue = createAction(
  '[QuoteComponent] Select Excess Amount',
  props<{ excessValue: number }>()
);

export const selectCoverType = createAction(
  '[QuoteComponent] Select Cover Type',
  props<{ coverType: CoverType }>()
);

export const selectFirstCoverType = createAction(
  '[Quote] Select first cover type from the available list of covers'
);

export const addToSelectedItemsRequest = createAction(
  '[Quote] Add Item To Multi Item Basket: Request',
  props<{
    selectedItem: SelectedBasketItem;
  }>()
);

export const addToSelectedItems = createAction(
  '[Quote] Add Item To Multi Item Basket',
  props<{
    selectedItem: SelectedBasketItem;
  }>()
);

export const replaceSelectedItemsWithSingleItem = createAction(
  '[Quote] Replace Items In Multi Item Basket',
  props<{
    selectedItem: SelectedBasketItem;
  }>()
);

export const removeSelectedItem = createAction(
  '[Quote] Remove Selected Item From Basket',
  props<{
    selectedItem: SelectedBasketItem;
  }>()
);

export const removeCurrentItem = createAction(
  '[Quote] Remove Current Item From Basket'
);

export const removeUnusedItems = createAction(
  '[Quote] Remove Unused Items From Basket: Request'
);

export const removeUnusedItemsSuccess = createAction(
  '[Quote] Remove Unused Items From Basket: Success',
  props<{
    basket: Basket;
  }>()
);

export const removeUnusedItemsFailure = createAction(
  '[Quote] Remove Unused Items From Basket: Failure',
  props<{
    error: Error;
  }>()
);

export const autoSelectCoverType = createAction(
  '[Quote] Preselect the initial and selected cover types',
  props<{ coverType: CoverType }>()
);

export const setInitialSelectCoverType = createAction(
  '[QuoteComponentService] Set the initial and selected cover types',
  props<{ coverType: CoverType }>()
);

export const autoSelectFromRouteParams = createAction(
  '[QuoteRouteParamsResolver] Store cover and excess route params for autoSelectCoverType later',
  props<{ coverType: CoverType; excess: number }>()
);

export const sendQuotesPageTags = createAction(
  '[QuotesEffects] Send Quotes Page Tag'
);

export const proceedToQuote = createAction(
  '[QuotesEffects] Proceed to Quotes page with multi item basket'
);

export const setCurrentItemIdToFirstSelectedItem = createAction(
  '[Quotes] Set Current Item Id To First Selected Item'
);

export const setMostRecentActionMessage = createAction(
  '[Quote] Set Most Recent Action Message',
  props<{ mostRecentActionMessage: string }>()
);

export const clearMostRecentActionMessage = createAction(
  '[Quote] Clear Most Recent Action Message'
);
