import { Inject, Injectable } from '@angular/core';
import { REQUEST_SOURCE } from '@common/util-foundation';
import { ConfigAdapter } from '@common/util-models';

@Injectable()
export class RequestSourceConfigAdapter implements ConfigAdapter {
  constructor(@Inject(REQUEST_SOURCE) private requestSource: string) {}

  getConfig(): { catalogueStore: string } {
    return {
      catalogueStore: this.requestSource,
    };
  }
}
