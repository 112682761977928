export const FAQS = {
  results: [
    {
      '@name': 'FAQ-1',
      '@path': '/sales/FAQ-1',
      '@id': '160ccd52-544a-487f-b1a0-98b4a3c4c059',
      '@nodeType': 'dgx-faq',
      'mgnl:tags': [],
      'jcr:createdBy': 'admin',
      'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
      answer:
        '<p>Household products such as washing machines, televisions, and fridges can be protected if they are less than eight years old. Boilers can be protected if they&#39;re less than 15-years old.</p>\n\n<p>If your product is already protected, there&#39;s no age limit on plan renewals.</p>\n',
      question: 'Is my product too old to be protected?',
      'jcr:created': '2021-03-23T15:21:50.038Z',
      'mgnl:created': '2021-03-23T14:59:18.799Z',
      'mgnl:createdBy': 'denislisitsyn',
      'jcr:predecessors': [''],
      'jcr:baseVersion': '',
      'mgnl:comment': '',
      'mgnl:lastModified': '2021-03-23T15:21:50.061Z',
      'jcr:isCheckedOut': 'true',
      jcrName: 'FAQ-1',
      'mgnl:lastModifiedBy': 'anonymous',
      'jcr:versionHistory': '',
      '@nodes': [],
    },
    {
      '@name': 'FAQ-2',
      '@path': '/sales/FAQ-2',
      '@id': '418f8dc1-e895-4221-bafd-4d8144add207',
      '@nodeType': 'dgx-faq',
      'mgnl:tags': [],
      'jcr:createdBy': 'admin',
      answer:
        '<p>If you have an appliance, you can get an insurance policy with continuous cover. If your appliance is in guarantee, the breakdown protection will start when the manufacturer&rsquo;s guarantee ends. The policy will continue indefinitely, but we will contact you every year to remind you about your cover. If you have a boiler, you can get a 12-month maintenance and support plan which will renew every year. Your boiler must be 15-years old or less at time of purchase. There&rsquo;s no product age limit on annual renewals. If you purchase an insurance policy or maintenance and support plan provided by us elsewhere, then how long it lasts may be different.</p>\n',
      'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
      question: 'How long can I protect my product for?',
      'jcr:created': '2021-03-23T15:47:42.452Z',
      'mgnl:created': '2021-03-23T15:46:35.970Z',
      'mgnl:createdBy': 'denislisitsyn',
      'jcr:predecessors': [''],
      'jcr:baseVersion': '',
      'mgnl:comment': '',
      'mgnl:lastModified': '2021-03-23T15:47:42.461Z',
      jcrName: 'FAQ-2',
      'jcr:isCheckedOut': 'true',
      'mgnl:lastModifiedBy': 'anonymous',
      'jcr:versionHistory': '',
      '@nodes': [],
    },
    {
      '@name': 'FAQ-3',
      '@path': '/sales/FAQ-3',
      '@id': '721fb392-96ea-44fc-be24-79f066280f65',
      '@nodeType': 'dgx-faq',
      'mgnl:tags': [],
      'jcr:createdBy': 'admin',
      'jcr:mixinTypes': ['mix:versionable', 'mgnl:hasVersion'],
      answer:
        '<p>We aim to get someone to you as soon as possible. Generally, we&rsquo;d expect an engineer to visit within 3-5 working days, however this may be a little longer depending on your area or at specific times of year.</p>\n',
      question:
        'If I have a problem, how long will an engineer take to arrive?',
      'jcr:created': '2021-03-23T15:50:35.703Z',
      'mgnl:created': '2021-03-23T15:50:21.822Z',
      'mgnl:createdBy': 'denislisitsyn',
      'jcr:predecessors': [''],
      'jcr:baseVersion': '',
      'mgnl:comment': '',
      'mgnl:lastModified': '2021-03-23T15:50:35.710Z',
      'jcr:isCheckedOut': 'true',
      jcrName: 'FAQ-3',
      'mgnl:lastModifiedBy': 'anonymous',
      'jcr:versionHistory': '',
      '@nodes': [],
    },
  ],
};
