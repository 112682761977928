import { BuildConfig } from '@common/util-models';
import { fromEvent } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { BuildConfigService } from '../../services';

export const cookieProInitializer = (
  document: Document,
  buildConfigService: BuildConfigService
): (() => Promise<Event>) => () => {
  return buildConfigService.config$
    .pipe(
      mergeMap((config: BuildConfig) => {
        const head = document.head;
        const script: HTMLScriptElement = document.createElement('script');
        script.src = config.cookieProSrc;
        script.setAttribute('data-domain-script', config.cookieProDomainScript);

        head?.insertBefore(script, head.childNodes[0]);
        return fromEvent(script, 'load');
      }),
      first()
    )
    .toPromise();
};
