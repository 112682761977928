import {
  Directive,
  EmbeddedViewRef,
  HostListener,
  Input,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { TooltipService } from './tooltip.service';

@Directive({
  selector: '[dgxDfbTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input('dgxDfbTooltip') tooltipTemplate!: TemplateRef<unknown>;
  @Input() tooltipName!: string;

  private renderedViewRef: EmbeddedViewRef<unknown> | undefined = undefined;

  constructor(private tooltipService: TooltipService) {}

  @HostListener('click')
  onClick() {
    if (!this.renderedViewRef) {
      this.renderedViewRef = this.tooltipService.attachTemplateToTarget(
        this.tooltipTemplate,
        this.tooltipName
      );
    } else {
      this.renderedViewRef = undefined;
      this.tooltipService.clear(this.tooltipName);
    }
  }

  ngOnDestroy(): void {
    this.tooltipService.clear(this.tooltipName);
  }
}
