import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContractType } from '@common/util-models';
import { QuoteDocument } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'sales-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent {
  @Input() termsAndConditionsDoc: QuoteDocument | undefined = undefined;
  @Input() ipidDoc: QuoteDocument | undefined = undefined;

  @Input() productType: ContractType | null = ContractType.Insurance;

  insurance = ContractType.Insurance;
  maintenance = ContractType.MSP;
}
