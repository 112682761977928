import { Injectable } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { TrustBox } from '@common/util-models';

@Injectable()
export class TrustboxService {
  constructor(private buildConfigService: BuildConfigService) {}

  getBusinessId(): string {
    return this.buildConfigService.config.trustPilot.businessUnitId;
  }

  getTemplateIdByType(type: TrustBox): string {
    const templates = this.buildConfigService.config.trustPilot.templates;
    const template = templates.find((x) => x.name === type);
    return template ? template.id : '';
  }
}
