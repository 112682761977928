import { ActivatedRoute, Data } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function routeDataFactory(
  dataKey: string
): (route: ActivatedRoute) => Observable<unknown> {
  return (route: ActivatedRoute): Observable<unknown> => {
    return route.data.pipe(map((data: Data) => data[dataKey]));
  };
}

export function routeParamFactory(
  paramKey: string
): (route: ActivatedRoute) => Observable<string | null> {
  return (route: ActivatedRoute): Observable<string | null> => {
    return route.paramMap.pipe(map((param) => param.get(paramKey)));
  };
}
