import {
  BasketItem,
  BasketItemQuoteDetails,
  CoverType,
  SelectedBasketItem,
} from '@common/util-models';
import { QuotesState } from './quotes.reducer';

/**
 * Helpers to extract legacy single item, single quote logic
 * and hold it in a place to highlight where refactor is likely to be needed later
 */

export function currentItem(state: QuotesState): BasketItem | undefined {
  return state.quoteApiResult?.basket.items.find(
    (item) => item.itemId === state.currentItemId
  );
}

export function firstQuoteInCurrentItem(
  state: QuotesState
): BasketItemQuoteDetails | undefined {
  return currentItem(state)?.data.quotes[0];
}

export function generateSelectedFirstItemQuoteByCoverType(
  state: QuotesState,
  coverType: CoverType
) {
  const currentSelected = currentSelectedItem(state);
  const currentExcess =
    currentItem(state)?.data.quotes.find(
      (quote) => quote.quoteId === currentSelected?.quoteId
    )?.excessAmount || 0;

  return {
    itemId: currentItem(state)?.itemId ?? '',
    quoteId:
      currentItem(state)?.data.quotes.find(
        (quote) =>
          quote.coverType === coverType && quote.excessAmount === currentExcess
      )?.quoteId ?? '',
  };
}

export function generateSelectedFirstItemQuote(state: QuotesState) {
  return {
    itemId: currentItem(state)?.itemId ?? '',
    quoteId: firstQuoteInCurrentItem(state)?.quoteId ?? '',
  };
}

export function generateSelectedItemQuoteBasedOnExcess(
  state: QuotesState,
  excessAmount: number
) {
  const currentSelected = currentSelectedItem(state);
  const currentCoverType = currentItem(state)?.data.quotes.find(
    (quote) => quote.quoteId === currentSelected?.quoteId
  )?.coverType;

  const quoteId =
    currentItem(state)?.data.quotes.find(
      (quote) =>
        quote.excessAmount === excessAmount &&
        quote.coverType === currentCoverType
    )?.quoteId ?? '';

  return <SelectedBasketItem>{
    itemId: currentSelected?.itemId,
    quoteId,
  };
}

export function currentSelectedItemQuote(state: QuotesState) {
  const currentSelected = currentSelectedItem(state);
  return state.quoteApiResult?.basket.items
    ?.find((item) => item.itemId === currentSelected?.itemId)
    ?.data.quotes.find((quote) => quote.quoteId === currentSelected?.quoteId);
}

export function currentSelectedItem(state: QuotesState) {
  return state.selectedItems.find(
    (selected) => selected.itemId === state.currentItemId
  );
}
