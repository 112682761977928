<dgx-uib-direct-debit-guarantee-dynamic title="The Direct Debit Guarantee">
  <p>
    Details of your Direct Debit will be sent to you within 3 working days, or
    no later than 10 working days before the first collection. Protection
    policies are provided by
    <strong>Domestic & General Insurance PLC</strong> and maintenance and
    support plans are provided by
    <strong>Domestic & General Services Limited</strong>.
  </p>
</dgx-uib-direct-debit-guarantee-dynamic>
