<ui-card
  *ngIf="payment"
  [selected]="selected"
  [ngClass]="{ selectable: selectable }"
  (click)="onClick()"
>
  <ui-card-header>
    <ui-card-title *ngIf="payment.title">{{ payment.title }}</ui-card-title>
    <ui-card-radio
      [selected]="selected"
      (valueChange)="isSelected($event)"
    ></ui-card-radio>
  </ui-card-header>
  <ui-card-body>
    <div class="price-container">
      <div *ngIf="isCard" class="price">
        One-time payment of £{{ payment.totalPrice || 0 | number: '1.2-2' }}
      </div>

      <div *ngIf="!isCard && !equalFirstAndSubsequentPayments" class="price">
        First payment of £{{ payment.firstMonthPayment || 0 | number: '1.2-2'
        }}<br />

        {{ payment.numSubsequentPayments }} monthly payments of £{{
          payment.perMonthPrice || 0 | number: '1.2-2'
        }}
      </div>

      <div *ngIf="!isCard && equalFirstAndSubsequentPayments" class="price">
        {{ (payment.numSubsequentPayments || 0) + 1 }} monthly payments of £{{
          payment.perMonthPrice || 0 | number: '1.2-2'
        }}
      </div>
    </div>
    <div class="totalCost">
      Total cost £{{ payment.totalPrice || 0 | number: '1.2-2' }}
    </div>
    <div *ngIf="discount" class="discount">
      Includes £{{ discount | number: '1.2-2' }}
      Direct Debit Discount
    </div>
    <div class="logo-container">
      <div *ngIf="!isCard">
        <span class="logo icon-direct-debit" title="Direct Debit"></span>
      </div>
      <div *ngIf="isCard">
        <span class="logo icon-card-issuer-visa" title="Visa Card"></span>
        <span
          class="logo icon-card-issuer-visa-debit"
          title="Visa Debit Card"
        ></span>
        <span
          class="logo icon-card-issuer-master-card"
          title="Master Card"
        ></span>
        <span class="logo icon-card-issuer-maestro" title="Maestro Card"></span>
      </div>
    </div>
  </ui-card-body>
</ui-card>
