<div
  class="hero"
  [ngStyle]="{ 'background-image': 'url(' + content?.image?.url + ')' }"
>
  <div class="left-content">
    <div
      id="trustbox-micro"
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="4cb3113600006400050dfb02"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    ></div>
  </div>
  <div class="right-content">
    <h3>{{ content?.text }}</h3>
  </div>
</div>
