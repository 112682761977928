<ng-container *ngIf="default404ErrorPageData$ | async as errorPageData">
  <div class="error">
    <div class="error__content text--center container">
      <h2 class="error__header">{{ errorPageData.header }}</h2>
      <p class="error__support">{{ errorPageData.support }}</p>
    </div>
    <div class="error__btn_container">
      <a [href]="contactUs" class="btn btn--secondary btn--outline">
        Contact us
      </a>
      <a class="btn btn--primary" [href]="home">
        <span class="label">Home</span>
        <i class="icon icon--md icon-arrow-right"></i>
      </a>
    </div>
  </div>
  <div class="error__code"><p>Error code:&nbsp;404</p></div>
</ng-container>
