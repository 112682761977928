export enum TrustBox {
  MICRO = 'micro',
  SLIDER = 'slider',
}

export interface TrustBoxTemplate {
  id: string;
  name: TrustBox;
}

export interface TrustBoxConfig {
  businessUnitId: string;
  templates: TrustBoxTemplate[];
}
