import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageComponentService {
  getData(key: string) {
    return sessionStorage.getItem(key);
  }

  setData(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }
}
