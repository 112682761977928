import { FormGroup, ValidatorFn } from '@angular/forms';

export enum ParseResult {
  Success,
  Failure,
  NotApplied,
}

export interface ParseResponse<T> {
  model?: Partial<T>;
  result: ParseResult;
  form?: FormGroup;
}

export type ParseRequest<T> = {
  [P in keyof T]: ValidatorFn[];
};
