<ng-container *ngIf="errorPageData$ | async as errorPageData">
  <div class="error">
    <div class="container">
      <div class="error__content text--center">
        <h2 class="error__header">{{ errorPageData.header }}</h2>
        <p class="error__support">{{ errorPageData.support }}</p>
        <p
          class="error__support error__support--additional"
          *ngIf="errorPageData.additionalSupport"
        >
          {{ errorPageData.additionalSupport }}
        </p>

        <ng-container
          *ngIf="errorPageData.link; else callCustomerService"
          [ngTemplateOutlet]="
            errorPageData.link.routerPath
              ? internalErrorLink
              : externalErrorLink
          "
          [ngTemplateOutletContext]="{ errorPageData: errorPageData }"
        ></ng-container>

        <ng-template #callCustomerService>
          <sales-customer-service-contact
            [contactHelpNumber]="contactHelpNumber"
            [title]="errorPageData.contactCustomerService"
          ></sales-customer-service-contact>
        </ng-template>
      </div>
    </div>
  </div>
  <sales-helpful-links
    [home]="home"
    [faq]="faq"
    [contact]="contact"
  ></sales-helpful-links>
</ng-container>

<ng-template #internalErrorLink let-errorPageData="errorPageData">
  <a [routerLink]="errorPageData.link.routerPath" class="btn btn--primary my-1">
    <span class="label"> {{ errorPageData.link.label }}</span>
    <i class="icon icon-arrow-right"></i>
  </a>
</ng-template>

<ng-template #externalErrorLink let-errorPageData="errorPageData">
  <a
    [href]="errorPageData.link.href"
    class="btn btn--primary my-1"
    target="_blank"
  >
    <span class="label"> {{ errorPageData.link.label }}</span>
    <i class="icon icon-arrow-right"></i>
  </a>
</ng-template>
