import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { APPLIANCE_DETAILS_FORM_DATA_PROVIDER } from '@common/util-foundation';
import {
  ApplianceDetailsFormDataProvider,
  ApplianceFormSubmit,
  ParseResponse,
  ParseResult,
} from '@common/util-models';
import {
  combineLatest,
  MonoTypeOperatorFunction,
  NEVER,
  Observable,
  of,
  pipe,
} from 'rxjs';
import { map, switchAll, take, tap } from 'rxjs/operators';
import { ApplianceDetailsFacade } from '../+state/appliance-details.facade';

@Injectable({
  providedIn: 'root',
})
export class ApplianceDetailsFormDataResolver
  implements
    Resolve<Observable<ParseResponse<ApplianceFormSubmit> | undefined>> {
  constructor(
    @Inject(APPLIANCE_DETAILS_FORM_DATA_PROVIDER)
    private applianceFormDataProviders: ApplianceDetailsFormDataProvider[],
    private applianceDetailsFacade: ApplianceDetailsFacade
  ) {}

  resolve(): Observable<ParseResponse<ApplianceFormSubmit> | undefined> {
    const formDataProviders$ = this.applianceFormDataProviders
      .sort((a, b) => a.priority - b.priority)
      .map((applianceFormDataProvider) => {
        return applianceFormDataProvider.getApplianceDetailsForm();
      });

    return combineLatest(formDataProviders$).pipe(
      take(1),
      map((formDataResponses) => {
        return formDataResponses.find(
          (response) => response.result !== ParseResult.NotApplied
        );
      }),
      this.submitQuoteIfParsingSuccessful(),
      this.checkParsingSuccess()
    );
  }

  private submitQuoteIfParsingSuccessful(): MonoTypeOperatorFunction<
    ParseResponse<ApplianceFormSubmit> | undefined
  > {
    return pipe(
      tap((parsedResponse) => {
        if (
          parsedResponse?.result === ParseResult.Success &&
          parsedResponse?.model
        ) {
          this.applianceDetailsFacade.createQuote(
            parsedResponse.model as ApplianceFormSubmit,
            true
          );
        } else {
          if (parsedResponse?.model) {
            this.applianceDetailsFacade.fillFormWithQueryParams(
              parsedResponse.model as ApplianceFormSubmit
            );
          }
        }
      })
    );
  }

  private checkParsingSuccess(): MonoTypeOperatorFunction<
    ParseResponse<ApplianceFormSubmit> | undefined
  > {
    return pipe(
      map((parsedResponse) =>
        parsedResponse?.result === ParseResult.Success
          ? NEVER
          : of(parsedResponse)
      ),
      switchAll()
    );
  }
}
