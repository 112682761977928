import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
})
export class HeroBannerComponent {
  @Input() title = '';
  @Input() body = '';
}
