import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormStatus } from '../_shared/interfaces/angular-forms-typed.interface';
import { GenericFieldDef } from '../_shared/interfaces/dynamic-formbuilder.interface';

@UntilDestroy()
@Component({
  selector: 'dgx-dfb-form-element-base',
  templateUrl: './form-element-base.component.html',
  styleUrls: ['./form-element-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormElementBaseComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() field!: GenericFieldDef;
  @Input() validate = false;
  @Output() analytics = new EventEmitter<AnalyticsData>();

  errorMessage!: string;

  get validity(): string {
    if (
      !this.ngControl ||
      this.ngControl.disabled ||
      this.ngControl.untouched
    ) {
      return 'untouched';
    } else if (this.ngControl.valid) {
      return 'valid';
    }
    return 'invalid';
  }

  onTouched: () => void = () => {
    return undefined;
  };

  onChanged: (val: unknown) => void = () => {
    return undefined;
  };

  constructor(@Optional() public ngControl: NgControl) {
    if (ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      ngControl.valueAccessor = this;
    }
  }

  /**
   * Note: call super.ngOnInit() first in ngOnInit() on inherited
   * components so that the 'field' @Input gets validated before spin up
   */
  ngOnInit(): void {
    this.validateFieldInput();

    if (this.field?.initialValue && this.ngControl?.control) {
      this.writeValue(this.field.initialValue);
      this.ngControl.control.markAsTouched();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validate?.currentValue === true) {
      this.updateErrorMessage();
    }
  }

  writeValue(val: unknown, emitAnalytics: boolean = false): void {
    this.onChanged(val);
    this.onTouched();
    this.updateErrorMessage();
    if (emitAnalytics) {
      this.emitAnalyticsData(val);
    }
  }

  emitAnalyticsData(val: unknown) {
    if (this.field.controlName) {
      this.analytics.emit({
        controlName: this.field.controlName,
        value: val,
        error: this.errorMessage,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnChange(_fn: (val: unknown) => void): void {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnTouched(_fn: () => void): void {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState?(_state: boolean): void {
    throw new Error('Method not implemented.');
  }

  protected validateFieldInput() {
    if (!this.field) {
      throw new Error(
        `'field' is a required input to FormElementBaseComponent`
      );
    }
  }

  /**
   * Feed errorMessage into dgx-dfb-error message component input
   * as required. Set to null when not.
   */
  protected updateErrorMessage() {
    const control = this.ngControl?.control;
    if (control?.touched && <FormStatus>control?.status === 'INVALID') {
      const firstErrorType = control.errors
        ? Object.keys(control.errors)[0]
        : null;
      this.errorMessage =
        this.field?.validationMessages?.find(
          (msg) =>
            msg.type.toLocaleLowerCase() === firstErrorType?.toLocaleLowerCase()
        )?.message || '';
    } else {
      this.errorMessage = '';
    }
  }
}
