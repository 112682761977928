import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sales-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  @Input() title!: string | null | undefined;
  @Input() subTitle!: string | null | undefined;
}
