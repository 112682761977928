import { Injectable } from '@angular/core';
import { PersonalDetailsPartialState } from '@common/data-access-personal-details';
import { CheckoutBasketPartialState } from '@common/data-access-checkout-basket';
import { UserProfilePartialState } from '@common/data-access-user-profile';
import { select, Store } from '@ngrx/store';
import { CardPaymentPartialState } from './card-payment.reducer';
import * as CardPaymentSelectors from './card-payment.selectors';

/**
 * This will only contain selectors that we need to reuse across pages. This will ensure that we do not duplicate selectors across
 * pages going forward in each and every facade. There will never be methods here that dispatch actions to the store. These
 * will be injected across various feature pages/ component services/ state services. So having methods here that dispatch actions
 * will lead to action reuse and use of actions as commands. We want to avoid actions reuse and have unique actions for every specific
 * event that happens across pages.
 *
 * @export
 * @class CardPaymentCommonSelectorsFacade
 */
@Injectable()
export class CardPaymentCommonSelectorsFacade {
  cardPaymentOrderNumber$ = this.store.pipe(
    select(CardPaymentSelectors.getCardPaymentOrderNumber)
  );

  constructor(
    private store: Store<
      PersonalDetailsPartialState &
        CheckoutBasketPartialState &
        UserProfilePartialState &
        CardPaymentPartialState
    >
  ) {}
}
