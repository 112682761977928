import { FieldDef } from '../../interfaces';

export function patchFieldDefinition<T extends FieldDef>(
  fieldDefinition: T,
  updatedFieldDefinition: Partial<T>
): T {
  return {
    ...fieldDefinition,
    ...updatedFieldDefinition,
  };
}

export function patchFieldDefinitions<T extends FieldDef>(
  fieldDefinitions: FieldDef[],
  fieldDefinitionControlName: string,
  updatedFieldDefinition: Partial<T>
): FieldDef[] {
  return fieldDefinitions.map((fieldDefinition: FieldDef) => {
    if (fieldDefinition.controlName === fieldDefinitionControlName) {
      return patchFieldDefinition<T>(
        fieldDefinition as T,
        updatedFieldDefinition
      );
    }
    return fieldDefinition;
  });
}
