import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { ENVIRONMENT } from '@common/util-foundation';
import { Environment } from '@common/util-models';

@Component({
  selector: 'wl-checkout-banner',
  templateUrl: './checkout-banner.component.html',
  styleUrls: ['./checkout-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutBannerComponent {
  @Input() set multiItemQuotesCount(count: number | undefined) {
    this.hideLink = !count || count === 0;
    this.hideSubtitle =
      !count ||
      count === 0 ||
      count >= (this.environment.multiBasketMaximum ?? 10);
  }
  @Input() title?: string;
  @Output() handleClick: EventEmitter<void> = new EventEmitter();

  hideLink?: boolean = true;
  hideSubtitle?: boolean = true;

  constructor(@Inject(ENVIRONMENT) private readonly environment: Environment) {}

  onClick(): void {
    this.handleClick.emit();
  }
}
