import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { SharedUiCmsModule } from '@domgen/dgx-fe-content-management';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { ApplianceDetailsFormHeaderComponent } from './appliance-details-form-header/appliance-details-form-header.component';
import { ApplianceDetailsFormComponent } from './appliance-details-form/appliance-details-form.component';
import { CoverageBannerComponent } from './coverage-banner/coverage-banner.component';
import { InformationHeaderBarComponent } from './information-header-bar/information-header-bar.component';
import { SafeHandsComponent } from './safe-hands/safe-hands.component';
import { WelcomeBackMessageComponent } from './welcome-back-message/welcome-back-message.component';
import { WhyGetProtectedComponent } from './why-get-protected/why-get-protected.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUiDynamicFormBuilderModule,
    SharedUiCmsModule,
    SharedUiComponentsCoreModule,
  ],
  declarations: [
    ApplianceDetailsFormComponent,
    ApplianceDetailsFormHeaderComponent,
    CoverageBannerComponent,
    InformationHeaderBarComponent,
    SafeHandsComponent,
    WelcomeBackMessageComponent,
    WhyGetProtectedComponent,
  ],
  exports: [
    ApplianceDetailsFormComponent,
    ApplianceDetailsFormHeaderComponent,
    CoverageBannerComponent,
    InformationHeaderBarComponent,
    SafeHandsComponent,
    WelcomeBackMessageComponent,
    WhyGetProtectedComponent,
  ],
})
export class ApplianceDetailsUiModule {}
