import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'dgx-uib-direct-debit-guarantee',
  templateUrl: './direct-debit-guarantee.component.html',
  styleUrls: ['./direct-debit-guarantee.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectDebitGuaranteeComponent {}
