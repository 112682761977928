import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'wl-direct-debit-guarantee',
  templateUrl: './direct-debit-gurantee.component.html',
  styleUrls: ['./direct-debit-gurantee.component.scss'],
})
export class DirectDebitGuranteeComponent implements OnChanges {
  orgName = '';
  notificationDays = 0;
  @Input() isInsurance: boolean | null = false;
  ngOnChanges() {
    this.orgName = this.isInsurance
      ? ' Domestic & General Insurance PLC'
      : 'Domestic & General Services Limited';

    this.notificationDays = this.isInsurance ? 14 : 10;
  }
}
