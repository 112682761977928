<footer>
  <div class="footer__main container">
    <div class="links xs-12">
      <div class="links__group">
        <div class="links__col">
          <h4>Products we protect</h4>
          <ul class="mb-2">
            <li *ngFor="let link of protectLinks">
              <ng-container
                [ngTemplateOutlet]="footerNavigationLink"
                [ngTemplateOutletContext]="{
                  navigationLink: link
                }"
              ></ng-container>
            </li>
          </ul>
        </div>
        <div class="links__col--separator"></div>
        <div class="links__col">
          <h4>Useful links</h4>
          <ul class="links__columns">
            <li *ngFor="let link of usefulLinks">
              <ng-container
                [ngTemplateOutlet]="footerNavigationLink"
                [ngTemplateOutletContext]="{
                  navigationLink: link
                }"
              ></ng-container>
            </li>
            <li *ngIf="usefulLinks.length % 2" class="empty-list"></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer__disclaimers xs-12">
      <p>
        Insurance policies are provided by Domestic & General Insurance PLC.
        Domestic & General Insurance PLC is an insurance undertaking, not an
        intermediary. We are the underwriter of the insurance policies and do
        not provide a personal recommendation or advice. Domestic & General
        Insurance PLC is authorised by the Prudential Regulation Authority and
        regulated by the Financial Conduct Authority and the Prudential
        Regulation Authority (Financial Services Register Number 202111). Our
        address and details of our authorisation can be checked on the
        <a
          href="https://register.fca.org.uk/"
          target="_blank"
          (click)="handleLinkClick($event, 'FCA website')"
          >FCA website</a
        >
        or by contacting the FCA on 0800 111 6768.
      </p>
    </div>
  </div>
  <div class="footer__legal">
    <div class="container">
      <div class="xs-12 footer__legal-inner">
        <div class="footer__copyright">
          &copy; {{ currentYear }} Domestic & General Group Limited
        </div>
        <div class="footer__legal-filler"></div>
        <div class="footer__legal-links">
          <ng-container *ngFor="let link of legalLinks">
            <ng-container
              [ngTemplateOutlet]="footerNavigationLink"
              [ngTemplateOutletContext]="{
                navigationLink: link
              }"
            ></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</footer>
<ng-template #footerNavigationLink let-navigationLink="navigationLink">
  <a
    *ngIf="navigationLink.routerPath; else externalFooterNavigationLink"
    [routerLink]="[navigationLink.routerPath]"
    (click)="handleLinkClick($event, navigationLink.label)"
  >
    {{ navigationLink.label }}
  </a>

  <ng-template #externalFooterNavigationLink>
    <a
      [href]="navigationLink.href"
      [attr.rel]="navigationLink.newWindow ? 'noopener noreferrer' : undefined"
      [attr.target]="navigationLink.newWindow ? '_blank' : undefined"
      (click)="handleLinkClick($event, navigationLink.label)"
    >
      {{ navigationLink.label }}
    </a>
  </ng-template>
</ng-template>
