import * as deepEqual from 'fast-deep-equal';
import { MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

// These have to be duplicated here as this is a publishable library and cannot depend on common.
export function distinctUntilChangedDeep<T>(): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged((previous: T, current: T) => {
    return deepEqual(previous, current);
  });
}
