import { ActionReducer } from '@ngrx/store';
import { LocalStorageConfig, localStorageSync } from 'ngrx-store-localstorage';

export function localStorageSyncReducer(
  reducer: ActionReducer<Record<string, unknown>>
): ActionReducer<Record<string, unknown>> {
  return localStorageSync({
    keys: [],
    storage: sessionStorage,
    rehydrate: true,
  } as LocalStorageConfig)(reducer);
}
