import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { CoverStartingDateInfoDynamicComponent } from './cover-starting-date-info/cover-starting-date-info-dynamic/cover-starting-date-info-dynamic.component';
import { CoverStartingDateInfoComponent } from './cover-starting-date-info/cover-starting-date-info.component';
import { DirectDebitFormComponent } from './direct-debit-form/direct-debit-form.component';
import { DirectDebitGuaranteeDynamicComponent } from './direct-debit-guarantee/direct-debit-guarantee-dynamic/direct-debit-guarantee-dynamic.component';
import { DirectDebitGuaranteeComponent } from './direct-debit-guarantee/direct-debit-guarantee.component';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';

@NgModule({
  imports: [
    CommonModule,
    SharedUiDynamicFormBuilderModule,
    SharedUiComponentsCoreModule,
  ],
  declarations: [
    DirectDebitFormComponent,
    DirectDebitGuaranteeComponent,
    CoverStartingDateInfoComponent,
    DirectDebitGuaranteeDynamicComponent,
    CoverStartingDateInfoDynamicComponent,
  ],
  exports: [
    DirectDebitFormComponent,
    DirectDebitGuaranteeComponent,
    CoverStartingDateInfoComponent,
    DirectDebitGuaranteeDynamicComponent,
    CoverStartingDateInfoDynamicComponent,
  ],
})
export class DirectDebitUiModule {}
