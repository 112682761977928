import { FormGroup } from '@angular/forms';
import { of, OperatorFunction, pipe } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { FieldDef } from '../../interfaces/dynamic-formbuilder.interface';
import { Normalize } from '../../interfaces/normalize.interface';
import {
  normalizeFormValidationMessages,
  normalizeFormValidationMessagesFromConfig,
} from '../../utils/normalize-form-validation-message/normalize-form-validation-messages.utils';
import { distinctUntilChangedDeep } from '../distinct-until-changed-deep/distinct-until-changed-deep';

export function formValidationMessages(): OperatorFunction<
  [FormGroup | null | undefined, FieldDef[]],
  Normalize<Normalize<string>>
> {
  return pipe(
    switchMap(([form, fieldDefinitions]) => {
      const validationMessages = normalizeFormValidationMessagesFromConfig(
        fieldDefinitions
      );

      if (!form) {
        return of({});
      }

      return form.valueChanges.pipe(
        startWith(form?.getRawValue()),
        map(() => normalizeFormValidationMessages(validationMessages, form)),
        distinctUntilChangedDeep()
      );
    })
  );
}
