import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { QuoteCms } from '@common/util-models';

@Component({
  selector: 'sales-boiler-total-month-plan',
  templateUrl: './boiler-total-month-plan.component.html',
  styleUrls: ['./boiler-total-month-plan.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoilerTotalMonthPlanComponent {
  @Input() cmsData!: QuoteCms | null | undefined;
  @Input() periodOfCover!: number;

  get textHeading() {
    return this.cmsData && this.cmsData.planDurationHeading
      ? this.cmsData.planDurationHeading
      : `Your ${this.periodOfCover}-month plan`;
  }

  get textBody() {
    return this.cmsData && this.cmsData.planDurationBody
      ? this.cmsData.planDurationBody
      : `If your boiler is in guarantee, the plan starts at the end of the guarantee and lasts for ${this.periodOfCover} months.
      If it’s not in guarantee, the plan starts once we have processed your application.
      Paying by Direct Debit ensures automatic renewal each year.
      Don’t worry, we’ll write to you before your renewal date.`;
  }
}
