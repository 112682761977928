<dgx-dfb-field-layout
  [id]="id"
  [legend]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
  class="form-group yes-no-radio"
>
  <div [class.yes-no-radio__options]="true" [ngClass]="field?.classes || []">
    <ng-container *ngFor="let option of options">
      <label
        class="yes-no-radio__option"
        [ngClass]="{
          'yes-no-radio__option--active':
            value === option.value ||
            (field.initialValue === option.value && !isDirty),
          'yes-no-radio__option--inactive': value !== option.value,
          'yes-no-radio__option--invalid': !!errorMessage
        }"
      >
        <input
          class="form-control yes-no-radio__input"
          type="radio"
          [name]="field?.controlName"
          [value]="option.value"
          [attr.checked]="
            value === option.value ||
            (field.initialValue === option.value && isDirty)
              ? ''
              : null
          "
          [disabled]="disabled"
          (click)="onSelectChange(option.value)"
        />
        <span>{{ option.label }}</span>
      </label>
    </ng-container>
  </div>
</dgx-dfb-field-layout>
