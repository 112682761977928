import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DirectDebit,
  DirectDebitDetails,
  isRemoteDataError,
  PaymentDetails,
  RemoteData,
} from '@common/util-models';
import {
  UserProfilePartialState,
  UserProfileState,
  USERPROFILE_FEATURE_KEY,
} from './user-profile.reducer';

export const getUserProfileState = createFeatureSelector<
  UserProfilePartialState,
  UserProfileState
>(USERPROFILE_FEATURE_KEY);

export const getDirectDebitPersonalDetailsRemoteState = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state.remoteStateDirectDebitPersonalDetails
);

export const getDirectDebitPersonalDetailsError = createSelector(
  getDirectDebitPersonalDetailsRemoteState,
  (remoteState: RemoteData) => {
    if (isRemoteDataError(remoteState)) {
      return remoteState.error;
    }
    return undefined;
  }
);

export const getPersonalDetails = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state?.person
);

export const getDirectDebitDetails = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state?.directDebitDetails
);

export const getNewPaymentDay = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state?.newPaymentDay
);

export const getDirectDebitDetailsWithPaymentDayChanges = createSelector(
  getUserProfileState,
  getNewPaymentDay,
  (state: UserProfileState, newPaymentDay: number | null | undefined) => {
    return {
      ...state?.directDebitDetails,
      bankDetails: {
        ...state?.directDebitDetails?.bankDetails,
        nominatedPerDay:
          newPaymentDay ||
          state?.directDebitDetails?.bankDetails?.nominatedPerDay,
      },
    } as DirectDebitDetails;
  }
);

export const getPaperlessPlanDocumentPreference = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state?.paperlessPlanDocuments
);

export const isPaperlessPlanDocumentPreferenceSet = createSelector(
  getUserProfileState,
  (state: UserProfileState) =>
    state.paperlessPlanDocuments !== undefined &&
    state.paperlessPlanDocuments !== null
);

export const getNewDirectDebitDetails = createSelector(
  getUserProfileState,
  (state: UserProfileState) => state?.newDirectDebitDetails
);

export const newPaymentDetailsPresent = createSelector(
  getNewDirectDebitDetails,
  (newDirectDebitDetails: DirectDebit | null | undefined) => {
    return !!newDirectDebitDetails;
  }
);

export const savedPaymentDetailsPresent = createSelector(
  getDirectDebitDetails,
  (directDebitDetails: DirectDebitDetails | undefined) => {
    return !!directDebitDetails?.paymentToken;
  }
);

export const getNewOrSavedDirectDebitPaymentDetails = createSelector(
  newPaymentDetailsPresent,
  savedPaymentDetailsPresent,
  getDirectDebitDetailsWithPaymentDayChanges,
  getNewDirectDebitDetails,
  (
    newPaymentDetailsPresent,
    savedPaymentDetailsPresent,
    savedPaymentDetails,
    newPaymentDetails
  ) => {
    if (newPaymentDetailsPresent) {
      return {
        accountName: newPaymentDetails?.accountName,
        accountNumber: newPaymentDetails?.accountNumber,
        sortCode: newPaymentDetails?.sortCode,
        paymentDay: newPaymentDetails?.monthlyPaymentDay,
        paymentToken: undefined,
      } as PaymentDetails;
    }

    if (savedPaymentDetailsPresent) {
      return {
        accountName: savedPaymentDetails?.bankDetails.accountName,
        accountNumber: savedPaymentDetails?.bankDetails.accountNo,
        sortCode: savedPaymentDetails?.bankDetails.sortCode,
        paymentDay: savedPaymentDetails?.bankDetails.nominatedPerDay,
        paymentToken: savedPaymentDetails?.paymentToken,
      } as PaymentDetails;
    }

    return undefined;
  }
);

export const getPreferredPaymentDay = createSelector(
  getNewOrSavedDirectDebitPaymentDetails,
  (state) => state?.paymentDay
);
