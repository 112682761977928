import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PersonalDetailsPartialState } from '@common/data-access-personal-details';
import { CheckoutBasketPartialState } from '@common/data-access-checkout-basket';
import { UserProfilePartialState } from '@common/data-access-user-profile';
import { CardPaymentPartialState } from '../+state/card-payment.reducer';
import * as CardPaymentSelectors from '../+state/card-payment.selectors';
import * as CardPaymentActions from '../+state/card-payment.actions';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import {
  isRemoteDataError,
  isRemoteDataNotFetched,
  isRemoteDataOK,
  RemoteData,
} from '@common/util-models';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';

@Injectable()
export class CardPaymentSetupResolver implements Resolve<RemoteData> {
  constructor(
    private store$: Store<
      PersonalDetailsPartialState &
        CheckoutBasketPartialState &
        UserProfilePartialState &
        CardPaymentPartialState
    >
  ) {}

  resolve() {
    this.store$.dispatch(CardPaymentActions.cardPaymentResolverEnter());

    return this.store$.pipe(
      select(CardPaymentSelectors.getCardPaymentSetupRemoteState),
      this.filterNotFetched(),
      take(1),
      tap(() => {
        this.store$.dispatch(CardPaymentActions.setupCardPayment());
      }),
      switchMap(() =>
        this.store$.pipe(
          select(CardPaymentSelectors.getCardPaymentSetupRemoteState)
        )
      ),
      this.filterSuccessOrError(),
      take(1)
    );
  }

  private filterNotFetched(): MonoTypeOperatorFunction<RemoteData> {
    return pipe(
      filter((cardPaymentSetupRemoteState) => {
        if (isRemoteDataNotFetched(cardPaymentSetupRemoteState)) {
          return true;
        }
        return false;
      })
    );
  }

  private filterSuccessOrError(): MonoTypeOperatorFunction<RemoteData> {
    return pipe(
      filter((cardPaymentSetupRemoteState) => {
        if (
          isRemoteDataOK(cardPaymentSetupRemoteState) ||
          isRemoteDataError(cardPaymentSetupRemoteState)
        ) {
          return true;
        }
        return false;
      })
    );
  }
}
