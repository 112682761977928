import { ofType } from '@ngrx/effects';
import { ActionCreator, Creator, Action } from '@ngrx/store';
import { Observable, of, OperatorFunction } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

export function ofTypeSequence<V extends Action>(
  ...allowedTypes: ActionCreator<string, Creator>[]
): OperatorFunction<Action, V>;

export function ofTypeSequence<
  A extends ActionCreator<string, Creator>,
  T1 extends A,
  V extends ReturnType<Extract<T1, A>>
>(t1: T1): OperatorFunction<Action, V>;

export function ofTypeSequence<
  A extends ActionCreator<string, Creator>,
  T1 extends A,
  T2 extends A,
  V extends ReturnType<Extract<T2, A>>
>(t1: T1, t2: T2): OperatorFunction<Action, V>;

export function ofTypeSequence<
  A extends ActionCreator<string, Creator>,
  T1 extends A,
  T2 extends A,
  T3 extends A,
  V extends ReturnType<Extract<T3, A>>
>(t1: T1, t2: T2, t3: T3): OperatorFunction<Action, V>;

export function ofTypeSequence<
  A extends ActionCreator<string, Creator>,
  T1 extends A,
  T2 extends A,
  T3 extends A,
  T4 extends A,
  V extends ReturnType<Extract<T4, A>>
>(t1: T1, t2: T2, t3: T3, t4: T4): OperatorFunction<Action, V>;

export function ofTypeSequence<
  A extends ActionCreator<string, Creator>,
  T1 extends A,
  T2 extends A,
  T3 extends A,
  T4 extends A,
  T5 extends A,
  V extends ReturnType<Extract<T5, A>>
>(t1: T1, t2: T2, t3: T3, t4: T4, t5: T5): OperatorFunction<Action, V>;

export function ofTypeSequence<V extends Action>(
  ...allowedTypes: ActionCreator<string, Creator>[]
): OperatorFunction<Action, V> {
  const firstAllowedType = allowedTypes.shift();

  return function (source: Observable<Action>): Observable<V> {
    if (!firstAllowedType) {
      return of();
    }

    return source.pipe(
      ofType<V>(firstAllowedType),
      switchMap((action: V) => {
        return allowedTypes.length
          ? source.pipe(ofTypeSequence<V>(...allowedTypes), take(1))
          : of(action);
      })
    );
  };
}
