import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateWithOrdinal',
})

//reference from https://github.com/builtbyjay/ngx-date-with-ordinal-pipe
export class DateWithOrdinalPipe extends DatePipe implements PipeTransform {
  transform(
    value: Date | string | number,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null;
  transform(
    value: null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): null;
  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null;
  transform(
    value: Date | string | number | null | undefined,
    format = 'mediumDate',
    timezone?: string,
    locale?: string
  ): string | null {
    if (format) {
      const dayOfMonth: string | null = super.transform(value, 'd');
      const ordinal: string = this.ordinal(dayOfMonth);
      format = format.replace(/o/g, `'${ordinal}'`);
    }
    return super.transform(value, format, timezone, locale);
  }

  private ordinal(input: string | null): string {
    if (input === null) {
      return '';
    }
    const n: number = parseInt(input, 10) % 100;
    return n === 0
      ? 'th'
      : n < 11 || n > 13
      ? ['st', 'nd', 'rd', 'th'][Math.min((n - 1) % 10, 3)]
      : 'th';
  }
}
