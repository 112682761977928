import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { TooltipService } from '../../directives/tooltip/tooltip.service';

@Component({
  selector: 'dgx-dfb-field-layout',
  templateUrl: './field-layout.component.html',
  styleUrls: ['./field-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TooltipService],
})
export class FieldLayoutComponent implements OnInit {
  @Input() id?: string;
  @Input() label?: string;
  @Input() legend?: string;
  @Input() tooltip?: string;
  @Input() hint?: string;
  @Input() errorMessage?: string;
  @HostBinding('class.form-group') private fromGroupCls = true;

  tooltipName = '';

  public ngOnInit(): void {
    this.tooltipName = `${this.id || ''} tooltip`;
  }
}
