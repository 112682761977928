import { Injectable } from '@angular/core';
import { FeatureConfigFacade } from '@common/data-access-feature-config';
import {
  ApplianceDetailsFormDataProvider,
  ApplianceFormSubmit,
  ParseResponse,
  ParseResult,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApplianceDetailsStoreFeatureConfigFormDataProvider
  implements ApplianceDetailsFormDataProvider {
  priority = 1;
  constructor(private featureConfigFacade: FeatureConfigFacade) {}

  getApplianceDetailsForm(): Observable<ParseResponse<ApplianceFormSubmit>> {
    return this.featureConfigFacade.applianceDetails$.pipe(
      map((applianceDetailsConfig) => {
        return applianceDetailsConfig?.applianceDetailsFormData
          ? {
              result: ParseResult.Success,
              model: applianceDetailsConfig.applianceDetailsFormData,
            }
          : { result: ParseResult.NotApplied };
      })
    );
  }
}
