import { Brand } from '@common/util-models';
import { createAction, props } from '@ngrx/store';

export const loadBrands = createAction(
  '[Appliance Details] Load brands for appliance',
  props<{ code: string }>()
);

export const loadBrandsSuccess = createAction(
  '[Appliance Details] Brands for appliance loaded successfully',
  props<{ brands: Brand[] }>()
);

export const loadBrandsFailure = createAction(
  '[Appliance Details] Brands for appliance failed',
  props<{ error: Error }>()
);

export const resetBrands = createAction('[Appliance Details] Reset Brands');
