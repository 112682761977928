import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  Inject,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type RetryCardPaymentResult = 'Try Again' | 'Pay By DirectDebit';

@Component({
  selector: 'sales-retry-card-payment',
  templateUrl: './retry-card-payment.component.html',
  styleUrls: ['./retry-card-payment.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetryCardPaymentComponent {
  @Input() showDirectDebitOption = true;

  constructor(
    private dialogRef: MatDialogRef<
      RetryCardPaymentComponent,
      RetryCardPaymentResult
    >,
    @Inject(MAT_DIALOG_DATA)
    data: { showDirectDebitOption: boolean }
  ) {
    this.showDirectDebitOption = data?.showDirectDebitOption ?? true;
  }

  handleTryAgain() {
    this.dialogRef.close('Try Again');
  }

  handlePayByDirectDebit() {
    this.dialogRef.close('Pay By DirectDebit');
  }
}
