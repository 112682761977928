import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';
import { createAction, props } from '@ngrx/store';

export const setAuthStateFromCookieLoggedIn = createAction(
  '[Auth Lib] Get auth state from cookie - is logged in',
  props<{ jwt: User }>()
);

export const setAuthStateFromCookieLoggedOut = createAction(
  '[[Auth Lib] Get auth state from cookie - is logged out'
);
