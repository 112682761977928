import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { CoversExclusionsComponent } from './covers-exclusions.component';

@NgModule({
  imports: [CommonModule, SharedUiComponentsCoreModule],
  declarations: [CoversExclusionsComponent],
  exports: [CoversExclusionsComponent],
})
export class CoverExclusionsModule {}
