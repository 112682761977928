import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NAVIGATION_FEATURE_KEY,
  NavigationPartialState,
  NavigationState,
} from './navigation.reducer';

// Lookup the 'Navigation' feature state managed by NgRx
export const getNavigationState = createFeatureSelector<
  NavigationPartialState,
  NavigationState
>(NAVIGATION_FEATURE_KEY);

export const getCompletedSteps = createSelector(
  getNavigationState,
  (state: NavigationState) => state.completedSteps
);
