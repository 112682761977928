import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateWithOrdinalPipe, InterpolatePipe } from './pipes';

@NgModule({
  imports: [CommonModule],
  exports: [InterpolatePipe, DateWithOrdinalPipe],
  declarations: [InterpolatePipe, DateWithOrdinalPipe],
})
export class CommonUtilFoundationModule {}
