import { Inject, Injectable } from '@angular/core';
import { applianceIsWhiteGoods, ENVIRONMENT } from '@common/util-foundation';
import { Environment } from '@common/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as ApplianceDetailsActions from '../appliance-details.actions';
import * as ApplianceFormDataActions from './appliance-form-data.actions';

@Injectable()
export class ApplianceFormDataEffects {
  setPrepopulatedFormValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.createQuoteSuccess),
      map(({ formData, quoteResponse }) => {
        const requestedBasketItem = quoteResponse.basket.items.find(
          (item) => item.itemId === quoteResponse.requestedItemId
        );

        if (
          this.environment.multiBasket &&
          applianceIsWhiteGoods(requestedBasketItem?.data?.applianceDetails)
        ) {
          return ApplianceFormDataActions.clearPrepopulatedFormValue();
        }
        return ApplianceFormDataActions.setPrepopulatedFormValue({
          formData,
        });
      })
    )
  );

  clearPrepopulatedFormValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.createQuoteFailure),
      map(() => ApplianceFormDataActions.clearPrepopulatedFormValue())
    )
  );

  saveFormValueWithItemId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplianceDetailsActions.createQuoteSuccess),
      map(({ formData, quoteResponse }) =>
        ApplianceFormDataActions.saveFormValueWithItemId({
          formData: {
            ...formData,
            itemId: quoteResponse.requestedItemId,
          },
        })
      )
    )
  );
  constructor(
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    private readonly actions$: Actions
  ) {}
}
