import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromReinstate from './+state/reinstate.reducer';
import { ReinstateFacade } from './+state/reinstate.facade';
import { EffectsModule } from '@ngrx/effects';
import { ReinstateEffects } from './+state/reinstate.effects';
import { ReinstateApiService } from './services/reinstate-api.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromReinstate.REINSTATE_FEATURE_KEY,
      fromReinstate.reducer
    ),
    EffectsModule.forFeature([ReinstateEffects]),
  ],
  providers: [ReinstateFacade, ReinstateApiService],
})
export class ReinstateDataAccessReinstateModule {}
