import { Inject, Injectable, Optional } from '@angular/core';
import {
  filterNullUndefined,
  GaTaggingService,
  GA_TAGS,
  pushToGa,
} from '@common/util-foundation';
import { GoogleTagConfig } from '@common/util-models';
import { ComponentStore } from '@ngrx/component-store';
import { mapTo } from 'rxjs/operators';

@Injectable()
export class ErrorTaggingService extends ComponentStore<never> {
  readonly landingGaTags = this.effect((trigger$) =>
    trigger$.pipe(
      mapTo(this.gaTags?.onLoadErrorPageData),
      filterNullUndefined(),
      pushToGa(this.gaTaggingService)
    )
  )();

  constructor(
    @Optional()
    @Inject(GA_TAGS)
    private gaTags: GoogleTagConfig,
    private gaTaggingService: GaTaggingService
  ) {
    super();
  }
}
