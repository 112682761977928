<ul class="covers-document">
  <li
    class="covers-document__item"
    *ngIf="termsAndConditionsDoc"
    data-automation="term-document"
  >
    <a
      class="link link--standalone"
      target="_blank"
      download
      [href]="termsAndConditionsDoc.link"
      title="{{ termsAndConditionsDoc.name }}"
      >{{ termsAndConditionsDoc.name }}
    </a>
  </li>
  <li
    class="covers-document__item"
    *ngIf="ipidDoc && productType === insurance"
    data-automation="ip-document"
  >
    <a
      class="link link--standalone"
      target="_blank"
      download
      [href]="ipidDoc.link"
      title="{{ ipidDoc.name }}"
      >{{ ipidDoc.name }}
    </a>
  </li>
</ul>
