import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import {
  OptionItem,
  YesNoDef,
} from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { RadioComponent } from '../radio/radio.component';

let nextId = 1;

@Component({
  selector: 'dgx-dfb-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TooltipService],
})
export class YesNoComponent
  extends RadioComponent
  implements OnChanges, OnInit, OnDestroy {
  @Input() field!: YesNoDef;
  tooltipName = 'tooltip';
  // a flag to apply active styling to the default option
  // gets set once the value is set by user
  isDirty = false;
  id = `yes-no-${nextId++}`;

  options: OptionItem<string | boolean>[] = [];

  private controlValueChangeSubscriptions = new Subscription();

  constructor(
    @Optional() public ngControl: NgControl,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  public get getUpdateErrorMsgOnValueChange$(): Observable<string> {
    const control = this.ngControl?.control;
    return control
      ? control.valueChanges.pipe(
          debounceTime(100),
          tap((value) => {
            if (value) {
              this.validate = false;
            }
          })
        )
      : of('');
  }

  writeValue(val: string | boolean | number, emitAnalytics: boolean = false) {
    super.writeValue(val, emitAnalytics);
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    super.ngOnInit();
    this.clearInitialValue();
    this.controlValueChangeSubscriptions.add(
      this.getUpdateErrorMsgOnValueChange$.subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.validate?.currentValue) {
      this.updateErrorMessage();
    }
    return super.ngOnChanges(changes);
  }

  onSelectChange(value: string | boolean) {
    this.isDirty = true;
    this.writeValue(value, true);
  }

  updateErrorMessage() {
    const control = this.ngControl?.control;
    if (this.validate) {
      const firstErrorType = control?.errors
        ? Object.keys(control?.errors)[0]
        : null;
      this.errorMessage =
        this.field?.validationMessages?.find(
          (msg) =>
            msg.type.toLocaleLowerCase() === firstErrorType?.toLocaleLowerCase()
        )?.message || '';
    } else {
      this.errorMessage = '';
    }
  }

  clearInitialValue() {
    if (this.field?.initialValue) {
      this.writeValue(this.field.initialValue);
      this.field.initialValue = '';
    }
  }

  ngOnDestroy() {
    this.controlValueChangeSubscriptions.unsubscribe();
  }
}
