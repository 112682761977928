<dgx-dfb-field-layout
  [label]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
  data-hook="dgx-sales-input"
>
  <div in-field-header class="show-password-link">
    <a (click)="toggleFieldPasswordType()"
      ><span class="auth-icon-eye">
        <ng-container
          [ngTemplateOutlet]="svgIconTemplate"
        ></ng-container> </span
      ><span class="link link-underline password-link" *ngIf="!fieldTextType"
        >Show</span
      >
      <span class="link link-underline password-link" *ngIf="fieldTextType"
        >Hide</span
      >
    </a>
  </div>

  <div class="input-wrapper">
    <input
      [name]="field?.controlName"
      class="form-control"
      [dgxFdnQmSanitise]="field?.sanitise"
      [type]="fieldTextType ? 'text' : 'password'"
      [placeholder]="field?.placeholder"
      [value]="value$ | async"
      (input)="onChange($any($event))"
      (change)="onChange($any($event))"
      (blur)="onBlur($any($event))"
      autocomplete="off"
    />
    <ui-form-validation-icon
      [validityStatus]="validity"
    ></ui-form-validation-icon>
  </div>
  <dgx-dfb-password-strength-checker
    *ngIf="!field?.hideStrengthChecker"
    in-field-footer
    [value]="passwordValue"
    [submitted]="passwordError"
    [showLength]="passwordStrengthChecker.showLength"
    [showUppercase]="passwordStrengthChecker.showUppercase"
    [showLowercase]="passwordStrengthChecker.showLowercase"
    [showNumber]="passwordStrengthChecker.showNumber"
  ></dgx-dfb-password-strength-checker>
</dgx-dfb-field-layout>
<ng-template #svgIconTemplate>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="eye"
    class="svg-inline--fa fa-eye fa-w-18"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
    ></path>
  </svg>
</ng-template>
