import { Injectable } from '@angular/core';
import { QuotesPartialState } from '@common/data-access-quotes';
import { SelectPaymentPartialState } from '@common/data-access-select-payment';
import { select, Store } from '@ngrx/store';
import { getTaggingData } from './ga-tagging.selectors';

@Injectable()
export class GaTaggingFacade {
  taggingData$ = this.store.pipe(select(getTaggingData));

  constructor(
    private store: Store<QuotesPartialState & SelectPaymentPartialState>
  ) {}
}
