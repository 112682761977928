<sales-information-header-bar>
  Hi,
  <span
    [attr.data-automation]="
      personalDetails?.firstName ? 'firstname' : 'unknown'
    "
  >
    {{ personalDetails?.firstName || 'there' }}</span
  >. Here's a reminder of your details.
</sales-information-header-bar>
