import { Component, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxToggleDef } from '@domgen/dgx-fe-dynamic-form-builder';
@Component({
  selector: 'wl-terms-and-conditions-for-insurance',
  templateUrl: './terms-and-conditions-for-insurance.component.html',
  styleUrls: ['./terms-and-conditions-for-insurance.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TermsAndConditionsForInsuranceComponent,
    },
  ],
})
export class TermsAndConditionsForInsuranceComponent
  implements ControlValueAccessor {
  @Input() field: CheckboxToggleDef | undefined = undefined;
  @Input() validate = false;
  AcceptanceChange: EventEmitter<boolean> = new EventEmitter();

  onTouched: () => void = () => {
    return undefined;
  };

  onChanged: (val: unknown) => void = () => {
    return undefined;
  };
  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: boolean) {
    this.AcceptanceChange.emit(val);
  }

  onAcceptanceChange(val: boolean) {
    this.onChanged(val);
  }
}
