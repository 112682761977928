import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'sales-minimum-term',
  templateUrl: './minimum-term.component.html',
  styleUrls: ['./minimum-term.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinimumTermComponent {}
