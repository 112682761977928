import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckoutCmsData } from '@common/util-models';

@Component({
  selector: 'sales-checkout-user-login',
  templateUrl: './checkout-user-login.component.html',
  styleUrls: ['./checkout-user-login.component.scss'],
})
export class CheckoutUserLoginComponent {
  @Input() cmsData!: CheckoutCmsData | null;
  @Output() loginSuccess = new EventEmitter();
  @Output() loginFail = new EventEmitter();
  @Output() forgotPassword = new EventEmitter();

  showForgotPassword = false;
  isPasswordResponseSuccess = false;
  onLoginSucceed(): void {
    this.loginSuccess.emit();
  }

  onForgotPassword(): void {
    this.forgotPassword.emit();
    this.showForgotPassword = true;
  }

  onLoginFailed(): void {
    this.loginFail.emit();
  }

  backToLoginButtonClicked(): void {
    this.showForgotPassword = false;
  }

  handleForgotPasswordResponseSuccess(): void {
    this.isPasswordResponseSuccess = true;
  }
}
