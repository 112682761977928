<ng-container
  *ngIf="(products?.length && products.length > 1) || alternateProduct"
>
  <div class="cover-title xs-12">
    {{ isInsurance ? 'Your cover' : 'Confirm your plan' }}
  </div>
  <ng-container *ngIf="products?.length">
    <div class="product-card" *ngFor="let product of products">
      <wl-product-card
        [selected]="selectedProduct === product.id"
        [product]="product"
        [selectable]="true"
        (selectionChange)="onSelectionChange($event)"
      >
      </wl-product-card>
    </div>
  </ng-container>
  <ng-container *ngIf="alternateProduct">
    <wl-upgrade-banner
      [desc]="alternateProduct.description"
      [linkText]="alternateProduct.linkText"
      (linkClick)="handleAlternateProductSelection(alternateProduct)"
    >
    </wl-upgrade-banner>
  </ng-container>
</ng-container>
