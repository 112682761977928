import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { YourDetails } from '@common/util-models';

export interface Detail {
  name: string;
  email: string;
  contact: string;
  address: string;
  code: string;
}

@Component({
  selector: 'wl-your-details',
  templateUrl: './your-details.component.html',
  styleUrls: ['./your-details.component.scss'],
})
export class YourDetailsComponent implements OnChanges {
  @Input() detail: YourDetails | undefined | null;
  @Input() editLink = '';
  address = '';

  onButtonClick() {
    if (this.editLink) {
      this.router.navigateByUrl(this.editLink);
    }
  }

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.detail) {
      return;
    }
    if (this.detail) {
      this.addressGenerator(this.detail);
    }
  }

  addressGenerator(personalDetail: YourDetails) {
    if (personalDetail.address) {
      const adressArray = Object.values(personalDetail.address);
      this.address = adressArray.filter((v) => !!v).join(', ');
    }
  }
}
