import { DomainBasedRequestSource } from '@common/util-models';

export const STORE_SUFFIX = 'STORE_SUFFIX';

export function getRequestSource({
  domainBasedRequestSources,
  catalogueStore,
}: {
  domainBasedRequestSources?: DomainBasedRequestSource[];
  catalogueStore?: string;
}): string {
  const url: URL = new URL(window?.location.href);
  const domainBasedRequestSource:
    | string
    | undefined = domainBasedRequestSources?.find(
    (source: DomainBasedRequestSource) => source.pattern.test(url.hostname)
  )?.requestSource;
  const requestSource: string =
    domainBasedRequestSource ?? catalogueStore ?? 'DandGUK';
  const requestSourceSuffix: string = getRequestSourceSuffix();

  return `${requestSource}${requestSourceSuffix}`;
}

export function getRequestSourceSuffix(): string {
  const url = new URL(window?.location.href);
  const storeSuffix =
    url.searchParams.get('store') ??
    window?.sessionStorage?.getItem(STORE_SUFFIX);
  if (storeSuffix && storeSuffix.match(/^[A-Za-z0-9]+$/)) {
    window?.sessionStorage?.setItem(STORE_SUFFIX, storeSuffix);
    return `_${storeSuffix}`;
  }
  return '';
}
