import {
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  CheckoutCompletedItem,
  CheckoutResponse,
  calculateNumberSubsequentPayments,
} from '@common/util-models';

export function addNumberSubsequentPaymentsToCheckoutResponse(
  checkoutResponse: CheckoutResponse
): CheckoutResponse {
  return {
    ...checkoutResponse,
    completedItems: checkoutResponse.completedItems.map(
      (completedItem: CheckoutCompletedItem) => ({
        ...completedItem,
        item: {
          ...completedItem.item,
          data: {
            ...completedItem.item.data,
            quotes: completedItem.item.data.quotes.map(
              (quote: BasketItemQuoteDetails) => ({
                ...quote,
                paymentOptions: quote.paymentOptions.map(
                  (paymentOption: BasketItemPaymentOption) => {
                    return {
                      ...paymentOption,
                      numSubsequentPayments: calculateNumberSubsequentPayments(
                        paymentOption
                      ),
                    };
                  }
                ),
              })
            ),
          },
        },
      })
    ),
  };
}
