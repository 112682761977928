import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {
  isRemoteDataError,
  isRemoteDataNotFetched,
  isRemoteDataOK,
  RemoteData,
} from '@common/util-models';
import { select, Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import * as AppliancesActions from '../+state/appliances/appliances.actions';
import { AppliancesState } from '../+state/appliances/appliances.reducer';
import * as AppliancesSelectors from '../+state/appliances/appliances.selectors';

@Injectable()
export class AppliancesResolver implements Resolve<unknown> {
  constructor(private store: Store<AppliancesState>) {}

  resolve() {
    return this.store.pipe(
      select(AppliancesSelectors.getAppliancesLoaded),
      tap((itemsLoadedState) => this.dispatchOnNotFetched(itemsLoadedState)),
      filter((itemsLoadedState) => this.filterSuccessOrError(itemsLoadedState)),
      take(1)
    );
  }

  private dispatchOnNotFetched(itemsLoadedState: RemoteData) {
    if (isRemoteDataNotFetched(itemsLoadedState)) {
      this.store.dispatch(AppliancesActions.loadAppliances());
    }
  }

  private filterSuccessOrError(itemsLoadedState: RemoteData) {
    if (
      isRemoteDataOK(itemsLoadedState) ||
      isRemoteDataError(itemsLoadedState)
    ) {
      return true;
    }
    return false;
  }
}
