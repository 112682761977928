import { CmsDataUrl, CmsPage } from '@common/util-models';
import { mapCheckoutPageCmsData, mapCmsFormData } from './cms-data-mappers';

const pageName = 'dgx-forms';

export const cmsDataUrl: CmsDataUrl = {
  [CmsPage.Appliance]: {
    id: CmsPage.Appliance,
    url: `${pageName}?application=sales&formName=applianceForm&lang=en`,
    mapper: mapCmsFormData,
  },
  [CmsPage.Quote]: {
    id: CmsPage.Quote,
    url: CmsPage.Quote,
  },
  [CmsPage.FAQs]: {
    id: CmsPage.FAQs,
    url: CmsPage.FAQs,
  },
  [CmsPage.Checkout]: {
    id: CmsPage.Checkout,
    url: `dgx-checkout/`,
    mapper: mapCheckoutPageCmsData,
  },
  [CmsPage.CheckoutPersonalDetails]: {
    id: CmsPage.CheckoutPersonalDetails,
    url: `${pageName}?application=sales&formName=checkoutPersonalDetailsForm-mock&lang=en`,
    mapper: mapCmsFormData,
  },
  [CmsPage.CheckoutDirectDebit]: {
    id: CmsPage.CheckoutDirectDebit,
    // TODO: Commented out due to no setup for page in CMS. MISSING Marketing Preference
    url: `${pageName}?application=sales&formName=checkoutDirectDebitForm&lang=en`,
    mapper: mapCmsFormData,
  },
  [CmsPage.CheckoutLoggedInUser]: {
    id: CmsPage.CheckoutLoggedInUser,
    // TODO: This page still needs to be set up for page in CMS. Currently only cached data is available
    url: 'dgx-forms?application=sales&formName=checkoutLoggedInUser&lang=en',
    mapper: mapCmsFormData,
  },
  [CmsPage.CheckoutSelectPayment]: {
    id: CmsPage.CheckoutSelectPayment,
    // TODO: Commented out due to no setup for page in CMS. CANNOT GO LIVE WITH THIS
    url: 'dgx-forms?application=sales&formName=checkoutSelectPayment&lang=en',
    mapper: mapCmsFormData,
  },
};
