import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Quote } from '@common/util-models';

@Component({
  selector: 'wl-quote-summary-total',
  templateUrl: './quote-summary-total.component.html',
  styleUrls: ['./quote-summary-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteSummaryTotalComponent implements OnChanges {
  @Input() applianceIsWhiteGoods?: boolean;
  @Input() isExpanded?: boolean;
  @Input() isFixedTerm?: boolean;
  @Input() isMultiItem?: boolean;
  @Input() quotes?: Quote[];
  @Input() selectedPaymentTypeIsCard?: boolean;
  @Input() storeDiscountPercentage?: number;

  cardTotal?: number;
  directDebitDiscount?: number;
  directDebitTotal?: number;
  monthlyPaymentTotal?: number;
  numberOfMonthlyPayments?: number;
  subsequentMonthlyPaymentTotal?: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quotes) {
      this.buildQuoteTotal();
    }
  }

  private buildQuoteTotal(): void {
    this.cardTotal = this.quotes?.reduce(
      (total: number, item: Quote) =>
        (total += item?.cover?.paymentOptions?.card?.fee ?? 0),
      0
    );
    this.directDebitTotal = this.quotes?.reduce(
      (total: number, item: Quote) =>
        (total += item?.cover?.paymentOptions?.directDebit?.fee ?? 0),
      0
    );
    this.subsequentMonthlyPaymentTotal = this.quotes?.reduce(
      (total: number, item: Quote) =>
        (total +=
          item?.cover?.paymentOptions?.directDebit?.subsequentPayment ?? 0),
      0
    );
    this.numberOfMonthlyPayments = this.quotes?.reduce(
      (count: number, item: Quote) =>
        Math.max(
          count,
          (item?.cover?.paymentOptions?.directDebit?.numSubsequentPayments ??
            0) + 1
        ),
      0
    );

    this.directDebitDiscount =
      this.cardTotal && this.directDebitTotal
        ? this.cardTotal - this.directDebitTotal
        : undefined;
  }
}
