import { MarketingPreferences } from '@domgen/dgx-fe-business-models';
import {
  BasketItemPaymentOption,
  ContractType,
  ItemType,
} from './basket-item.types';

export interface CheckoutBasketItem {
  itemId: string;
  itemType: ItemType;
  quoteId: string;
  paymentOptions: {
    directDebit?: BasketItemPaymentOption | undefined;
    card?: BasketItemPaymentOption | undefined;
  };
  contractType: ContractType;
  companyCode: string;
  schemeCode: string;
}

export interface CheckoutBasket {
  basketId: string;
  items: CheckoutBasketItem[];
}

export enum PlanDocumentsSendMethod {
  EMAIL = 'EMAIL',
  POST = 'POST',
  BOTH = 'BOTH',
}

export interface CheckoutItemDetails {
  itemType: ItemType.Quote;
  itemId: string;
  quoteId: string;
  stopAutoRenewalCode?: StopAutoRenewalCode;
}

export interface CheckoutRequest {
  basketId: string;
  items: Array<CheckoutItemDetails>;
  customer: CustomerDetails;
  payment:
    | PaymentByDirectDebit
    | PaymentByDirectDebitToken
    | PaymentByCreditCard;
}

export interface CustomerDetails {
  title?: string;
  initial?: string;
  firstName: string;
  surname: string;
  preferredName?: string;
  telephone?: CustomerTelephone;
  email: CustomerEmail;
  address: CustomerAddress;
  /**
   * Method for sending contract documents.
   */
  sendMethod: PlanDocumentsSendMethod;
  isOverrideAddress: boolean;
  /**
   * Preferred method for general correspondence.
   */
  preferredContactMethod: 'EMAIL' | 'POST' | 'TELEPHONE';
  marketingPreferences: MarketingPreferences;
}

export interface CustomerTelephone {
  mobile?: string;
  home?: string;
  work?: string;
  preferred?: 'H' | 'M' | 'W';
}

export interface CustomerEmail {
  main: string;
}

export interface CustomerAddress {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  postalCode: string;
  countryCode: string;
}

export interface PaymentByDirectDebit {
  paymentType: 'D';
  directDebitDetails: PaymentByDirectDebitDetails;
  preferredPaymentDay: number;
}

export interface PaymentByDirectDebitDetails {
  sortCode: string;
  accountNumber: string;
  accountName: string;
}
export interface PaymentByDirectDebitToken {
  paymentType: 'D';
  directDebitPaymentToken: string;
  preferredPaymentDay: number;
}
export interface PaymentByCreditCard {
  paymentType: 'C';
  cardPaymentReference: string;
}

export enum StopAutoRenewalCode {
  EndOfCurrentRenewal = 'U1',
  EndOfNextRenewalCycle = 'U2',
  ReinstateAutoRenewal = 'U3',
}

export function isPaymentByCreditCard(
  payment:
    | PaymentByDirectDebit
    | PaymentByDirectDebitToken
    | PaymentByCreditCard
): payment is PaymentByCreditCard {
  return payment.paymentType === 'C';
}

export function isPaymentByDirectDebitToken(
  payment:
    | PaymentByDirectDebit
    | PaymentByDirectDebitToken
    | PaymentByCreditCard
): payment is PaymentByCreditCard {
  return (
    payment.paymentType === 'D' &&
    !!(payment as PaymentByDirectDebitToken).directDebitPaymentToken
  );
}

export function isPaymentByDirectDebit(
  payment:
    | PaymentByDirectDebit
    | PaymentByDirectDebitToken
    | PaymentByCreditCard
): payment is PaymentByDirectDebit {
  return (
    payment.paymentType === 'D' &&
    !(payment as PaymentByDirectDebitToken).directDebitPaymentToken
  );
}

export function initialBasket(): CheckoutBasket {
  return {
    basketId: '',
    items: [],
  };
}
