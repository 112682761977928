import { Injectable } from '@angular/core';
import { filterNullUndefined } from '@common/util-foundation';
import {
  CoverPaymentOptions,
  Quote,
  TaggingMetadata,
} from '@common/util-models';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as QuotesPageSelectors from '../+state/quotes-page.selectors';
import { QuotesPartialState } from '../+state/quotes.reducer';
import * as QuotesSelectors from '../+state/quotes.selectors';

@Injectable()
export class SelectQuotePaymentOptionsFacade {
  isHeating$ = this.store.pipe(
    select(QuotesSelectors.getCurrentItemIsHeatingAppliance)
  );

  paymentOptions$ = this.store.pipe(
    select(QuotesSelectors.getPaymentOptions),
    filter((paymentOptions) => !!paymentOptions)
  ) as Observable<CoverPaymentOptions>;

  quote$ = this.store.pipe(
    select(QuotesPageSelectors.getQuote),
    filter((quote) => !!quote)
  ) as Observable<Quote>;

  basketId$ = this.store.pipe(
    select(QuotesSelectors.getBasketId),
    filterNullUndefined()
  );

  taggingMetadata$ = this.store.pipe(
    select(QuotesPageSelectors.getTaggingMetadata),
    filter((taggingMetadata) => !!taggingMetadata)
  ) as Observable<TaggingMetadata>;

  constructor(private store: Store<QuotesPartialState>) {}
}
