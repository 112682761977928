import { CmsBusinessComponentsFormFieldName } from '@domgen/dgx-fe-business-models';
import { ApplianceFormField } from './appliance-form-cms.types';
import { LoggedInUserFormField } from './logged-in-user-cms.types';
import { PersonalDetailsFormField } from './personal-details-form-cms.types';

export enum TermsAndConditionsFormField {
  TermsAndConditions = 'termsAndConditions',
}

// Combination all of forms in SALES 2.0
export type CmsSalesFormFieldName =
  | ApplianceFormField
  | PersonalDetailsFormField
  | LoggedInUserFormField
  | TermsAndConditionsFormField
  | CmsBusinessComponentsFormFieldName;
