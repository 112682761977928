<ui-banner>
  <div class="top-banner">
    <p class="top-banner__title">{{ title }}</p>
    <button
      *ngIf="!hideLink"
      class="top-banner__link"
      type="button"
      (click)="handleClick()"
    >
      {{ linkText }}
      <i class="icon icon-arrow-right"></i>
    </button>
    <p *ngIf="subtitle && !hideSubtitle" class="top-banner__subtitle">
      {{ subtitle }}
    </p>
  </div>
</ui-banner>
