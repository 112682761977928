import { AuthModuleConfigForms } from '@domgen/dgx-fe-auth/lib/common/tokens/auth-config-token';

export const authenticationModuleFormsConfig: AuthModuleConfigForms = {
  loginForm: {
    emailLabel: 'Email',
    requiredEmailValidation: 'Enter a valid email address',
    incorrectEmailFormatValidation: 'You must provide a valid email address',
    passwordLabel: 'Password',
    showPasswordLabel: 'Show',
    hidePasswordLabel: 'Hide',
    requiredPasswordValidation: 'Password is required',
    logInButtonLabel: 'Sign in to checkout',
    forgotPasswordLinkLabel: 'Forgot your password?',
  },
  forgotPasswordForm: {
    emailLabel: 'Email',
    requiredEmailValidation: 'Email is required',
    incorrectEmailFormatValidation: 'You must provide a valid email address',
    emailPlaceholderLabel: '',
    submitButtonLabel: 'Submit',
    showBackToLoginButton: true,
    backToLoginButtonLabel: 'Back To Login',
  },
};
