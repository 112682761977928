import { Action, createReducer, on } from '@ngrx/store';
import * as CorrelationIdActions from './correlation-id.action';

export const CORRELATION_ID_KEY = 'correlationId';

export type CorrelationIdPartialState = {
  [CORRELATION_ID_KEY]: CorrelationIdState;
};

export interface CorrelationIdState {
  correlationId: string | null;
}

export const initialState: CorrelationIdState = {
  correlationId: null,
};

const reducer = createReducer(
  initialState,

  on(CorrelationIdActions.setCorrelationID, (state, { correlationId }) => ({
    ...state,
    correlationId,
  }))
);

export function correlationIdReducer(
  state: CorrelationIdState | undefined,
  action: Action
): CorrelationIdState {
  return reducer(state, action);
}
