import { FormGroup } from '@angular/forms';
import {
  FieldDef,
  ValidationMessage,
} from '../../interfaces/dynamic-formbuilder.interface';
import { Normalize } from '../../interfaces/normalize.interface';
import { normalize } from '../normalize/normalize.utils';

export function normalizeFormValidationMessagesFromConfig(
  fieldDefs: FieldDef[]
): Normalize<Normalize<ValidationMessage>> {
  return fieldDefs.reduce(
    (messages: Normalize<Normalize<ValidationMessage>>, fieldDef: FieldDef) => {
      const fieldDefinition = fieldDef as {
        controlName?: string;
        validationMessages?: ValidationMessage[];
      };
      if (fieldDefinition.controlName && fieldDefinition.validationMessages) {
        const key: string = fieldDefinition.controlName;
        const value: Normalize<ValidationMessage> = normalize(
          fieldDefinition.validationMessages,
          'type'
        );
        messages[key] = value;
      }
      return messages;
    },
    {}
  );
}

export function normalizeFormValidationMessages(
  validationMessages: Normalize<Normalize<ValidationMessage>>,
  form: FormGroup
): Normalize<Normalize<string>> {
  return Object.entries(form.controls).reduce(
    (result: Normalize<Normalize<string>>, [controlName, control]) => {
      result[controlName] = {};
      Object.keys(control.errors ?? {}).forEach(
        (errorKey: string) =>
          (result[controlName][errorKey] =
            validationMessages[controlName][errorKey]?.message)
      );

      return result;
    },
    {}
  );
}
